import React, { Fragment } from 'react'

import getDate from '../../../../../Helpers/Date/GetDate'

import Table from '../../../../../Components/Table/Table'
import ListTableImageColumn from '../../../../../Components/ListTable/ListTableImageColumn'

export default ({
  receive
}) => {
  const {
    receive_no: ReceiveNo,
    receive_date: receiveDate,
    receive_item_details: detail,
    vendor_name: CustomerName
  } = receive
  const tableHeaders = [
    'Item Name',
    'SKU',
    'Qty.'
  ]
  const tableData = [
    'itemName',
    'sku',
    'quantity',
  ]
  const rightAlignColumns = [2]

  const items = detail.map(single => {
    return {
      itemName: <ListTableImageColumn
        name={single.item.name}
        // sku={single.item.sku}
        images={single.item.images}
      />,
      sku: single.item.sku,
      quantity: <Fragment>{single.quantity} <div className="small-td-info">{single.item.unit}</div></Fragment>,
    }
  })

  return (
    <Fragment>
      {/* <div>
      <div className="semi-bold margin-bottom-md font-1_6rem">{ReceiveNo}</div>
      </div>
      <div>
      <div className="semi-bold margin-bottom-md font-1_6rem">{CustomerName}</div>
      </div>
      <div>
      <div className="section-details--text-grey margin-bottom-sm">{getDate(receiveDate)}</div>
      </div> */}
      <div className="float-left w-100 payment_modal">
        <div className="section-details--info">
          {ReceiveNo && (
            <div className="details_fields receive_modal_fields_value">
              <Fragment>
                <div className="section-details--info-title semi-bold receive_modal_fields_key received-firs-row">
                  Receive No.
                </div>
                <div className="receive_modal_fields_value">{ReceiveNo}</div>
              </Fragment>
            </div>
          )}
        </div>

        <div className="section-details--info">
          {CustomerName && (
            <div className="details_fields receive_modal_fields_value">
              <Fragment>
                <div className="section-details--info-title semi-bold receive_modal_fields_key">
                  Vendor Name
                </div>
                <div className="receive_modal_fields_value">{CustomerName}</div>
              </Fragment>
            </div>
          )}
          {receiveDate && (
            <div className="details_fields receive_modal_fields_value">
              <Fragment>
                <div className="section-details--info-title semi-bold receive_modal_fields_key">
                  Date
                </div>
                <div className="receive_modal_fields_value">{getDate(receiveDate)}</div>
              </Fragment>
            </div>
          )}
        </div>
      </div>
      <div className="package-item-info w-100 sales_return_table" style={{ marginBottom: "10%" }}>
        <Table
          list={items}
          tableHeaders={tableHeaders}
          tableData={tableData}
          rightAlignColumns={rightAlignColumns}
          className="details-table"
        />
      </div>
      {/* <div style={{ marginBottom: "20%" }}></div> */}
    </Fragment>
  );
}