import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { restRequest } from '../../../Helpers/RequestHelper';

import OverlayLoader from '../../../Components/Loader/OverlayLoader';
import Form from '../../../Components/Form/Form';

import { Checked } from '../../../Assets/List/ListIcons';
import FormAction from '../../../Components/Form/FormAction';
import {
  getObjFromLS,
  // setObjInLS
} from '../../../Helpers/LocalStorage';

import './PrintandPdfPreference.css';
import { checkError } from '../../../Helpers/AuthHelper';
import { isEqual } from 'lodash';

class PrintandPdfPreference extends Component {
  state = {
    loading: true,
    staticVal: {},
    pdfprintPref: {
      displayLicenseStatus: false,
      displayCountryStatus: false,
    },
    isSubmitting: false,
  };

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.fetchData();
    window.onbeforeunload = function () {
      return true;
    };
  }
  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { pdfprintPref, staticVal } = this.state;
    const { displayLicenseStatus, displayCountryStatus } = pdfprintPref;
    if (
      prevState.pdfprintPref?.displayLicenseStatus !== displayLicenseStatus ||
      prevState.pdfprintPref?.displayCountryStatus !== displayCountryStatus
    ) {
      const checkEquality = Boolean(isEqual(pdfprintPref, staticVal));
      if (!checkEquality) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }

  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', 'preferences/pdfsettings')
      .then((res) => {
        this.setState({
          pdfprintPref: {
            displayLicenseStatus: res.preferences.license_no,
            displayCountryStatus: res.preferences.country,
          },
          staticVal: {
            displayLicenseStatus: res.preferences.license_no,
            displayCountryStatus: res.preferences.country,
          },
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let { displayLicenseStatus, displayCountryStatus } =
      this.state.pdfprintPref;
    this.setState({ isSubmitting: false });
    restRequest('put', 'preferences/pdfsettings', {
      status: {
        license_no: displayLicenseStatus,
        country: displayCountryStatus,
      },
    })
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.handleToast(
          'Prints & PDF preference updated successfully',
          'success'
        );
        this.props.setPrompt(false);
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        //this.props.handleToast(error, "error")
        checkError(error, this.props.handleToast);
      });
  };

  togglePdfPrintDisplayStatus(flag) {
    flag === 'country' &&
      this.setState((state) => ({
        pdfprintPref: {
          displayLicenseStatus: state.pdfprintPref.displayLicenseStatus,
          displayCountryStatus: !state.pdfprintPref.displayCountryStatus,
        },
      }));
    flag === 'license' &&
      this.setState((state) => ({
        pdfprintPref: {
          displayLicenseStatus: !state.pdfprintPref.displayLicenseStatus,
          displayCountryStatus: state.pdfprintPref.displayCountryStatus,
        },
      }));
  }

  renderActionButtons() {
    // return (
    //   <FormAction disabled={this.state.isSubmitting} onCancel={() => this.props.history.push('/settings')} />
    // )
    let permissions = getObjFromLS('role').permissions;
    if (!permissions.superAccess) {
      if (permissions.PreferenceEdit) {
        return (
          <FormAction
            disabled={this.state.isSubmitting}
            onCancel={() => this.props.history.push('/settings')}
          />
        );
      }
    }
    if (permissions.superAccess) {
      return (
        <FormAction
          disabled={this.state.isSubmitting}
          onCancel={() => this.props.history.push('/settings')}
        />
      );
    }
  }

  renderStatusChangeInput() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="pdf-print-preference float-left w-100">
          <div className="float-left w-70 genral_tab_content">
            {this.state.loading && <OverlayLoader />}

            <label className="list-table--checkbox-lable pointer">
              <input
                className="float-left"
                checked={this.state.pdfprintPref.displayLicenseStatus}
                type="checkbox"
                id="license"
                onChange={() => this.togglePdfPrintDisplayStatus('license')}
              />
              <span className="float-left">
                <Checked />
              </span>
              <div className="pdf-print-preference--label-text float-left">
                Display License Number In Prints & PDF?
              </div>
            </label>

            <label className="list-table--checkbox-lable pointer">
              <input
                className="float-left"
                checked={this.state.pdfprintPref.displayCountryStatus}
                type="checkbox"
                onChange={() => this.togglePdfPrintDisplayStatus('country')}
                id="country"
              />
              <span className="float-left">
                <Checked />
              </span>
              <div className="pdf-print-preference--label-text float-left">
                Display Country In Prints & PDF?
              </div>
            </label>

            {this.renderActionButtons()}
          </div>
        </div>
      </Form>
    );
  }

  render() {
    return this.renderStatusChangeInput();
  }
}

export default withRouter(PrintandPdfPreference);
