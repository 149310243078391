import React, { Fragment, useEffect, useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
//import { endOfDay } from 'date-fns';
import { withFormik, FieldArray } from 'formik';
import queryString from 'query-string';
import {
  getObjFromLS,
  checkError,
  setObjInLS,
  restRequest,
  getDate,
} from '../../Helpers';
import { getOrganizationDate } from '../../Helpers';
import {
  required,
  ValidateDate,
  ValidateMax255,
  ValidateMax50,
  ValidateNumber,
  ValidateRequired,
  ValidateNumerMoreThenZero,
} from '../../Helpers/Validator';
import {
  Field,
  Form,
  FormSection,
  FormAction,
  ErrorFocus,
} from '../../Components/Form/index';
import {
  formSubmit,
  //getError,
  validate,
} from '../../Components/Form/FormHelper';
import { CustomModal, Loader } from '../../Components';
import Customer from '../Contact/ContactDetails/index';
import {
  NewContactIcon,
  NewInvoiceIcon,
} from '../../Assets/Navigation/NavigationIcons';
import InvoiceDetails from '../SalesOrder/Invoices/InvoiceDetails';
import AddContact from '../Contact/AddContact';
//import InvoicesFilter from './InvoicesFilter';
import { validateCustomerpaymentForm } from '../../Helpers/FormValidator';
import useMobileDetection from '../../Hooks/MobileDetect';
import './index.css';
import { MdHelpOutline } from '../../Common/Icons';

// let allValls = [];

// let confirm = false;
const getYupValidationSchema = (values) => {
  return Yup.object().shape({
    contactId: required(ValidateNumber),
    paymentId: ValidateRequired,
    amount_received: ValidateNumerMoreThenZero,
    paymentDate: required(ValidateDate),
    reference: ValidateMax50,
    customerNotes: ValidateMax255,
    payment_mode: required(ValidateMax255),

    // items: Yup.array()
    //   .of(
    //     Yup.lazy((item) => {
    //       return Yup.object().shape({
    //         name: ValidateRequired,
    //         quantity: required(
    //           moreThanZero(ValidateMax10Billion).integer('Invalid Value')
    //         ),
    //         unitPrice: required(moreThanMinus(ValidateMax10Billion)),
    //         discount:
    //           item.discountType === 'percent'
    //             ? ValidateMax100Number
    //             : ValidateAmountNumber(
    //                 item.discount,
    //                 item.quantity * item.unitPrice
    //               ),
    //       });
    //     })
    //   )
    //   .min(1, 'At least one item is required'),
  });
};

const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: (props) => ({
    saveAndSend: false,
    saveAndPrint: false,
    customerNotes: props.customerNotes,
    reference: props.reference,
    advancePaymentId: props.advancePaymentId,
    amount_received: props.amount_received,
    refund_amount: props.refund_amount,
    contactId: props.contactId,
    invoiceList: props.invoiceList,
    paymentDate: props.paymentDate,
    paymentId: props.paymentId,
    payment_mode: props.payment_mode,
    payment_mode_name: props.payment_mode_name,
    contactObj: props.contactObj,
  }),
  mapPropsToStatus: (props) => ({
    showContactCreateModal: false,
    ...props,
  }),
  handleSubmit: (values, bag) => {
    sessionStorage.setItem('showPrint', false);
    handleFormSubmit(values, bag);
  },
});
const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      id,
      submitRequestType,
      submitRequestUrl,
      successMessage,
      type,
      history,
      handleToast,
      setPrompt,
    },
  }
) => {
  if (
    values.amount_received <
    values?.invoiceList?.reduce(
      (total, val) => (val.payment ? total + val.payment : total + 0),
      0
    )
  ) {
    handleToast(
      'Applied payment on invoice is greater than Payment total',
      'error'
    );
    setSubmitting(false);
    return;
  }
  restRequest(submitRequestType(), submitRequestUrl(), {
    customer_id: values.contactId,
    id: values.advancePaymentId,
    payment_no: values.paymentId,
    payment_date:
      values.paymentDate && values.paymentDate.format('YYYY-MM-DD 00:00:00'),
    payment: values.amount_received,
    reference: values.reference,
    payment_mode: values.payment_mode_name,
    note: values.customerNotes,
    refund_amount: values.refund_amount,
    invoices: values.invoiceList
      .filter((item) => item.payment > 0)
      .map((item) => ({
        invoice_id: item.id,
        payment: item.payment,
      })),
    used_amount: values?.invoiceList?.reduce(
      (total, val) => (val.payment ? total + val.payment : total + 0),
      0
    ),
  })
    .then((res) => {
      setPrompt(false);
      successMessage();
      if (values.saveAndSend) {
        history.push(
          `/paymentsmade/email/${res.data.advance_payment.payment_no}`
        );
      } else if (values.saveAndPrint) {
        sessionStorage.setItem('showPrint', true);
        sessionStorage.setItem(
          'showPrintId',
          res.data.advance_payment.payment_no
        );
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          moduleName: 'paymentsmade',
          urlPath: `/paymentsmade/`,
          showPrint: true,
          action: type === 'add' ? 'add' : 'justedit',
          queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
        });
        return history.push('/r');
      } else {
        let dataFromLS = getObjFromLS('module');
        if (type === 'add') {
          setObjInLS('module', {
            ...dataFromLS,
            id: res.data.advance_payment.payment_no,
            moduleName: 'paymentsmade',
            urlPath: '/paymentsmade',
            action: type === 'add' ? 'add' : 'justedit',
            queryParam: '',
          });
        }
        if (type === 'edit') {
          setObjInLS('module', {
            ...dataFromLS,
            id: parseInt(res.data.advance_payment.payment_no),
            moduleName: 'paymentsmade',
            urlPath: '/paymentsmade',
            action: type === 'add' ? 'add' : 'justedit',
            queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
          });
        }
        history.push('/r');

        // history.push('/r');
        sessionStorage.setItem('fullscreen', false);
      }
    })
    .catch((error) => {
      setSubmitting(false);
      checkError(error);
      handleToast(error, 'error');
    });
};

const ReceivedPaymentFormEnhancer = ({
  // props from formik
  values,
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setStatus,
  setValues,
  isSubmitting,
  errors,
  touched,
  setFieldTouched,

  // custom props
  type,
  organizationCurrency,
  customerList,
  newItem,
  history,
  handleToast,
  updateCustomerList,
  invoiceList,
  cofirmState,
  ...props
}) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [staticVal, setStaticValues] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState();
  const [fullAmountRec, setFullAmountRec] = useState(false);
  const [dateRangeModal, setDataRangeModal] = useState(false);
  const [SubmittingState, setSubmittingState] = useState(false);
  const [comingFromContact, setComingFromContact] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoiceFilterDate, setInvoiceFilterDate] = useState({
    startDate: moment(getOrganizationDate()),
    endDate: moment(getOrganizationDate()),
    displayDateText: '(Date Filter)',
  });
  const [dateRangeLoader, setDteRangeLoader] = useState(false);
  const [preSelectedInvoices, setPreSelectedInvoices] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState({});

  const [hasMobile] = useMobileDetection();
  /*const [filters, setFilters] = useState({
    dateRange: {
      selection: {
        startDate: moment(getOrganizationDate()).subtract(7, 'days'),
        endDate: moment(endOfDay(getOrganizationDate())),
        key: 'selection',
      },
    },
  });*/

  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, staticVal]);

  useEffect(() => {
    setStaticValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { contactId = null } = queryString.parse(window.location.search);
    if (contactId) {
      customerChangeHandler(+contactId, 'from_contacts');
      // setComingFromContact(true);
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (type === 'edit') {
      let preSelectedItems = invoiceList.filter(
        (item) => item.payment && item.payment > 0
      );
      setPreSelectedInvoices(preSelectedItems);
    }
  }, []);

  // useEffect(() => {
  //   if (type === 'edit') {
  //     setFieldValue('_payment_mode', props.payment_mode_name);
  //   } else if (type === 'add') {
  //     setFieldValue('_payment_mode', props.payment_mode_name);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleAlert = () => {
    let { checkout } = validateCustomerpaymentForm(values, staticVal);
    if (!checkout) props.setPrompt();
    else props.setPrompt(false);
  };

  const handlePaymentChange = (option) => {
    let isNewOption = !status.paymentMethods.find(
      (method) => method.name === option.label
    );
    if (!isNewOption) {
      setFieldValue('payment_mode', option.id);
      setFieldValue('payment_mode_name', option.name);
    }
  };

  const [, setShowMergeModal] = useState(false);

  useEffect(() => {
    return () => {
      // allValls = [];
      setShowMergeModal(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadingItem] = useState(false);

  const handleItemQuantityChange = (quantityValue, index) => {
    let quantity = quantityValue.floatValue;
    let itemList = [...values.invoiceList];
    let currentItem = itemList[index];
    itemList[index] = { ...currentItem, payment: quantity };
    setFieldValue('invoiceList', itemList);
    const checkExist = itemList.some((item) => item.payment_due < item.payment);
    // if (checkExist) {
    //   setSubmittingState(true);
    // } else {
    //   checkPriceValidator(itemList);
    // }
  };

  const checkPriceValidator = (itemList) => {
    const invoiceAmount = (
      itemList.reduce(
        (total, val) => (val.payment ? total + val.payment : total + 0),
        0
      ) || 0
    ).toFixed(2);

    const totalPayment = values.amount_received
      ? parseFloat(values.amount_received).toFixed(2)
      : 0;
    if (Number(invoiceAmount + values.refund_amount) > Number(totalPayment)) {
      setSubmittingState(true);
    } else {
      setSubmittingState(false);
    }
  };

  const checkAmountValidator = (itemList, amount_received) => {
    const invoiceAmount = (
      itemList.reduce(
        (total, val) => (val.payment ? total + val.payment : total + 0),
        0
      ) || 0
    ).toFixed(2);

    const totalPayment = amount_received
      ? parseFloat(amount_received).toFixed(2)
      : 0;
    if (Number(invoiceAmount + values.refund_amount) > Number(totalPayment)) {
      setSubmittingState(true);
    } else {
      setSubmittingState(false);
    }
  };

  // function handleSaveAndSend(event, submitFlag) {
  //   setFieldValue('saveAndSend', true);
  //   if (submitFlag === false || submitFlag === undefined) {
  //     event.preventDefault();
  //     setShowMergeModal(true);
  //   } else {
  //     return formSubmit(
  //       event,
  //       values.items,
  //       setFieldValue,
  //       handleSubmit
  //       // wareId
  //     );
  //   }
  // }

  const closeModal = () => {
    setShowModal(false);
    setStatus({
      ...status,
      showContactCreateModal: false,
    });
  };

  const viewInvoiceModal = () => {
    return (
      <CustomModal
        Icon={NewInvoiceIcon}
        showModal={showModal}
        title="View Invoice"
        renderActions={false}
        size="large"
        onClose={closeModal}
        linkTo={`invoices/${invoiceId}`}
        classNames="so_view_invoices"
      >
        <InvoiceDetails id={invoiceId} inModal />
      </CustomModal>
    );
  };

  /*const handleDateRangeChange = (payload) => {
    setFilters({
      ...filters,
      dateRange: {
        selection: {
          startDate: moment(payload.selection.startDate),
          endDate: moment(endOfDay(payload.selection.endDate)),
          key: 'selection',
        },
      },
    });
  };*/

  const dateRangeFilterHandler = (reset) => {
    if (invoiceFilterDate?.startDate > invoiceFilterDate?.endDate) {
      handleToast(
        '"Start Date" should be smaller than or equal to the "End Date"',
        'error'
      );
      return;
    }
    setDteRangeLoader(true);
    let preSelectedItems = [];

    if (values.invoiceList.length && type === 'add') {
      preSelectedItems = values.invoiceList.filter(
        (item) => item.payment && item.payment > 0
      );
    } else if (values.invoiceList.length && type === 'edit') {
      let preSelectedIds = preSelectedInvoices.map((item) => item.id);
      preSelectedItems = values.invoiceList.filter(
        (item) =>
          preSelectedIds.includes(item.id) || (item.payment && item.payment > 0)
      );
    }

    let displayDateText = `(${getDate(
      invoiceFilterDate.startDate
    )} -  ${getDate(invoiceFilterDate.endDate)})`;

    setInvoiceFilterDate({
      ...invoiceFilterDate,
      displayDateText: displayDateText,
    });

    setDataRangeModal(false);
    let apiURl = `advancepayment/search?customer_id=${
      values.contactId
    }&start=${invoiceFilterDate.startDate.format(
      'YYYY-MM-DD 00:00:00'
    )}&end=${invoiceFilterDate.endDate.format('YYYY-MM-DD 23:59:59')}`;
    if (reset === 'reset') {
      apiURl = `advancepayment/search?customer_id=${values.contactId}`;
      let resetFilterDate = {
        startDate: moment(getOrganizationDate()),
        endDate: moment(getOrganizationDate()),
        displayDateText: '(Date Filter)',
      };
      setInvoiceFilterDate(resetFilterDate);
    }

    restRequest('get', apiURl)
      .then((res) => {
        if (res.length) {
          let preSelectedIds = preSelectedItems.map((item) => item.id);
          let unMatchedInvoices = res.filter((item) => {
            return !preSelectedIds.includes(item.id);
          });
          let allinvoices = [...preSelectedItems, ...unMatchedInvoices];
          setValues({
            ...values,
            //amount_received: 0,
            invoiceList: allinvoices,
          });
        } else {
          setValues({
            ...values,
            // amount_received: 0,
            invoiceList: preSelectedItems,
          });
        }
        setDteRangeLoader(false);
      })
      .catch((error) => {
        setDteRangeLoader(false);
        checkError(error);
        handleToast(error, 'error');
      });
  };

  const dateRangeFilter = () => {
    return (
      <CustomModal
        TItleClass="payment-popup"
        Icon={NewInvoiceIcon}
        showModal={dateRangeModal}
        title="Select Date Range"
        renderActions={true}
        size="large"
        onClose={() => {
          setDataRangeModal(false);
        }}
        classNames="so_view_invoices"
        onSubmit={dateRangeFilterHandler}
        confirmLabel="Apply"
      >
        <div className="date-range-filters">
          <Field
            className="inline-field so_feilds"
            type="date"
            size="large"
            name="Start Date"
            value={invoiceFilterDate.startDate}
            id="startDate"
            handleChange={(date) => {
              invoiceDateFilterHandler(date, 'startDate');
            }}
          />
          <Field
            className="inline-field so_feilds"
            type="date"
            size="large"
            name="End Date"
            minDate={invoiceFilterDate.startDate}
            value={invoiceFilterDate.endDate}
            id="endDate"
            handleChange={(date) => {
              invoiceDateFilterHandler(date, 'endDate');
            }}
          />
        </div>

        {/* <InvoicesFilter
          dateRange={{
            value: filters.dateRange,
            onChange: handleDateRangeChange,
          }}
          clickHandler={dateRangeFilterHandler}
        /> */}
      </CustomModal>
    );
  };

  const payFullHandler = (index) => {
    const updatedList = values.invoiceList.map((singleInvoice, i) => {
      return index === i
        ? { ...singleInvoice, payment: singleInvoice.payment_due }
        : singleInvoice;
    });
    setFieldValue('invoiceList', updatedList);
  };

  const handleError = (index) => {
    if (values.invoiceList[index].payment_due === 0) {
      // console.log(
      //   'sda',
      //   values.invoiceList[index].payment,
      //   values.invoiceList[index].payment_due + values.invoiceList[index].total,
      //   values.invoiceList[index].payment >
      //     values.invoiceList[index].payment_due +
      //       values.invoiceList[index].total,
      //   values.invoiceList[index].payment > values.amount_received
      // );
      return (
        (touched &&
          values.invoiceList.length !== 0 &&
          touched[values.invoiceList[index].id] &&
          values.invoiceList[index].payment >
            values.invoiceList[index].payment_due +
              values.invoiceList[index].total) ||
        values.invoiceList[index].payment > values.amount_received
      );
    }
    return (
      touched &&
      values.invoiceList.length !== 0 &&
      touched[values.invoiceList[index].id] &&
      values.invoiceList[index].payment > values.invoiceList[index].payment_due
    );
  };

  const handleArrowsKey = (e, index) => {
    if (e.key === 'ArrowDown' && index + 1 < values?.invoiceList?.length) {
      const element = document.getElementById(
        `${values.invoiceList[index + 1].id}`
      );
      setTimeout(() => {
        element.select();
        element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }, 100);
    }
  };

  const handleArrowUpKey = (e, index) => {
    if (e.key === 'ArrowUp' && index - 1 >= 0) {
      const element = document.getElementById(
        `${values.invoiceList[index - 1].id}`
      );
      element.select();
      element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  };

  function renderList() {
    return (
      <FieldArray
        name="items"
        render={() => {
          return (
            <div className="so-tbletabs">
              <table className={`w-100 customer-payment-table`}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Invoice No.</th>
                    <th className="right-align">Amount</th>
                    <th className="right-align">Amount Due</th>
                    <th className="right-align">Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {values.invoiceList.length !== 0 ? (
                    values.invoiceList?.map((item, index) => (
                      <tr key={index} className="">
                        <td className="">
                          <span>{getDate(item.invoice_date)}</span>
                        </td>
                        <td className="">
                          <span
                            className="item_view_click_btn"
                            onClick={() => {
                              setInvoiceId(item.id);
                              setShowModal(true);
                            }}
                          >
                            {item.invoice_no}
                          </span>
                        </td>
                        <td className="right-align">
                          <span>{`${organizationCurrency.symbol}${parseFloat(
                            item.total
                          ).toFixed(2)}`}</span>
                        </td>
                        <td className="right-align">
                          <span>{`${organizationCurrency.symbol}${parseFloat(
                            item.payment_due
                          ).toFixed(2)}`}</span>
                        </td>
                        <td className="right-align">
                          <div className="field-pay">
                            <Field
                              size="small"
                              type="number"
                              value={item?.payment}
                              placeholder="$0"
                              decimalScale={6}
                              id={values.invoiceList[index].id}
                              allowNegative={false}
                              thousandSeparator=","
                              onValueChange={(value) =>
                                handleItemQuantityChange(value, index)
                              }
                              removeValueOnClick
                              error={handleError(index)}
                              onBlur={handleBlur}
                              onKeyDown={(e) => handleArrowsKey(e, index)}
                              onKeyUp={(e) => handleArrowUpKey(e, index)}
                            />
                            <small
                              onClick={() => payFullHandler(index)}
                              className="payFullbtn"
                            >
                              Pay in Full
                            </small>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="no-record">
                      <td colSpan={5} style={{ padding: '10px' }}>
                        Invoices Not Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          );
        }}
      />
    );
  }

  const getExcessAmmount = () => {
    let amountReceived = values.amount_received
      ? parseFloat(values.amount_received).toFixed(2)
      : 0;

    let Payments = values?.invoiceList?.reduce(
      (total, val) => (val.payment ? total + val.payment : total + 0),
      0
    );
    let refundAmount = values.refund_amount ? values.refund_amount : 0;
    let total = amountReceived - (Payments + refundAmount);

    return total.toFixed(2);
  };

  const renderSummary = () => {
    let currencySymbol = organizationCurrency?.symbol || '$';
    return (
      <>
        <div className="cust-table-summary">
          <div className="w-40 left-col">
            {/* <span>Applied Invoices:&nbsp;0</span> */}
          </div>
          <div className="w-30 right-col">
            <div className="single-summary-rec mb-6">
              <span className="">Amount Received </span>
              <span className="semi-bold">{`${currencySymbol}${
                values.amount_received
                  ? parseFloat(values.amount_received).toFixed(2)
                  : parseFloat(0).toFixed(2)
              }`}</span>
            </div>

            <div className="single-summary-rec mb-6">
              <span className="">Amount used for Payments </span>
              <span className="semi-bold">{`${currencySymbol}${parseFloat(
                values?.invoiceList?.reduce(
                  (total, val) =>
                    val.payment ? total + val.payment : total + 0,
                  0
                ) || 0
              ).toFixed(2)}`}</span>
            </div>
            <div className="single-summary-rec border-top">
              <span className="">
                <strong>Refund Amount</strong>
              </span>
              <span className="semi-bold">
                {`${currencySymbol}` +
                  parseFloat(values.refund_amount)?.toFixed(2)}
              </span>
            </div>
            <div className="single-summary-rec border-top">
              <span className="">
                <strong>Amount in Excess</strong>
              </span>
              <span className="semi-bold">{`${currencySymbol}${getExcessAmmount()}`}</span>
            </div>
          </div>
        </div>
        <hr />
        <hr />
        <div style={{ color: '#ccc', marginTop: '7px' }}>
          **List contains only SENT invoices
        </div>
      </>
    );
  };

  const renderModals = () => {
    return (
      <Fragment>
        {contactCreateModal()}
        {viewInvoiceModal()}
        {dateRangeFilter()}
        {showCustomerModal && contactDeatilModal()}
      </Fragment>
    );
  };

  const handleClearAmount = (e) => {
    e.preventDefault();
    const list = [...values.invoiceList];
    let updatedList = list.map((val) => ({ ...val, payment: '' }));
    setFieldValue('invoiceList', updatedList);
  };

  let submitFlag = true;
  const [stopRequest, setstopRequest] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const handleCustomerModal = (id) => {
    setCustomerId(id);
    setShowCustomerModal(true);
  };
  const contactDeatilModal = () => {
    return (
      <CustomModal
        size="large"
        className="contacts_modal_view"
        showModal={showCustomerModal}
        title="View Contact"
        modaltitle="inventory_modal_title"
        Icon={NewContactIcon}
        renderActions={false}
        onClose={() => setShowCustomerModal(false)}
        linkTo={`contacts/${customerId && customerId}`}
      >
        <Customer id={customerId && customerId} inModal={true} />
      </CustomModal>
    );
  };
  const handleReceivedAmount = ({ target }) => {
    setFullAmountRec(target.checked);
    if (target.checked) {
      let val = [...values.invoiceList];
      let list = val.map((val) => ({ ...val, payment: val.payment_due }));
      let receivedfullAmount = values?.invoiceList?.reduce(
        (total, val) => total + val.payment_due,
        0
      );

      setValues({
        ...values,
        amount_received: parseFloat(
          receivedfullAmount + values.refund_amount
        ).toFixed(2),
        invoiceList: list,
      });
      // if (receivedfullAmount <= 0 || receivedfullAmount === undefined) {
      //   setSubmittingState(true);
      // } else {
      checkAmountValidator(list, receivedfullAmount);
      // }
    } else {
      let val = [...values.invoiceList];
      let list = val.map((val) => ({ ...val, payment: '' }));
      setValues({
        ...values,
        // amount_received: 0,
        invoiceList: list,
      });
    }
  };

  const customerChangeHandler = (contactId = null, from = '') => {
    if (contactId) {
      setDteRangeLoader(true);
      restRequest(
        'get',
        `advancepayment/invoice/create?customer_id=${contactId}`
      )
        .then((res) => {
          setLoading(false);
          if (from) {
            const newCustomer = res?.customer_list[0];
            const old_obj = {
              id: newCustomer.id,
              display_name: newCustomer.display_name,
            };

            const { id: value, display_name: label, ...rest } = old_obj;
            const new_customer = { value, label, ...newCustomer };
            setCurrentCustomer(new_customer);
            updateCustomerList(newCustomer);
          }
          setValues({
            ...values,
            // amount_received: null,
            invoiceList: res.invoices,
            contactId: contactId,
          });
          setFullAmountRec(false);
          setDteRangeLoader(false);
          let resetFilterDate = {
            startDate: moment(getOrganizationDate()),
            endDate: moment(getOrganizationDate()),
            displayDateText: '(Date Filter)',
          };
          setInvoiceFilterDate(resetFilterDate);
        })
        .catch((error) => {
          setDteRangeLoader(false);
          checkError(error);
          handleToast(error, 'error');
        });
    } else {
      setValues({
        ...values,
        amount_received: '',
        customerNotes: '',
        invoiceList: [],
        contactId: null,
      });
      setFullAmountRec(false);
      let resetFilterDate = {
        startDate: moment(getOrganizationDate()),
        endDate: moment(getOrganizationDate()),
        displayDateText: '(Date Filter)',
      };
      setInvoiceFilterDate(resetFilterDate);
    }
  };

  // Date Filter
  const invoiceDateFilterHandler = (date, type) => {
    if (!date) return;
    const datefilter = {
      ...invoiceFilterDate,
      [type]: date,
    };
    setInvoiceFilterDate(datefilter);
  };

  // Date Filter end

  const handleContactCreateSubmit = (newCustomer) => {
    const old_obj = {
      id: newCustomer.id,
      display_name: newCustomer.display_name,
    };

    const { id: value, display_name: label, ...rest } = old_obj;
    const new_customer = { value, label, ...newCustomer };
    setCurrentCustomer(new_customer);
    updateCustomerList(newCustomer);
    customerChangeHandler(newCustomer?.id);
    setStatus({
      ...status,
      showContactCreateModal: false,
    });
  };
  function handleSaveAndSend(event, submitFlag) {
    setFieldValue('saveAndSend', true);
    if (submitFlag === false || submitFlag === undefined) {
      event.preventDefault();
      // setShowMergeModal(true);
    } else {
      let dataFromLS = getObjFromLS('module');
      if (type === 'add') {
        setObjInLS('module', {
          ...dataFromLS,
          // id: res.data.advance_payment.payment_no,
          moduleName: 'paymentsmade',
          urlPath: '/paymentsmade',
          action: type === 'add' ? 'add' : 'justedit',
          queryParam: '',
        });
      }
      // setTimeout(() => {
      return formSubmit(event, [], setFieldValue, handleSubmit);
      // }, 1);
    }
  }
  function handleSaveAndPrint(event, submitFlag) {
    setFieldValue('saveAndPrint', true);
    if (submitFlag === false) {
      event.preventDefault();
    } else {
      // setTimeout(() => {
      return formSubmit(event, [], setFieldValue, handleSubmit);
      // }, 1);
    }
  }
  const handlePrompt = (status = true) => {
    setShowPrompt(status);
  };

  const contactCreateModal = () => {
    return (
      <CustomModal
        size="large"
        showModal={status.showContactCreateModal}
        title="Add Contact"
        customWidth="contact-modal-width"
        renderActions={false}
        onClose={closeModal}
        showPrompt={showPrompt}
      >
        <AddContact
          type="purchaseOrder"
          inModal
          close={closeModal}
          onSubmit={(res) => handleContactCreateSubmit(res.data)}
          handleToast={handleToast}
          contactTypeId={2}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };

  const handleAdjustmentChange = (adjustmentValue) => {
    let adjustment = adjustmentValue.floatValue;
    if (adjustment > 0) {
      let adjustmentString = adjustment.toString();

      if (adjustmentString.length > 10) {
        // adjustmentString = adjustmentString.slice(0, 10);
        // update to 14 for getting value after decimal points
        adjustmentString = adjustmentString.slice(0, 14);
      }
      adjustment = parseFloat(adjustmentString)?.toFixed(2);
    }
    setFieldValue('amount_received', adjustment);
    // if (adjustment <= 0 || adjustment === undefined) {
    //   setSubmittingState(true);
    // } else {
    //   setSubmittingState(false);
    // }
  };

  const getReceivedFullAmount = () => {
    let amount = values?.invoiceList?.reduce(
      (total, val) => total + val.payment_due,
      0
    );
    return parseFloat(amount + values.refund_amount).toFixed(2);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          {renderModals()}
          <Form
            className="salesorder-form feilds_so"
            onSubmit={(event) => {
              if (stopRequest === true) event.preventDefault();
              else if (submitFlag === false) {
                event.preventDefault();
                setShowMergeModal(true);
              } else {
                formSubmit(event, values.items, setFieldValue, handleSubmit);
              }
            }}
          >
            <FormSection
              title={`${
                type && type === 'add'
                  ? 'Add'
                  : type === 'edit'
                  ? 'Edit'
                  : 'Clone'
              } Payment`}
            >
              <div className="two-fields-row">
                <div className="">
                  <Field
                    type="DynamicPaginationDropdown"
                    name="Customer Name"
                    id="contactId"
                    clearable={true}
                    value={values.contactId}
                    newCust={currentCustomer}
                    editCust={values.contactObj}
                    handleChange={(option) => {
                      if (option?.value === 'createNew') {
                        setStatus({ ...status, showContactCreateModal: true });
                      } else {
                        customerChangeHandler(option?.value);
                      }
                    }}
                    required
                    addButton
                    alphabetSort
                    error={touched.contactId && errors.contactId}
                    placeholder={'Select'}
                    onBlur={() => {
                      setFieldTouched('contactId', true);
                    }}
                    api="advancepayment/contact"
                    disabled={type === 'edit' || comingFromContact}
                  />
                  {/* <Field
                className="w-100"
                type="PaginationDropdown"
                name="Customer Name"
                placeholder="Select Customer"
                autoFocus={true}
                clearable={false}
                id="contactId"
                value={values.contactId}
                options={customerList}
                dropdownValue="id"
                dropdownLabel="display_name"
                addButton
                required
                onBlur={handleBlur}
                error={touched.contactId && errors.contactId}
                size="large"
                handleChange={(option) => {
                  if (option.value === 'createNew') {
                    setStatus({ ...status, showContactCreateModal: true });
                  } else {
                    customerChangeHandler(option.id);
                  }
                }}
                disabled={type === 'edit' || comingFromContact}
              /> */}

                  {values.contactId && (
                    <div className="view-detail">
                      <button
                        type="button"
                        className=""
                        onClick={() => handleCustomerModal(values.contactId)}
                      >
                        {' '}
                        View Details
                      </button>
                    </div>
                  )}
                </div>
                <div
                  className={values.contactId ? 'displaying' : 'notdisplaying'}
                >
                  <Field
                    className="inline-field w-100"
                    type="number"
                    size=""
                    name="Payment No"
                    id="paymentId"
                    placeholder=""
                    value={values.paymentId}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.paymentId && errors.paymentId}
                    disabled
                  />
                </div>
              </div>
              <div
                className={values.contactId ? 'displaying' : 'notdisplaying'}
              >
                <div className="three-fields-row">
                  <Field
                    className=""
                    type="date"
                    name="Payment Date"
                    id="paymentDate"
                    size=""
                    value={values.paymentDate}
                    handleChange={(date) => {
                      setFieldValue('paymentDate', date);
                    }}
                    onBlur={() => setFieldTouched('paymentDate', true)}
                    required
                    error={touched.paymentDate && errors.paymentDate}
                  />
                  <div className="">
                    <Field
                      className=""
                      size=""
                      type="number"
                      name="Amount Received"
                      id="amount_received"
                      placeholder="0"
                      value={values.amount_received}
                      // handleChange={(e) => {
                      //   console.log(e.target.value);
                      // }}
                      onValueChange={(val) => {
                        handleAdjustmentChange(val);
                      }}
                      decimalScale={2}
                      onBlur={handleBlur}
                      required
                      allowNegative={false}
                      thousandSeparator=","
                      error={touched.amount_received && errors.amount_received}
                      disabled={fullAmountRec}
                      removeValueOnClick
                    />
                    <div style={{ marginTop: '6px' }}>
                      <input
                        type="checkbox"
                        className="view-details-btn"
                        id="check"
                        onChange={handleReceivedAmount}
                        checked={fullAmountRec}
                      />
                      <label
                        style={{ cursor: 'pointer', paddingLeft: '5px' }}
                        htmlFor="check"
                      >
                        {`Received full amount  (${
                          organizationCurrency.symbol
                        }${getReceivedFullAmount()})`}
                      </label>
                    </div>
                  </div>
                  <Field
                    className="inline-field"
                    size=""
                    type="dropdown"
                    name="Payment Mode"
                    id="payment_mode"
                    clearable={false}
                    value={values.payment_mode}
                    options={status.paymentMethods}
                    dropdownValue="id"
                    dropdownLabel="name"
                    handleChange={handlePaymentChange}
                    required
                    onBlur={handleBlur}
                    error={touched.payment_mode && errors.payment_mode}
                  />
                </div>

                <div className="so-form-inner">
                  <div className="middle-row">
                    <Field
                      className="inline-field description_box "
                      size="large"
                      type="textarea"
                      name="Customer Notes"
                      value={values.customerNotes}
                      id="customerNotes"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.customerNotes && errors.customerNotes}
                    />
                  </div>
                  <Field
                    className="inline-field"
                    name="Reference"
                    size="large"
                    id="reference"
                    value={values.reference}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.reference && errors.reference}
                  />
                </div>

                <div className="form-field">
                  <div className="table-title mb_3 d-flex align-center">
                    <div className="d-flex payment-invoice-main">
                      <label className="semi-bold">Select Invoices</label>{' '}
                      <span className="icon_div d-flex align-center">
                        <MdHelpOutline
                          className="material-icon blue-icon help"
                          style={{ marginRight: '1.7rem' }}
                        />
                        <div className="tooltip_tooltip">
                          Note: If you've entered a Payment amount for any
                          unpaid invoices, those invoices will continue to be
                          shown at the top of the list, irrespective of the Date
                          Range filter that you apply.
                        </div>
                      </span>
                      <button
                        type="button"
                        className="buttonDateRangeFilter"
                        style={{
                          marginRight: '10px',
                        }}
                        onClick={() => setDataRangeModal(true)}
                      >
                        {invoiceFilterDate.displayDateText}
                      </button>
                      {invoiceFilterDate.displayDateText !==
                        '(Date Filter)' && (
                        <button
                          type="button"
                          className="buttonDateRangeFilter"
                          onClick={() => dateRangeFilterHandler('reset')}
                        >
                          Reset
                        </button>
                      )}
                    </div>
                    <button onClick={handleClearAmount}>
                      Clear Applied Amount
                    </button>
                  </div>

                  {loadingItem && <Loader />}

                  {/* <span
                onClick={() => {
                  setDataRangeModal(true);
                }}
              >
                Date Filter
              </span> */}

                  {dateRangeLoader ? <Loader /> : renderList()}
                  {renderSummary()}
                </div>

                {values.contactId && (
                  <FormAction
                    className="So-actions payment-actions"
                    disabled={SubmittingState || isSubmitting}
                    saveBtnFlag
                    onSubmit={handleSubmit}
                    onCancel={() => {
                      let dataFromLS = getObjFromLS('module');
                      let redirectUrl = '';

                      if (dataFromLS.moduleName === 'contacts') {
                        history.push('/contacts');
                        return;
                      }

                      if (
                        window.location.pathname.includes('add') ||
                        window.location.pathname.includes('edit')
                      ) {
                        history.push('/paymentsmade' + redirectUrl);
                      } else {
                        setObjInLS('module', {
                          ...dataFromLS,
                          action: null,
                        });
                        history.push('/r');
                      }
                    }}
                    isSalesOrder={true}
                    isSO
                    dropUpSO={[
                      {
                        label: 'Save & Print',
                        onClick: (event) => {
                          setstopRequest(true);
                          handleSaveAndPrint(event, submitFlag);
                          //   handleSubmit();
                        },
                        disabled: SubmittingState || isSubmitting,
                      },
                      {
                        label: 'Save & Send',
                        onClick: (event) => {
                          setstopRequest(true);
                          handleSaveAndSend(event, submitFlag);
                          //   handleSubmit();
                        },
                        disabled: SubmittingState || isSubmitting,
                      },
                    ].filter((item) =>
                      hasMobile ? item.label !== 'Save & Print' : item
                    )}
                    additionalFields={[
                      {
                        label: 'Save & Send',
                        onClick: (event) => {
                          handleSaveAndSend(event, submitFlag);
                          //   handleSubmit();
                        },
                        disabled: SubmittingState || isSubmitting,
                      },
                      {
                        label: 'Save & Print',
                        onClick: (event) => {
                          handleSaveAndPrint(event, submitFlag);
                          // handleSubmit();
                        },
                        disabled: SubmittingState || isSubmitting,
                      },
                    ].filter((item) =>
                      hasMobile ? item.label !== 'Save & Print' : item
                    )}
                  />
                )}
              </div>
            </FormSection>
            <ErrorFocus />
          </Form>
        </>
      )}
    </Fragment>
  );
};

export default formEnhancer(ReceivedPaymentFormEnhancer);
