import moment from 'moment';

import { restRequest } from '../../Helpers/RequestHelper';
import { getObjFromLS, getOrganizationDate } from '../../Helpers';
import {
  emptyItem,
  prepareAddItems,
  prepareEditItems,
  prepareItemsList,
  prepareItemListForSO,
} from './Utils';
import { checkError } from '../../Helpers/AuthHelper';

export const getTermsList = () => {
  return restRequest('get', `invoiceterms`)
    .then((invoiceTermsList) => {
      return invoiceTermsList;
    })
    .catch((error) => checkError(error));
};

export const getInvoiceEditData = (invoiceId) => {
  return restRequest('get', `invoices/${invoiceId}/edit`)
    .then((res) => {
      let invoiceItems = prepareEditItems(res.invoice_info.invoice_details);
      //const { no_discount, at_individual_item_level } = res.discount_preference.preferences;
      const invoice = {
        invoiceId: res.invoice_info.id,
        status: res.invoice_info.status,
        invoiceNo: res.invoice_info.invoice_no,
        orderNo: res.invoice_info.order_no,
        saleOrderDate: res.invoice_info.order_no
          ? res?.invoice_info?.sales_orders[0]?.order_date
          : null,
        soId:
          res.invoice_info.sales_orders &&
          res.invoice_info.sales_orders.length > 0
            ? res.invoice_info.sales_orders[0].id
            : null,
        customerId: res.invoice_info.customer_id,
        invoiceObj: {
          label: res.invoice_info.customer.display_name,
          value: res.invoice_info.customer.id,
        },
        invoiceTerm: {
          name: res.invoice_info.invoice_term_name,
          value: res.invoice_info.invoice_term_value,
        },
        note: res.invoice_info.note,
        dueDate: moment(res.invoice_info.due_date),
        invoiceDate: moment(res.invoice_info.invoice_date),
        shippingCharge: res.invoice_info.shipping_charge,
        adjustment: res.invoice_info.adjustment,
        transactionDiscount: res.invoice_info.discount_transaction_level,
        transactionDiscountType: res.invoice_info.discount_type,
        discountLevel: res.invoice_info.discount_level ? res.invoice_info.discount_level : 'no_discount',
        // discountLevel:no_discount
        // ? 'no_discount'
        // : at_individual_item_level
        // ? 'item'
        // : 'transaction',
        items: invoiceItems,
        salesPersonId: res.invoice_info.sales_person_id,
        hasTermsAndConditions:
          res.invoice_info.terms_and_condition &&
          !!res.invoice_info.terms_and_condition.length,
        termsAndConditions:
          res.invoice_info.terms_and_condition &&
          !!res.invoice_info.terms_and_condition.length
            ? res.invoice_info.terms_and_condition
            : res.invoice_preference.terms,
      };

      const itemsList = prepareItemsList(
        res.invoiceable_items,
        res.warehouses[0].id
      );

      return {
        invoice: invoice,
        taxList: res.taxes,
        customersList: res.customers,
        warehousesList: res.warehouses,
        confirmedSalesOrdersList:
          res?.invoiceable_confirmed_sales_orders?.sale_orders || [],
        itemsList,
        disableDate: !res.invoice_info.invoice_term_value === null,
        invoiceTermsList: res.invoice_terms,
        discountPreference: res.discount_preference.preferences,
        itemLevelIndvidual:
          res.invoice_info.discount_level === 'item' ? true : false,
        currency: res.base_currency,
        salesPersonsFromDB: res.sales_person,
        is_sales_person: res.sales_person_user,
        invoiceItemsLength: !res.invoice_info?.sales_orders.length
          ? []
          : res?.invoice_info?.invoice_details,
        owner_can_edit_in_lock_status: res.owner_can_edit,
      };
    })
    .catch((e) => {
      checkError(e);
      return { error: e?.response };
    });
};

export const getInvoiceCreateData = async (
  salesOrderId,
  inModal = false,
  contactId
) => {
  const url = contactId
    ? `invoices/create${contactId ? `?page=1&contactId=${contactId}` : ''}`
    : `invoices/create${salesOrderId ? `?sales_order_id=${salesOrderId}` : ''}`;
  return restRequest('get', url)
    .then((res) => {
      let role = getObjFromLS('role');
      let roleId = role.id;
      let roleSlug = role.slug;
      let { no_discount, at_individual_item_level } =
        res.discount_preference.preferences;
      const invoice = {
        invoiceId: null,
        invoiceObj: {
          label: res.customers[0]?.display_name || '',
          value: res.customers[0]?.id || null,
        },
        invoiceNo: res.invoice_no,
        orderNo: res.order_no,
        saleOrderDate: res.order_no ? res?.sales_order?.order_date : null,
        invoiceDate: !res.order_no
          ? moment(getOrganizationDate())
          : res.order_no &&
            moment(res?.sales_order?.order_date) < moment(getOrganizationDate())
          ? moment(getOrganizationDate())
          : moment(res?.sales_order?.order_date),
        dueDate: !res.order_no
          ? moment(getOrganizationDate())
          : res.order_no &&
            moment(res?.sales_order?.order_date) < moment(getOrganizationDate())
          ? moment(getOrganizationDate())
          : moment(res?.sales_order?.order_date),
        salesPersonId: res.sales_order ? res.sales_order.sales_person_id : null,
        shippingCharge: res.sales_order ? res.sales_order.shipping_charge : 0,
        adjustment: res.sales_order ? res.sales_order.adjustment : 0,
        transactionDiscount: res.sales_order
          ? res.sales_order.discount_transaction_level
          : 0,
        transactionDiscountType: res.sales_order
          ? res.sales_order.discount_type
          : 'percent',
        discountLevel: inModal
          ? res.sales_order.discount_level === 'transaction'
            ? 'transaction'
            : res.sales_order.discount_level === 'item'
            ? 'item'
            : 'no_discount'
          : no_discount
          ? 'no_discount'
          : at_individual_item_level
          ? 'item'
          : 'transaction',
        items: res.sales_order
          ? prepareAddItems(
              res.sales_order.sales_order_item_details,
              res.sales_order.warehouse_id
            )
          : [],
        // warehousesId: res.warehouses[0].id
        termsAndConditions: res.invoice_preference.terms,
        invoiceTerm:
          res.order_no &&
          res.hasOwnProperty('customers') &&
          res.customers.length === 1 &&
          res.customers[0].invoice_terms
            ? {
                name: res.customers[0]?.invoice_terms.name,
                value: res.customers[0]?.invoice_terms.value,
              }
            : {
                name: '',
                value: null,
              },
      };
      let warehouseList = [];
      warehouseList.push(
        res.warehouses.find((warehouse) => warehouse.is_primary === true)
      );
      res.warehouses = res.warehouses.filter((list) => list.is_active === 1);
      // Removes inactive warehouse from list
      const itemsList = salesOrderId
        ? prepareItemListForSO(
            res.sales_order.sales_order_item_details,
            warehouseList[0].id
          )
        : prepareItemsList(res.invoiceable_items, warehouseList[0].id);
      return {
        invoice: {
          ...invoice,
          items: invoice.items.length > 0 ? [...invoice.items] : [emptyItem],
          salesPersonId:
            res.sales_person_user === true
              ? res.sales_person[0].users.id
              : invoice.salesPersonId
              ? invoice.salesPersonId
              : roleSlug && roleSlug !== 'sales_person'
              ? res.sales_person.find((p) => p.role_id === roleId).users.id
              : null,
        },
        itemsList,
        invoiceTermsList: res.invoice_terms,
        discountPreference: res.discount_preference.preferences,
        warehousesList: res.warehouses,
        customersList: res.customers,
        taxList: res.taxes,
        currency: res.base_currency,
        customerId: res.sales_order && res.sales_order.customer_id,
        salesPersonsFromDB: res.sales_person,
        is_sales_person: roleSlug === 'sales_person',
        error: null,
        invoiceItemsLength: salesOrderId
          ? res.sales_order?.sales_order_item_details
          : [],
      };
    })
    .catch((e) => {
      console.log('error asdf', e);
      //checkError(e);
      return {
        invoice: null,
        itemsList: [],
        invoiceTermsList: null,
        discountPreference: null,
        warehousesList: [],
        customersList: [],
        taxList: [],
        currency: null,
        customerId: null,
        salesPersonsFromDB: null,
        is_sales_person: false,
        error: e,
      };
    });
};
