import React from 'react';

// import { formatNumber } from '../../../../Helpers/FormatNumber';

import Table from './../../../../Components/Table/Table';
// import { GridLayout } from '../../../../Components/Layout/Layout'
import ListTableImageColumn from '../../../../Components/ListTable/ListTableImageColumn';
import Quantity from '../../../../Components/Fragments/Quantity/Quantity';

const PackageItemInfo = ({
  itemList,
  packageInfo,
  itemWareList,
  inModal,
  totalQuantity,
  className = ''
}) => {
  /*var tableHeaders = ['Items', 'Ordered', 'Shipped', 'Total Return Qty']
  var tableData = ['itemName', 'totalQuantity', 'shipped', 'totalReturnQuantity']
  if(packageInfo.warehouse_list.length > 1) {
      tableHeaders = ['Items', 'Warehouse', 'Ordered', 'Shipped', 'Total Return Qty']
      tableData = ['itemName', 'warehouse', 'totalQuantity', 'shipped', 'totalReturnQuantity']
  }*/
  const tableHeaders = [
    'Items',
    'Warehouse',
    'Ordered',
    'Shipped',
    'Total Return Qty.',
  ];
  const tableData = [
    'itemName',
    'warehouse',
    'totalQuantity',
    'shipped',
    'totalReturnQuantity',
  ];
  // console.log('packedItem',itemList[0].shipped)
  const rightAlignColumns = [4];
  //console.log('itemList', itemList)
  const packedItems = itemList.map((packedItem, index) => {
    //console.log('packedItem', packedItem)
    return {
      itemName: (
        <ListTableImageColumn
          images={packedItem.items.images}
          name={packedItem.items.name}
          id={packedItem.items.id}
          showModalUponClick={inModal ? false : true}
          sku={packedItem.items.sku}
        />
      ),
      totalQuantity: (
        <Quantity quantity={packedItem.quantity} unit={packedItem.items.unit} />
      ),
      shipped: (
        <Quantity quantity={packedItem.shipped} unit={packedItem.items.unit} />
      ),
      totalReturnQuantity: (
        <Quantity
          quantity={packedItem.sale_return.quantity}
          unit={packedItem.items.unit}
        />
      ),
      warehouse:
        packedItem.items.inventory_type === 'noninventory'
          ? 'Un-tracked item'
          : itemWareList[index].warehouse_name,
    };
  });
  return (
    <div className="package-item-info sales_return_table">
      <Table
        list={packedItems}
        tableHeaders={tableHeaders}
        tableData={tableData}
        rightAlignColumns={rightAlignColumns}
        className={`${className} details-table`}
      // className=" "
      />

      <div />
      {/* <div style ={{marginRight:'2.5rem' , margin: '20px 20px' ,textAlign :'right'}}>
          <div className="padding-top">
            <span className="semi-bold" style={{marginRight:'5rem'}}>Total Return Quantity</span>
            <span className="semi-bold">{totalQuantity}</span>
          </div>     
        </div>  */}
    </div>
  );
};

export default PackageItemInfo;
