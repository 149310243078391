import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { endOfDay, startOfDay } from 'date-fns'

import { restRequest } from '../../Helpers/RequestHelper'
import { getOrganizationDate } from '../../Helpers/Date/OrganizationDate'

import Form from './../Form/Form'
import Field from '../Form/Field/Field'
import FormAction from '../Form/FormAction'

import './Export.css'
import {checkError} from "../../Helpers/AuthHelper";


class Export extends Component {

  state = {
    contactType: 'customer',
    filterType: 'allContacts',
    dateFrom : moment(startOfDay(getOrganizationDate())),
    dateTo: moment(endOfDay(getOrganizationDate())),
  }

  checkExportType() {
    if (this.props.name === "Contacts") {
      return {
        contact_type: this.state.contactType
      }
    }
    return {}
  }

  handleSubmit = event => {
    event.preventDefault()
    var filterType = (this.state.filterType === 'allContacts') ? 'all' : this.state.filterType

    restRequest('post', `${this.props.submitURL}`, {
      ...this.checkExportType(),
      filter: filterType,
      starting_date: this.state.dateFrom.format('YYYY-MM-DD 00:00:00'),
      ending_date: this.state.dateTo.format('YYYY-MM-DD 23:59:59'),
    })
      .then(res => {
        let csvFile = 'data:text/csv;charset=utf-8,' + res
        let encodedUri = encodeURI(csvFile)
        let link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', `${this.props.name}.csv`)
        document.body.appendChild(link)
        link.click()
        this.props.handleToast(`${this.props.name} exported successfully`, 'success')
      })
      .catch(error => {
        checkError(error, this.props.handleToast)
        //this.props.handleToast(error, 'error')
      })
  }

  handleChange = event => {
    let value = event.target.value
    let name = event.target.name
    this.setState({ [name]: value })
  }

  renderExport() {
    return (
      <Form onSubmit={this.handleSubmit}>
        {
          this.props.name === "Contacts" && (
            <Field
              className="radio-field"
              type="radio"
              name="Contact Type"
              required
            >
              <div className="radio-container" onChange={this.handleChange}>
                <div className="radio-btn">
                  <input
                    type="radio"
                    id="customer"
                    name="contactType"
                    value="customer"
                    checked={this.state.contactType === "customer"}
                    readOnly
                  />
                  <label htmlFor="customer">Customer</label>
                  <span className="radio-circle" />
                </div>

                <div className="radio-btn">
                  <input
                    type="radio"
                    id="vendor"
                    name="contactType"
                    value="vendor"
                    checked={this.state.contactType === "vendor"}
                    readOnly
                  />
                  <label htmlFor="vendor">Vendor</label>
                  <span className="radio-circle" />
                </div>

                <div className="radio-btn">
                  <input
                    type="radio"
                    id="all"
                    name="contactType"
                    value="all"
                    checked={this.state.contactType === "all"}
                    readOnly
                  />
                  <label htmlFor="all">All</label>
                  <span className="radio-circle" />
                </div>
              </div>
            </Field>
          )
        }

        <Field
          className="radio-field"
          type="radio"
          name="Export Filter"
          required
        >
          <div className="radio-container" onChange={this.handleChange}>
            <div className="radio-btn">
              <input
                type="radio"
                id="allContacts"
                name="filterType"
                value="allContacts"
                checked={this.state.filterType === "allContacts"}
                readOnly
              />
              <label htmlFor="allContacts">All</label>
              <span className="radio-circle"/>
            </div>

            <div className="radio-btn">
              <input
                type="radio"
                id="custom"
                name="filterType"
                value="custom"
                checked={this.state.filterType === "custom"}
                readOnly
              />
              <label htmlFor="custom">Custom</label>
              <span className="radio-circle"/>
            </div>
          </div>
        </Field>

        {
          this.state.filterType === "custom" && (
            <Fragment>
              <Field
                className="inline-field"
                type="date"
                name="From"
                id="dateFrom"
                value={this.state.dateFrom}
                handleChange={date => this.setState({ dateFrom: date })}
              />

              <Field
                className="inline-field"
                type="date"
                name="To"
                id="dateTo"
                value={this.state.dateTo}
                minDate={this.state.dateFrom}
                handleChange={date => this.setState({ dateTo: date })}
              />
            </Fragment>
          )
        }

        <FormAction
          onCancel={this.props.onCancel}
          onSubmit={this.props.onCancel}
          submitLabel="Export"
          className="export_btn"
        />
      </Form>
    )
  }

  render() {
    return this.renderExport()
  }
}

export default Export