import React, { Component, Fragment } from 'react';
import queryString from 'query-string';

import {
  NewSalesReturnIcon,
  SalesReturnIcon,
} from '../../Assets/Navigation/NavigationIcons';
import { restRequest } from '../../Helpers/RequestHelper';
import GenerateOptions from '../../Helpers/ListHelper/GenerateOptions';
import { routesPath } from '../Navigation/NavigationUtils';
// import shouldShowModal from '../../Helpers/ShouldShowModal'
// import getDate from '../../Helpers/Date/GetDate'
import getStatusColor from '../../Helpers/GetStatusColor';
import SaleReturnDetails from '../../Pages/SalesReturn/SalesReturnDetails';
import AddSalesReturn from '../../Pages/SalesReturn/AddSalesReturn';
import EditSalesReturn from '../../Pages/SalesReturn/EditSalesReturn';
import { DropshipIcon } from '../../Assets/List/ListIcons'; //, BackorderIcon, CloneIcon
import SalesOrderImage from '../../Components/Onboarding/Diagrams/SalesOrderImage';
// import SalesOrderFlowDiagram from '../../Components/Onboarding/Diagrams/SalesOrderFlowDiagram'
import SalesOrderFlowDiagram from '../../Components/Onboarding/Diagrams/SalesReturnFlowDiagram';

// import { HorizontalColumnJoin } from '../../Components/ListTable/ListTableColumnJoin'
import Alert from '../../Components/Alert/Alert';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
import PDFViewer from '../../Components/PDFViewer/PDFViewer';
// import BulkDropshipBackorder from './BackorderDropship/BulkDropshipBackorder'
import CustomModal from '../../Components/CustomModal/CustomModal';
import ListTable from '../../Components/ListTable/ListTable';
import CheckedMenu, {
  Button,
  ButtonLink,
  Options,
} from '../../Components/CheckedMenu/CheckedMenu';
import SearchResult from '../../Components/SearchBar/SearchResult';
import Export from '../../Components/Export/Export';
// import Onboarding from '../../Components/Onboarding/OnboardingReturn'
import Onboarding from '../../Components/Onboarding/InitialOnboardingScreen';
import {
  removeValueFromLS,
  getObjFromLS,
  setObjInLS,
  getStringValueFromLS,
} from '../../Helpers/LocalStorage';
import EditIcon from '../../Assets/General/EditIcon';
import DeleteIcon from '../../Assets/General/DeleteIcon';
import MarkAsConfirmedIcon from '../../Assets/General/MarkAsConfirmedIcon';
// import MarkAsVoidIcon from '../../Assets/General/MarkAsVoidIcon'
import PdfIcon from '../../Assets/General/PdfIcon';
import ExportIcon from '../../Assets/General/ExportIcon';

import './SalesReturn.css';

// import EmptyTable from '../../Components/ListTable/EmptyTable'
import EmailIcon from '../../Assets/General/EmailIcon';
import { checkError } from '../../Helpers/AuthHelper';

class SalesReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesreturnId: null,
      loading: true,
      data: [],
      dataExpanded: [],

      totalPage: 1,
      currentPage: 1,
      viewPerPage: 20,
      totalRecords: 0,

      markAsVoidModal: false,
      markAsVoidId: '',

      currentFilter: 'all',
      sortFilter: 'created_at',
      orderBy: 'desc',

      dropshipBackorderModal: false,
      dropshipBackorderType: '',
      dropshipBackorderData: [],

      pdf: null,
      showPDF: false,
      search: '',
      showExportModal: false,
      start: '',
      end: '',
      contactId: '',
      contactName: null,
    };
    this.getID = this.getID.bind(this);
  }

  filterOptions = [
    {
      id: 'none',
      label: 'Status',
      type: 'tagline',
    },
    {
      id: 'all',
      label: 'All',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'all', page: 1 }),
    },
    {
      id: 'draft',
      label: 'Draft',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'draft', page: 1 }),
    },
    {
      id: 'confirmed',
      label: 'Confirmed',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'confirmed', page: 1 }),
    },
    {
      id: 'closed',
      label: 'Received',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'closed', page: 1 }),
    },
    {
      id: 'receiving',
      label: 'PRTL Received',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'receiving', page: 1 }),
    },
    /*{
      id: 'onhold',
      label: 'Onhold',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'onhold', page: 1 })
    },
    {
      id: 'shipped',
      label: 'Shipped',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'shipped', page: 1 })
    },
    {
      id: 'dropshipped',
      label: 'Dropshipped',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'dropshipped', page: 1 })
    },
    {
      id: 'backorder',
      label: 'Backorder',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'backorder', page: 1 })
    },
    {
      id: 'fulfilled',
      label: 'Fulfilled',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'fulfilled', page: 1 })
    },*/
    /*{
      id: 'void',
      label: 'Void',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'void', page: 1 })
    },*/
    /*{
      id: 'invoiced',
      label: 'Invoiced',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'invoiced', page: 1 })
    },
    {
      id: 'not fully invoiced',
      label: 'Not Fully Invoiced',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'not fully invoiced', page: 1 })
    },
    {
      id: 'not fully packed',
      label: 'Not Fully Packed',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'not fully packed', page: 1 })
    }*/
  ];

  sortOptions = [
    {
      id: 'sales_return_date',
      label: 'Return Date',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('sales_return_date') }),
    },
    {
      id: 'sales_return_no',
      label: 'Sales Return No',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('sales_return_no') }),
    },
    {
      id: 'sales_order_no',
      label: 'Sales Order No',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('sales_order_no') }),
    },
    {
      id: 'display_name',
      label: 'Customer Name',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('display_name') }),
    },
    {
      id: 'add_back_stock',
      label: 'Add to Stock',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('add_back_stock') }),
    },
    {
      id: 'sales_return_status',
      label: 'Status',
      type: 'link',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('sales_return_status'),
        }),
    },
    {
      id: 'return_items',
      label: 'Return Items',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('return_items') }),
    },
    /*{
      id: 'return_quantity',
      label: 'Return Quantity',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('return_quantity') })
    },
    {
      id: 'package_status',
      label: 'Packed',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('package_status') })
    },
    {
      id: 'invoice_status',
      label: 'Invoiced',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('invoice_status') })
    },
    {
      id: 'shipment_status',
      label: 'Shipped',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('shipment_status') })
    },
    {
      id: 'total',
      label: 'Amount',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('total') })
    },*/
    {
      id: 'created_at',
      label: 'Created Time',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('created_at') }),
    },
    {
      id: 'updated_at',
      label: 'Last Modified Time',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('updated_at') }),
    },
  ];

  importExportOptions = [
    {
      label: 'Export Sales Return',
      icon: <ExportIcon className="material-icon" />,
      onClick: () => this.openExportModal(),
      disabled: () => !this.hasViewPermission('salesreturns'),
    },
  ];

  componentDidMount() {
    document.title = 'Sale Returns';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  handleOnUpdateQueryString() {
    const {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      contactId = '',
    } = this.parseQueryString();
    if (filter !== this.state.currentFilter) {
      this.setState(
        {
          currentFilter: filter,
        },
        () => this.fetchData()
      );
    }
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: parseInt(page),
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.setState(
        {
          viewPerPage: parseInt(view),
        },
        () => this.fetchData()
      );
    }
    if (search !== this.state.search) {
      this.setState({ search: search, bulkSelectedData: [] }, () =>
        this.fetchData()
      );
    }
    if (contactId !== this.state.contactId) {
      this.setState(
        {
          contactId: contactId,
        },
        () => this.fetchData()
      );
    }
  }

  fetchDataWithQueryString() {
    const { filter, page, sort, orderBy, view, search, start, end, contactId } =
      this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
        search: search,
        start: start,
        end: end,
        contactId: contactId,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    switch (query.filter) {
      case 'all':
      case 'draft':
      case 'confirmed':
      case 'closed':
      case 'receiving':
      case 'void':
        filter = query.filter;
        break;
      default:
        filter = 'all';
    }

    let sort = '';
    switch (query.sort) {
      case 'sales_return_date':
      case 'sales_return_no':
      case 'display_name':
      case 'add_back_stock':
      case 'sales_return_status':
      case 'sales_order_no':
      case 'return_items':
      case 'created_at':
      case 'updated_at':
        sort = query.sort;
        break;
      default:
        sort = 'created_at';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'desc';
    }

    let page = parseInt(query.page);
    if (!page) page = 1;
    let view = parseInt(query.view);
    if (!view) view = 20;

    let search = query.search;
    if (!search) search = '';

    let start = query.start;
    if (!start) start = '';

    let end = query.end;
    if (!end) end = '';

    let contactId = query.contactId;
    if (!contactId) contactId = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      start,
      end,
      contactId,
    };
  }

  fetchData = async () => {
    this.setState({ loading: true });

    const currentFilter =
      this.state.currentFilter === 'all' ? '' : this.state.currentFilter;
    var url = `salesreturns?page=${this.state.currentPage}&filter=${currentFilter}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}&view=${this.state.viewPerPage}&search=${this.state.search}`;
    if (this.state.start && this.state.end) {
      url = `${url}&start=${this.state.start}&end=${this.state.end}`;
    }
    if (this.state.contactId) {
      url = `${url}&contactId=${this.state.contactId}`;
    }
    await restRequest('get', url)
      .then((res) => {
        let obj = {
          prevId: null,
          id: res.data[0] && res.data[0].id,
          nextId: res.data[1] && res.data[1].id,
          moduleName: 'salesreturns',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.moduleName !== 'salesreturns') {
          sessionStorage.setItem('fullscreen', true);
          sessionStorage.setItem('once', true);
          sessionStorage.setItem('fullscreenFlag', false);
          removeValueFromLS('module');
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res.data[0]) {
          removeValueFromLS('module');
        }
        if (!dataFromLS || dataFromLS.action === 'add') {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (dataFromLS.action === 'bulkdelete') {
          if (res.data[0]) {
            setObjInLS('module', { ...obj, lolo: 'no' });
          }
        } else if (
          dataFromLS &&
          dataFromLS.moduleName === 'salesreturns' &&
          (dataFromLS.queryParam !== window.location.search || localStorage.getItem('org_switch') === 'true')
        ) {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (dataFromLS.action === 'delete') {
          setObjInLS('module', obj);
        }
        if (!res.data?.length) {
          sessionStorage.setItem('fullscreen', true);
          setObjInLS('module', obj);
        }
        this.setState({
          loading: false,
          data: this.prepareDataForTableCollapsed(res.data),
          dataExpanded: this.prepareDataForTable(res.data),
          totalPage: res.last_page,
          totalRecords: res.total,
        });

        this.setState(
          {
            data: this.prepareDataForTableCollapsed(res.data),
            dataExpanded: this.prepareDataForTable(res.data),
            totalPage: res.last_page,
            totalRecords: res.total,
          },
          () => {
            this.setState({ mainLoading: false });
          }
        );
        if (this.state.contactId) {
          this.setState({ contactName: res.data[0].display_name });
        }
      })
      .catch((error) => {
        checkError(error);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  };

  prepareDataForTable(data) {
    return data.map((salesreturn) => ({
      id: salesreturn.id,
      Date: {
        id: 'sales_return_date',
        sortable: true,
        getValue: function () {
          return salesreturn[this.id];
        },
      },
      'Sales Return': {
        id: 'sales_return_no',
        sortable: true,
        getValue: function () {
          return salesreturn[this.id];
        },
      },
      'Sales Order': {
        id: 'sales_order_no',
        sortable: true,
        getValue: function () {
          return salesreturn[this.id];
        },
      },
      Customer: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return salesreturn[this.id];
        },
      },
      'Add To Stock': {
        DropshipIcon,
        id: 'add_back_stock',
        sortable: true,
        getValue: function () {
          if (salesreturn[this.id] === 1) {
            return 'Yes';
          }
          return 'No';
        },
      },
      Status: {
        id: 'sales_return_status',
        sortable: true,
        hideStatusColor: true,
        getValue: function () {
          return (
            <span
              className={getStatusColor(
                salesreturn[this.id] === 'receiving'
                  ? 'partially received'
                  : salesreturn[this.id] === 'closed'
                    ? 'received'
                    : salesreturn[this.id]
              )}
            >
              {' '}
              {salesreturn[this.id] === 'receiving'
                ? 'PRTL Received'
                : salesreturn[this.id] === 'closed'
                  ? 'Received'
                  : salesreturn[this.id]}
            </span>
          );
        },
      },
      // 'Status': {
      //   id: 'sales_return_status',
      //   sortable: true,
      //   hideStatusColor: true,
      //   getValue: function () {
      //     const Icons = []
      //     const tooltips = []
      //     if (['dropshipped', 'partially dropshipped'].includes(salesreturn.dropship_status)) {
      //       Icons.push(DropshipIcon)
      //       tooltips.push('Drop shipment')
      //     }
      //     if (['backordered', 'partially backordered'].includes(salesreturn.backorder_status)) {
      //       Icons.push(BackorderIcon)
      //       tooltips.push('Back order')
      //     }
      //     // Added for clone indication
      //     if (salesreturn.clone_status === 1) {
      //       Icons.push(CloneIcon)
      //       tooltips.push('Clone order')
      //     }
      //     return(
      //       <HorizontalColumnJoin
      //         labelClass={getStatusColor(salesreturn[this.id])}
      //         label={salesreturn[this.id]}
      //         Icons={Icons}
      //         tooltips={tooltips}
      //       />
      //     )
      //   }
      // },
      /*'Return Quantity': {
        id: 'return_quantity',
        sortable: true,
        getValue: function () { return salesreturn[this.id] }
      },*/
      'Return Items': {
        id: 'return_items',
        sortable: true,
        getValue: function () {
          return salesreturn[this.id];
        },
      },
      // not displayable
      additional: {
        status: salesreturn.sales_return_status,
        backorderStatus: salesreturn.backorder_status,
        dropshipStatus: salesreturn.dropship_status,
        invoiceStatus: salesreturn.invoice_status,
        packageStatus: salesreturn.package_status,
        cloneStatus: salesreturn.clone_status,
      },
    }));
  }

  prepareDataForTableCollapsed(data) {
    return data.map((salesreturn) => ({
      id: salesreturn.id,
      Date: {
        id: 'sales_return_date',
        sortable: true,
        getValue: function () {
          return salesreturn[this.id];
        },
      },
      // 'Return No.': {
      //   id: 'sales_return_no',
      //   sortable: true,
      //   getValue: function () { return salesreturn[this.id] }
      // },
      Customer: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return salesreturn[this.id];
        },
      },
      // 'Add To Stock': {DropshipIcon,
      //   id: 'add_back_stock',
      //   sortable: true,
      //   getValue: function () {
      //     if(salesreturn[this.id] === 1){
      //       return "Yes";
      //     }
      //     return "No";
      //   }
      // },
      Status: {
        id: 'sales_return_status',
        sortable: true,
        hideStatusColor: true,
        getValue: function () {
          return (
            <span
              className={getStatusColor(
                salesreturn[this.id] === 'receiving'
                  ? 'partially received'
                  : salesreturn[this.id] === 'closed'
                    ? 'received'
                    : salesreturn[this.id]
              )}
            >
              {' '}
              {salesreturn[this.id] === 'receiving'
                ? 'PRTL Received'
                : salesreturn[this.id] === 'closed'
                  ? 'Received'
                  : salesreturn[this.id]}
            </span>
          );
        },
      },
      /*'Return Qty': {
        id: 'sales_return_details',
        // sortable: true,
        getValue: function () {
          let quantity = 0
          salesreturn[this.id].map((value)=>{
            quantity = quantity + value.quantity
          })
          return quantity
        }
      },*/
      'Return Items': {
        id: 'return_items',
        sortable: true,
        getValue: function () {
          return salesreturn[this.id];
        },
      },
      // not displayable
      additional: {
        status: salesreturn.sales_return_status,
        backorderStatus: salesreturn.backorder_status,
        dropshipStatus: salesreturn.dropship_status,
        invoiceStatus: salesreturn.invoice_status,
        packageStatus: salesreturn.package_status,
        cloneStatus: salesreturn.clone_status,
      },
    }));
  }

  close = () => {
    this.setState({
      showPDF: false,
      pdf: null,
    });
  };

  openPdf(id) {
    this.setState({ showPDF: true });
    restRequest('get', `salesreturns/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  }

  openExportModal() {
    this.setState({ showExportModal: true });
  }

  closeExportModal = () => {
    this.setState({ showExportModal: false });
  };

  renderExportModal() {
    return (
      <CustomModal
        showModal={this.state.showExportModal}
        title="Export Sales Return"
        Icon={ExportIcon}
        onClose={this.closeExportModal}
        renderActions={false}
      >
        <Export
          name="Sales Return"
          onCancel={this.closeExportModal}
          submitURL="salesreturns/export"
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    );
  }

  markAsConfirmed = (id) => {
    this.setState({ processing: true });
    restRequest('put', `salesreturns/${id}/confirmed`)
      .then(() => {
        this.setState({ processing: false });
        this.props.handleToast(
          'Sales return has been marked as confirmed.',
          'success'
        );
        this.updateCheckedState();
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
      });
  };

  bulkMarkAsConfirmed(ids) {
    this.setState({ processing: true });
    restRequest('put', 'salesreturns/confirmed', {
      ids: ids,
    })
      .then((res) => {
        this.setState({ processing: false });
        this.props.handleToast(res.message, 'success');
        this.updateCheckedState();
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ processing: false });
        this.props.handleToast(error, 'error');
      });
  }

  // check status whether its active or not
  checkStatus = (status, id = false) => {
    const currentId = id ? id : this.state.bulkSelectedData[0];
    return (
      this.state.data.find((one) => {
        return one.id === currentId;
      }).additional.status === status
    );
  };

  markAsVoid = () => {
    restRequest('put', `salesreturns/${this.state.markAsVoidId}/void`)
      .then(() => {
        this.props.handleToast(
          'Sales return has been marked as void.',
          'success'
        );
        this.fetchData();
        this.updateCheckedState();
        this.closeMarkAsVoidModal();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
        this.closeMarkAsVoidModal();
      });
  };

  openMarkAsVoidModal = (id) => {
    this.setState({
      markAsVoidModal: true,
      markAsVoidId: id,
    });
  };

  closeMarkAsVoidModal = () => {
    this.setState({
      markAsVoidModal: false,
    });
  };

  renderMarkAsVoidModal() {
    return (
      <CustomModal
        showModal={this.state.markAsVoidModal}
        title="Confirm to Mark as Void"
        onClose={this.closeMarkAsVoidModal}
        onSubmit={() => this.markAsVoid()}
      >
        Are you sure you want to mark this sales return as void?
      </CustomModal>
    );
  }

  hidePDFModal = () => {
    this.setState({
      showPDF: false,
    });
  };
  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'salesreturns',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);
    this.setState({ salesreturnId: id });
    // setValueInLS('id',id)
  }
  renderBulkDropshipBackorderModal = () => {
    return (
      <CustomModal
        size="large"
        showModal={this.state.dropshipBackorderModal}
        title={`${this.state.dropshipBackorderType === 'dropship'
            ? 'Dropship'
            : 'Backorder'
          }`}
        renderActions={false}
        onClose={this.closeDropshipBackorderModal}
      ></CustomModal>
    );
  };
  populateConditionalOptions(id, isVoid, isConfirmed) {
    const hasEditPermission = this.hasPermission('salesreturns', 'Edit');
    const options = [];

    if (!hasEditPermission) return options;
    if (!isConfirmed) {
      options.push({
        label: 'Mark as Confirmed',
        onClick: () => this.markAsConfirmed(id),
        disabled: (id) =>
          this.checkStatus('confirmed', id) || this.checkStatus('void', id),
      });
    }
    /*if(!isVoid) {
      options.push({
        label: 'Mark as Void',
        onClick: () => this.openMarkAsVoidModal(id)
      })
    }*/
    return options;
  }

  renderSingleActionMenu = () => {
    var isConfirmed;
    this.state.data.forEach((status) => {
      if (status.additional.status !== 'confirmed') {
        isConfirmed = false;
      }
    });
    const isVoid = this.checkStatus('void');
    const checkedId = this.state.bulkSelectedData[0];
    const options = this.populateConditionalOptions(
      checkedId,
      isVoid,
      isConfirmed
    );
    const [hasEditPermission, hasDeletePermission] = this.hasPermission(
      'salesreturns',
      'All'
    ); // hasCreatePermission,
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <ButtonLink
          enable={
            !isVoid && hasEditPermission && this.checkStatus('draft', checkedId)
          }
          to={`/salesreturns/edit/${checkedId}`}
          type="edit"
        />
        <Button handleClick={() => this.openPdf(checkedId)} type="pdf" />
        <Button handleClick={() => this.print(checkedId)} type="print" />
        <Button
          enable={
            hasDeletePermission &&
            (this.checkStatus('draft', checkedId) ||
              this.checkStatus('confirmed', checkedId))
          }
          handleClick={() => this.openDeleteModal(checkedId)}
          type="delete"
        />
        <Button handleClick={() => this.exportCurrentView()} type="export" />
        <Options enable={!!options.length} options={options} />
      </CheckedMenu>
    );
  };

  populateConditionalOptionsBulkSelect() {
    this.hasPermission('salesreturns', 'Edit');
    const options = [];
    let confirmFlag = true;
    this.state.data.forEach(function (status) {
      if (
        status.additional.status !== 'confirmed' ||
        status.additional.status === 'void' ||
        status.additional.status === 'receiving'
      ) {
        confirmFlag = false;
      }
    });
    // if(!hasEditPermission){
    //   options.push({
    //     label: 'Export Selected',
    //       onClick: this.exportCurrentView(),
    //       disabled: () => !this.hasViewPermission('salesreturns')
    //   })
    //   return options
    // }
    options.push(
      {
        label: 'Mark as Confirmed',
        onClick: () => this.bulkMarkAsConfirmed(this.state.bulkSelectedData),
        disabled: () => confirmFlag,
      },
      {
        label: 'Export Selected',
        onClick: () => this.exportCurrentView(),
        disabled: () => !this.hasViewPermission('salesreturns'),
      }
    );
    return options;
  }
  renderMultipleActionMenu = () => {
    const [, , hasDeletePermission] = this.hasPermission('salesreturns', 'All');
    const options = this.populateConditionalOptionsBulkSelect();
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={this.openBulkDeleteModal}
          type="delete"
        />
        <Options enable={!!options.length} options={options} />
      </CheckedMenu>
    );
  };

  exportCurrentView = () => {
    restRequest('post', `salesreturns/exportSelected`, {
      ids: this.state.bulkSelectedData,
    })
      .then((res) => {
        let csvFile = 'data:text/csv;charset=utf-8,' + res;
        let encodedUri = encodeURI(csvFile);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `SalesReturn.csv`);
        document.body.appendChild(link);
        link.click();
        this.props.handleToast(`Sales Return exported successfully`, 'success');
        this.setState({ bulkSelectedData: [] });
      })
      .catch((error) => {
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
      });
  };

  print(id) {
    this.setState({ loading: true });
    restRequest('get', `salesreturns/${id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 100);

        this.setState({ loading: false });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error,'error')
        this.setState({ loading: false });
      });
  }

  isOrderEditable(id) {
    return false;
  }

  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Mark as Confirmed',
        icon: MarkAsConfirmedIcon,
        onClick: (id) => this.markAsConfirmed(id),
        disabled: (id) =>
          this.checkStatus('receiving', id) ||
          this.checkStatus('confirmed', id) ||
          this.checkStatus('void', id) ||
          this.checkStatus('closed', id),
      },
      {
        label: 'Edit',
        icon: EditIcon,
        onClick: (id) => this.props.history.push(`salesreturns/edit/${id}`),
        disabled: (id) =>
          this.checkStatus('receiving', id) ||
          this.isOrderEditable(id) ||
          this.checkStatus('void', id) ||
          this.checkStatus('confirmed', id) ||
          this.checkStatus('closed', id),
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: (id) => this.openDeleteModal(id),
        disabled: (id) =>
          this.checkStatus('receiving', id) ||
          this.checkStatus('closed', id) ||
          this.checkStatus('void', id),
      },
      /*{
        label: 'Mark as Void',
        icon: MarkAsVoidIcon,
        onClick: id => this.openMarkAsVoidModal(id),
        disabled: id =>  this.checkStatus('receiving', id) || this.checkStatus('void', id) || this.checkStatus('draft', id) || this.checkStatus('closed', id)
      },*/
      {
        label: 'View PDF',
        icon: PdfIcon,
        onClick: (id) => this.openPdf(id),
        // disabled: id => this.checkStatus('void', id) || this.checkStatus('closed', id)
      },
      {
        label: 'Email',
        type: 'link',
        icon: EmailIcon,
        onClick: (id) => `/salesreturns/email/${id}`,

        // disabled: id => !this.checkActiveStatus(id),
      },
    ];

    const permissions = this.hasPermission('salesreturns', 'All');

    //const allowedRowOptions = GenerateOptions(permissions, rowOptions, ['Mark as Confirmed', 'Mark as Void', 'Dropship', 'Backorder'])
    const allowedRowOptions = GenerateOptions(permissions, rowOptions, [
      'Mark as Confirmed',
      'Mark as Void',
    ]);

    return allowedRowOptions;
  }

  renderTable() {
    const {
      data,
      bulkSelectedData,
      sortFilter,
      dataExpanded,
      orderBy,
      //loading,
      mainLoading,
    } = this.state;
    if (!mainLoading) {
      if (this.checkIfOnboarding()) {
        return (
          // <Onboarding
          //   name="Sales Return"
          //   Image={SalesOrderImage}
          //   FlowDiagram={SalesOrderFlowDiagram}
          //   title="Manage Return Activity"
          //   description="Create, customize and manage your sales returns"
          //   //buttonLabel="Create Sales Return"
          //   //buttonLink="/salesreturns/add"
          // />

          <Onboarding
            name="Sales Return"
            Image={SalesOrderImage}
            FlowDiagram={SalesOrderFlowDiagram}
            videoFrame={
              <iframe
                width="700"
                height="400"
                src="https://www.youtube.com/embed/TSi9nZJIAl0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            }
            DisplayIcon={NewSalesReturnIcon}
            headerTitle="Sales Return"
            title="Manage Return Activity"
            description="Create, customize and manage your sales returns"
          //buttonLabel="Create Sales Return"
          //buttonLink="/salesreturns/add"
          />
        );
      }
    }
    let fullscreen = getStringValueFromLS('fullscreen');
    let fullscreenFlag = getStringValueFromLS('fullscreenFlag');
    if (fullscreenFlag === 'true') {
      sessionStorage.setItem('fullscreen', false);
      sessionStorage.setItem('fullscreenFlag', false);
    }
    return (
      <ListTable
        className={
          fullscreen === 'true'
            ? 'fullscreen sale_return_listing'
            : 'compressedscreen expanded_view_sale_return expanded_view_creditnote'
        }
        data={fullscreen === 'true' ? dataExpanded : data}
        selectedData={bulkSelectedData}
        rowOptions={this.prepareRowOptions()}
        linkTo="/salesreturns"
        preferenceLink="/preference/SaleReturnPolicyPreference"
        preference="Sale Return Preferences"
        sortFilter={sortFilter}
        orderBy={orderBy}
        loading={mainLoading}
        generateRoute={this.generateRoute}
        updateCheckedState={this.updateCheckedState}
        handleCheckAll={this.handleCheckAll}
        handleCheckSingle={this.handleCheckSingle}
        getID={this.getID}
        moduleName="salesreturns"
        totalRecords={this.state.totalRecords}
      />
    );
  }

  onClickHandler = () => {
    this.setState({ contactId: '', contactName: '' });
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS && dataFromLS.moduleName === 'salesreturns') {
      setObjInLS('module', {
        ...dataFromLS,
        queryParam: '?filter=all&sort=created_at&page=1&orderBy=desc&view=20',
      });
      this.props.history.push('/r');
    } else {
      this.props.history.push(routesPath.salesreturns);
    }
  };

  renderSearchDetails() {
    const { search, contactId, contactName } = this.state;
    if (search) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Search Criteria"
          linkTo={routesPath.salesreturns}
        >
          <SearchResult
            criterias={[
              'Sale Return No',
              'Sale Order No',
              'Customer Name',
              'Return Reason',
            ]}
            searchKey={search.replace('%26', '&')}
          />
        </Alert>
      );
    }
    if (contactId) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Sales Returns"
          linkTo={routesPath.salesreturns}
          crossIcon={false}
        //onClickHandler={this.onClickHandler}
        >
          Displaying all results from contact "{contactName}"
        </Alert>
      );
    }
    return null;
  }

  render() {
    return (
      <Fragment>
        {this.renderMarkAsVoidModal()}
        {this.renderExportModal()}
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.close}
          pdf={this.state.pdf}
        />
        {this.renderTable()}
      </Fragment>
    );
  }
}

export default HeaderWrapper(
  SalesReturn,
  {
    name: 'Sales Return',
    deleteName: 'Sales Return',
    Icon: SalesReturnIcon,
    baseUrl: 'salesreturns',
    tableIcon: 'salereturn_general_module_icon',
    redirectUrl: '/salesreturns',
    //buttonUrl: '/salesreturns/add',
    //buttonLabel: 'New Sales Return',
    showNewButton: false,
    routeName: 'salesreturns',
    showDetailsComp: true,
    bypassLoadingState: false,
    permissionName: 'salesreturns',
  },
  SaleReturnDetails,
  AddSalesReturn,
  EditSalesReturn
);
