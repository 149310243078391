import React, { Component } from 'react';
import moment from 'moment';

import { restRequest } from '../../Helpers/RequestHelper';
import { getOrganizationDate } from '../../Helpers/Date/OrganizationDate';

import ReceiveFormEnhancer from '../../Components/Form/ReceiveForm/ReceiveFormEnhancer';
import Loader from '../../Components/Loader/Loader';

import './CreateReceive.css';
import { checkError } from '../../Helpers/AuthHelper';

class CreateReceive extends Component {
  purchaseOrderId = this.props.purchaseOrderId;

  state = {
    loading: true,
    receive: {
      receiveNo: '',
      date: null,
      purchase_order_date: null,
      type: 'unbilled',
      unbilledItems: [],
      billedItems: [],
    },
    allUnbilledItems: [],
    allBilledItems: [],
    vendor: '',
  };

  emptyUnbilledItem = {
    id: null,
    purchaseOrderItemDetailId: null,
    image: '',
    name: '',
    sku: '',
    unit: '',
    cancelledQuantity: 0,
    orderedQuantity: 0,
    receivedQuantity: 0,
    quantityToReceive: 0,
  };

  emptyBilledItem = {
    billId: null,
    billNo: null,
    items: [
      {
        id: null,
        image: '',
        name: '',
        sku: '',
        unit: '',
        quantity: null,
      },
    ],
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchData().then(() => this.setState({ loading: false }));
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  fetchData = async () => {
    this.setState({ loading: true });

    await this.fetchReceives(this.purchaseOrderId);

    this.setState({ loading: false });
  };

  fetchReceives = async (purchaseOrderId) => {
    await restRequest(
      'get',
      `purchaseorders/${purchaseOrderId}/receives/create`
    )
      .then((res) => {
        const unbilledItems = res.items_receivable_without_billed.map(
          (unbilledItem) => ({
            id: unbilledItem.item_id,
            purchaseOrderItemDetailId:
              unbilledItem.purchase_order_item_detail_id,
            image: unbilledItem.item_images[0],
            name: unbilledItem.item_name,
            sku: unbilledItem.item_sku,
            unit: unbilledItem.item_unit,
            cancelledQuantity: unbilledItem.cancelled_quantity,
            orderedQuantity: unbilledItem.quantity_ordered,
            receivedQuantity: unbilledItem.received_quantity,
            quantityToReceive: unbilledItem.quantity_receivable_without_billed,
          })
        );
        const billedItems = res.receivable_bills.map((billedItem) => ({
          billId: billedItem.bill_id,
          billNo: billedItem.bill.bill_no,
          items: billedItem.bill.bill_item_details.map((item) => ({
            id: item.item_id,
            image: item.item.images[0],
            name: item.item.name,
            sku: item.item.sku,
            unit: item.item.unit,
            quantity: item.quantity,
          })),
        }));
        this.setState((state) => ({
          receive: {
            ...state.receive,
            date:
              res?.purchase_order_date &&
              moment(res?.purchase_order_date) > moment(getOrganizationDate())
                ? res?.purchase_order_date
                : moment(getOrganizationDate()).format('YYYY-MM-DD 00:00:00'),
            purchase_order_date: res?.purchase_order_date,
            receiveNo: res.receive_no,
            unbilledItems:
              unbilledItems.length > 0
                ? [...unbilledItems]
                : [this.emptyUnbilledItem],
            billedItems:
              billedItems.length > 0
                ? [...billedItems]
                : [this.emptyBilledItem],
          },
          // allUnbilledItems: unbilledItems,
          allUnbilledItems: [],
          allBilledItems: [],
        }));
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
        if (this.props.inModal) {
          this.props.onClose();
        }
      });
  };

  renderReceiveForm() {
    const { receive, vendor, allUnbilledItems, allBilledItems } = this.state;
    const {
      onClose,
      onSubmit,
      handleToast,
      handleprompt = () => null,
    } = this.props;
    return (
      <ReceiveFormEnhancer
        receive={{ ...receive, vendor }}
        allBilledItems={allBilledItems}
        allUnbilledItems={allUnbilledItems}
        emptyBilledItem={this.emptyBilledItem}
        emptyUnbilledItem={this.emptyUnbilledItem}
        onClose={onClose}
        onSubmit={onSubmit}
        handleToast={handleToast}
        purchaseOrderId={this.purchaseOrderId}
        handleprompt={handleprompt}
      />
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.renderReceiveForm();
  }
}

export default CreateReceive;
