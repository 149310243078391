import React from 'react';

import Table from '../../../../Components/Table/Table';
import { GridLayout } from '../../../../Components/Layout/Layout';
import ListTableImageColumn from '../../../../Components/ListTable/ListTableImageColumn';
import Quantity from '../../../../Components/Fragments/Quantity/Quantity';
import './CreditNoteInfo.css';

const PackageItemInfo = ({
  itemList = [],
  totalCredits,
  utilizedCredits,
  refundCredits,
  totalDeductions,
  currency,
  creditNoteDetails,
  isSaleOrder,
  inModal,
  className,
}) => {
  const tableHeaders = ['Item', 'Qty.', 'Deduction Policy', 'Deduction'];
  const tableData = ['itemName', 'qty', 'adjustment', 'adjustment_amount'];
  const rightAlignColumns = [4];
  const packedItems = itemList?.map((packedItem, index) => {
    return {
      itemName: (
        <ListTableImageColumn
          images={
            isSaleOrder ? packedItem.items.images : packedItem.items.images
          }
          name={isSaleOrder ? packedItem.items.name : packedItem.items.name}
          id={isSaleOrder ? packedItem.items.id : packedItem.items.id}
          showModalUponClick={inModal ? false : true}
          sku={isSaleOrder ? packedItem.items.sku : packedItem.items.sku}
        />
      ),
      qty: (
        <Quantity
          quantity={
            creditNoteDetails[index] !== undefined
              ? creditNoteDetails[index].quantity_processed
              : 0
          }
          unit={
            creditNoteDetails[index] !== undefined
              ? creditNoteDetails[index].items.unit
              : 0
          }
        />
      ),
      adjustment: `${
        creditNoteDetails[index].adjustement_criteria === 'amount'
          ? currency.symbol
          : ''
      }${
        creditNoteDetails[index] &&
        parseFloat(creditNoteDetails[index].adjustment).toFixed(2)
      }${
        creditNoteDetails[index].adjustement_criteria === 'percent' ? '%' : ''
      }`,
      adjustment_amount:
        currency.symbol +
        `${
          creditNoteDetails[index] &&
          parseFloat(creditNoteDetails[index].adjustment_amount).toFixed(2)
        }`,
    };
  });

  return (
    <div
      className="package-item-info credit_sales_return_table"
      style={{ marginTop: '2rem' }}
    >
      {itemList.length > 0 && (
        <Table
          list={packedItems}
          tableHeaders={tableHeaders}
          tableData={tableData}
          rightAlignColumns={rightAlignColumns}
          className={`details-table ${className}`}
        />
      )}
      <div />

      <GridLayout
        className="custom_paddddding adjust_grid_gap credit-note-summary"
        grid="2-2"
      >
        <div className="section-details--footer-amount">
          <div className="border-bottom border-top">
            <span className="semi-bold">Total Deduction </span>
            <span className="semi-bold padddding_right">{`${
              currency.symbol
            }${parseFloat(totalDeductions).toFixed(2)}`}</span>
          </div>
          <div>
            <span>Total Credits </span>
            <span className="padddding_right">
              {' '}
              {currency.symbol}
              {parseFloat(totalCredits).toFixed(2)}{' '}
            </span>
          </div>
          {utilizedCredits > 0 && (
            <div>
              <span>Paid On Invoice(s) </span>
              <span
                className="padddding_right"
                style={{ color: 'red' }}
              >{`(-) ${parseFloat(utilizedCredits).toFixed(2)}`}</span>
            </div>
          )}
          {refundCredits > 0 && (
            <div>
              <span>Refund </span>
              <span className="padddding_right" style={{ color: 'red' }}>
                {' '}
                {`(-) ${parseFloat(refundCredits).toFixed(2)}`}
              </span>
            </div>
          )}
          {totalCredits - (refundCredits + utilizedCredits) !==
            totalCredits && (
            <div className="border-top">
              <span className="semi-bold">Remaining Credits </span>
              <span className="semi-bold padddding_right">
                {currency.symbol}
                {parseFloat(
                  totalCredits - (refundCredits + utilizedCredits)
                ).toFixed(2)}
              </span>
            </div>
          )}
        </div>
      </GridLayout>
    </div>
  );
};

export default PackageItemInfo;
