import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import moment from 'moment';
import { getOrganizationDate, restRequest } from '../../../Helpers';
import {
  getInvoiceCreateData,
  getInvoiceEditData,
  getTermsList,
} from '../../../Api/Invoice/Invoice';
import { emptyItem } from '../../../Api/Invoice/Utils';
import { Loader, NotFound, PlanExceeded } from '../../';
import InvoiceFormEnhancer from './InvoiceFormEnhancer';
import { addDays } from 'date-fns';
import { ranges } from '../../CustomDatePicker/StaticRanges';
import './InvoiceForm.css';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';

class InvoiceForm extends Component {
  type = this.props.type; // "add" or "edit" form
  salesOrderId = this.props.salesOrderId || null;

  id =
    this.props.idFromHeaderWrapper ||
    this.props.id ||
    this.props.match.params.id ||
    null;
  contactId = this.getContactId();
  inModal = this.props.inModal || null;
  showAddItemButton = this.props.inModal ? false : true;

  state = {
    invoice: {
      invoiceNo: '',
      invoiceId: null,
      orderNo: '',
      invoiceDate: null,
      dueDate: null,
      invoiceTerm: {
        name: '',
        value: null,
      },
      items: [],
      overallTotal: 0,
      shippingCharge: 0,
      adjustment: 0,
      transactionDiscount: 0,
      transactionDiscountType: 'percent',
      discountLevel: null,
      customerId: null,
      invoiceObj: {},
      note: '',
      status: '',
      salesPersonId: null,
      hasTermsAndConditions: window.location.pathname.includes('add')
        ? true
        : this.props.inModal
        ? true
        : false,
      termsAndConditions: '',
    },
    itemsList: [],
    salesOrderList: [],
    invoiceTermsList: [],
    warehousesList: [],
    customersList: [],
    confirmedSalesOrdersList: [],
    taxList: [],
    currency: {
      currency_code: '',
      name: '',
      symbol: '',
    },
    discountPreference: {},
    disableDate: true,
    planExceeded: null,
    showTermsModal: false,
    validation: null,
    loading: true,
    itemLevelIndvidual: true,
    notFound: false,
    accessDenied: false,
    salesOrderId: this.props.salesOrderId || null,
    showAddItemButton: this.props.inModal ? false : true,
    previousPurchasePrices: [],
    salesPersonsFromDB: [],
    is_sales_person: false,
    cofirmState: false,
    showPrompt: false,
    invoiceItemsLength: [],
    confirmOnEdit: false,
    owner_can_edit_in_lock_status: false,
  };

  componentDidMount() {
    this.fetchData();
    sessionStorage.setItem('once', false);
    sessionStorage.setItem('fullscreen', false);
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  getContactId() {
    let param = window.location.search.split('=');
    //console.log('id', id);
    if (param && param[0] === '?contactId') {
      return parseInt(param[1]);
    }
    return null;
  }

  async fetchData() {
    //console.log(this.type)
    switch (this.type) {
      case 'edit':
        this.fetchInvoiceEditData();
        break;
      case 'add':
      default:
        this.fetchInvoiceCreateData(this.salesOrderId);
    }
  }

  async getPreviousSellingPrice(customerId, item) {
    // return item
    if (!customerId) {
      return item;
    } else {
      let updatedItem = [];
      let PPURL = 'invoice/previoussoldprice';
      const payload = {
        customer_id: customerId,
        item_id: item,
        object: 'invoice',
      };

      await restRequest('post', PPURL, payload).then((previousPrice) => {
        // updatedItem = {
        //   ...updatedItem,
        //   previousSellingPrice: previousPrice,
        // };
        updatedItem = previousPrice;
      });
      return updatedItem;
    }
  }

  async fetchInvoiceEditData() {
    const { close, handleToast } = this.props;
    try {
      const {
        invoice,
        taxList,
        customersList,
        warehousesList,
        itemsList,
        // disableDate,
        invoiceTermsList,
        confirmedSalesOrdersList,
        discountPreference,
        itemLevelIndvidual,
        currency,
        salesPersonsFromDB,
        is_sales_person,
        error,
        invoiceItemsLength,
        owner_can_edit_in_lock_status,
      } = await getInvoiceEditData(this.id);
      if (typeof error?.status !== 'undefined' && error?.status === 403) {
        this.setState({
          accessDenied: true,
          loading: false,
        });
        return;
      }
      if (typeof error?.status !== 'undefined' && error?.status === 422) {
        handleToast(error?.data?.message, 'error');
        return this.props.history.push('/invoices');
      }
      let promises = [];
      invoice.items.forEach((item) => {
        let PPURL = null;
        if (invoice.invoiceId !== null) {
          PPURL = `invoice/previoussoldprice?customer_id=${invoice.customerId}&item_id=${item.id}&object=invoice&object_id=${invoice.invoiceId}`;
        } else {
          PPURL = `invoice/previoussoldprice?customer_id=${invoice.customerId}&item_id=${item.id}`;
        }
        promises.push(restRequest('get', PPURL));
      });

      Promise.all(promises).then((promisRes) => {
        this.setState({ previousPurchasePrices: promisRes });
      });
      const checkDirectInvoice = invoice.items.some(
        (item) => item.salesOrderItemDetailId !== null
      );
      var disabledDateEdit = true;
      if (invoice.invoiceTerm.name === 'Custom') {
        disabledDateEdit = false;
      }
      this.setState({
        invoice: { ...this.state.invoice, ...invoice },
        taxList,
        customersList,
        warehousesList,
        itemsList: checkDirectInvoice ? invoice.items : itemsList,
        disableDate: disabledDateEdit,
        checkDirectInvoice,
        invoiceTermsList,
        confirmedSalesOrdersList,
        discountPreference,
        itemLevelIndvidual,
        currency,
        salesPersonsFromDB,
        is_sales_person,
        invoiceItemsLength,
        owner_can_edit_in_lock_status: (invoice.status === 'draft') ? false :
          owner_can_edit_in_lock_status
          ? owner_can_edit_in_lock_status
          : false,
      });
    } catch (error) {
      if (this.inModal) {
        close();
      } else if (error.response && error.response.status === 403) {
        this.setState({
          accessDenied: true,
          loading: false,
        });
      } else if (error.response && error.response.data.status === false) {
        this.setState({
          // notFound: true ,
          planExceeded: error.response && error.response.data.status,
          loading: false,
        });
      } else {
        this.setState({
          notFound: true,
          loading: false,
        });
      }
    }
    this.setState({ loading: false });
  }

  async fetchInvoiceCreateData(salesOrderId) {
    const { close, handleToast } = this.props;
    const {
      invoice,
      itemsList,
      invoiceTermsList,
      discountPreference,
      warehousesList,
      customersList,
      taxList,
      customerId,
      currency,
      salesPersonsFromDB,
      is_sales_person,
      error,
      invoiceItemsLength,
    } = await getInvoiceCreateData(salesOrderId, this.inModal, this.contactId);
    try {
      if (invoice === null) {
        this.setState({
          planExceeded: error.response && error.response.data.status,
          loading: false,
        });
        handleToast(error.response.data.message, 'error');
      }

      let item_ids = [];
      item_ids = invoice.items
        .map((item) => item.id && item.id)
        .filter(Boolean);
      let responce;
      let newItems = [];

      let promises = [];
      if (invoice !== null) {
        let PPURL = null;
        if (invoice.invoiceId !== null) {
          PPURL = `invoice/previoussoldprice?customer_id=${customerId}&item_id=${item_ids}&object=invoice&object_id=${invoice.invoiceId}`;
        } else {
          if (item_ids.length) {
            responce = await this.getPreviousSellingPrice(customerId, item_ids);
          }
        }
        if (responce) {
          invoice.items.map((item) => {
            let previousRate = responce.find((o) => o.item_id === item.id);
            newItems.push({
              ...item,
              previousSellingPrice: previousRate ? previousRate.rate : 0,
            });
          });
        }

        //   // sessionStorage.removeItem('previousSellingPrice')
        if (this.inModal) invoice.customerId = customersList[0].id;
        this.setState({
          invoice: { ...this.state.invoice, ...invoice },
          // itemsList : !salesOrderId ? itemsList : [],
          itemsList: !salesOrderId ? itemsList : newItems,
          checkDirectInvoice: salesOrderId ? true : false,
          invoiceTermsList,
          discountPreference,
          warehousesList,
          customersList,
          taxList,
          currency,
          salesPersonsFromDB,
          is_sales_person,
          invoiceItemsLength,
        });
      }
      if (this.contactId) {
        const customerInvoiceTerms =
          this.state.customersList.find(
            (customer) => parseInt(this.contactId) === parseInt(customer.id)
          ) || {};
        if (customerInvoiceTerms) {
          this.setState({
            invoice: {
              ...this.state.invoice,
              invoiceObj: {
                label: customerInvoiceTerms.display_name,
                value: customerInvoiceTerms.id,
              },
            },
          });
        }
        if (customerInvoiceTerms && customerInvoiceTerms.invoice_terms) {
          let invoiceTerm = {
            name: customerInvoiceTerms.invoice_terms.name
              ? customerInvoiceTerms.invoice_terms.name
              : '',
            value: customerInvoiceTerms.invoice_terms.value
              ? customerInvoiceTerms.invoice_terms.value
              : null,
          };
          if (invoiceTerm.name === 'Custom') {
            let disableDate = false;
            this.setState({
              invoice: { ...this.state.invoice, invoiceTerm },
              disableDate,
            });
          } else {
            let dueDate = this.handleDate(invoiceTerm);
            this.setState({
              invoice: {
                ...this.state.invoice,
                invoiceTerm,
                dueDate,
              },
            });
          }
        }
      }
      if (this.inModal && invoice.customerId) {
        const customerInvoiceTerms =
          this.state.customersList.find(
            (customer) => parseInt(invoice.customerId) === parseInt(customer.id)
          ) || {};
        if (customerInvoiceTerms && customerInvoiceTerms.invoice_terms) {
          let invoiceTerm = {
            name: customerInvoiceTerms.invoice_terms.name
              ? customerInvoiceTerms.invoice_terms.name
              : '',
            value: customerInvoiceTerms.invoice_terms.value
              ? customerInvoiceTerms.invoice_terms.value
              : null,
          };
          if (invoiceTerm.name === 'Custom') {
            let disableDate = false;
            this.setState({
              invoice: { ...this.state.invoice, invoiceTerm },
              disableDate,
            });
          } else {
            let dueDate = this.handleDate(invoiceTerm);
            this.setState({
              invoice: {
                ...this.state.invoice,
                invoiceTerm,
                dueDate,
              },
            });
          }
        }
      }
    } catch (error) {
      if (this.inModal && error.response.data.status) {
        close();
      } else if (error.response && error.response.data.status === false) {
        this.setState({
          planExceeded: error.response && error.response.data.status,
          loading: false,
        });
      } else {
        this.setState({
          notFound: true,
          loading: false,
        });
      }
      handleToast(error, 'error');
    }

    this.setState({ loading: false });
  }

  handleDate(option) {
    let date = this.state.invoice.invoiceDate;
    if (option && option.value) {
      let value = option.value;
      switch (value) {
        case 'Custom':
          break;
        case 'this month':
          date = moment(ranges().endOfMonth);
          break;
        case 'next month':
          date = moment(ranges().endOfNextMonth);
          break;
        case 'receipt':
          date = moment(ranges().endOfToday);
          break;
        default:
          date = moment(addDays(getOrganizationDate(), value));
      }
      return date;
    } else {
      return moment(getOrganizationDate());
    }
  }

  submitRequestType = () => {
    switch (this.type) {
      case 'edit':
        return 'put';
      case 'add':
      default:
        return 'post';
    }
  };

  submitRequestUrl = () => {
    switch (this.type) {
      case 'edit':
        return `invoices/${this.id}/update`;
      case 'add':
      default:
        return 'invoices';
    }
  };

  successMessage = () => {
    if (this.type === 'add') {
      this.props.handleToast('Invoice created successfully', 'success');
    } else if (this.type === 'edit') {
      this.props.handleToast('Invoice has been updated', 'success');
    }
  };

  fetchTermsList = () => {
    getTermsList(this.id, this.type).then((invoiceTermsList) => {
      this.setState({ invoiceTermsList });
    });
  };

  handleTotalCheckTrue = (check) => {
    this.setState({
      cofirmState: check,
    });
  };
  handleReasonOnEdit = (check) => {
    this.setState({
      confirmOnEdit: check,
    });
  };

  handleTotalCheckFalse = (check) => {
    this.setState({
      cofirmState: check,
    });
  };

  handlePrompt = (status = true) => this.setState({ showPrompt: status });

  renderInvoiceForm() {
    const {
      invoice,
      customersList,
      warehousesList,
      itemsList,
      invoiceTermsList,
      confirmedSalesOrdersList,
      salesPersonsFromDB,
      is_sales_person,
      taxList,
      disableDate,
      discountPreference,
      currency,
      itemLevelIndvidual,
      hasTermsAndConditions,
      salesOrderId,
      showAddItemButton,
      checkDirectInvoice,
      previousPurchasePrices,
      cofirmState,
      confirmOnEdit,
      invoiceItemsLength,
      owner_can_edit_in_lock_status,
    } = this.state;
    const { title, close, onSubmit, handleToast, history } = this.props;
    return (
      <>
        <Prompt
          when={this.state.showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        <div>
          <InvoiceFormEnhancer
            title={title}
            type={this.type}
            invoice={invoice}
            inModal={this.inModal}
            fetchTermsList={this.fetchTermsList}
            disableDate={disableDate}
            // previousPurchasePrices={previousPurchasePrices}
            hasTermsAndConditions={hasTermsAndConditions}
            itemsList={itemsList}
            contactIdTransaction={this.contactId}
            invoiceItemsLength={invoiceItemsLength?.length}
            emptyItem={emptyItem}
            discountPreference={discountPreference}
            itemLevelIndvidual={itemLevelIndvidual}
            currency={currency}
            invoiceTermsList={invoiceTermsList}
            id={this.id}
            taxList={taxList}
            customersList={customersList}
            warehousesList={warehousesList}
            salesPersonsFromDB={salesPersonsFromDB}
            is_sales_person={is_sales_person}
            confirmedSalesOrdersList={confirmedSalesOrdersList}
            submitRequestType={this.submitRequestType}
            submitRequestUrl={this.submitRequestUrl}
            successMessage={this.successMessage}
            close={close}
            onSubmit={onSubmit}
            history={history}
            handleToast={handleToast}
            salesOrderId={salesOrderId}
            showAddItemButton={showAddItemButton}
            checkDirectInvoice={checkDirectInvoice}
            cofirmState={cofirmState}
            confirmOnEdit={confirmOnEdit}
            handleReasonOnEdit={this.handleReasonOnEdit}
            handleTotalCheckTrue={this.handleTotalCheckTrue}
            handleTotalCheckFalse={this.handleTotalCheckFalse}
            setPrompt={this.handlePrompt}
            handlePrompt={this.props.handlePrompt}
            owner_can_edit_in_lock_status={owner_can_edit_in_lock_status}
          />
        </div>
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    if (this.state.accessDenied) return <AccessDenied type="section" />;
    if (this.state.planExceeded === false) return <PlanExceeded />;
    return this.renderInvoiceForm();
  }
}

export default withRouter(InvoiceForm);
