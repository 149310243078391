import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import { NewInvoiceIcon } from '../../../Assets/Navigation/NavigationIcons';
import { restRequest } from '../../../Helpers/RequestHelper';
import GenerateOptions from '../../../Helpers/ListHelper/GenerateOptions';
import { routesPath } from '../../Navigation/NavigationUtils';
// import { formatNumber } from '../../../Helpers/FormatNumber';
// import InvoiceImage from '../../../Components/Onboarding/Diagrams/InvoiceImage';
import InvoiceFlowDiagram from '../../../Components/Onboarding/Diagrams/InvoiceFlowDiagram';
import Alert from '../../../Components/Alert/Alert';
import PDFViewer from '../../../Components/PDFViewer/PDFViewer';
import HeaderWrapper from '../../../Components/HeaderWrapper/HeaderWrapper';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import ListTable from '../../../Components/ListTable/ListTable';
import CheckedMenu, {
  Button,
  ButtonLink,
  Options,
} from '../../../Components/CheckedMenu/CheckedMenu';
import SearchResult from '../../../Components/SearchBar/SearchResult';
// import Onboarding from '../../../Components/Onboarding/Onboarding'
import Onboarding from '../../../Components/Onboarding/InitialOnboardingScreen';
import {
  removeValueFromLS,
  getObjFromLS,
  setObjInLS,
  getStringValueFromLS,
} from '../../../Helpers/LocalStorage';
import InvoiceDetails from '../../../Pages/SalesOrder/Invoices/InvoiceDetails';
import AddInvoice from '../../../Pages/SalesOrder/Invoices/AddInvoice';
import EditInvoice from '../../../Pages/SalesOrder/Invoices/EditInvoice';
import Export from '../../../Components/Export/Export';
import ExportIcon from '../../../Assets/General/ExportIcon';
import EditIcon from '../../../Assets/General/EditIcon';
import PdfIcon from '../../../Assets/General/PdfIcon';
import EmailIcon from '../../../Assets/General/EmailIcon';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import MarkAsSentIcon from '../../../Assets/General/MarkAsSentIcon';
// import MarkAsVoidIcon from "../../../Assets/General/MarkAsVoidIcon";

// import EmptyTable from '../../../Components/ListTable/EmptyTable'

import { checkError } from '../../../Helpers/AuthHelper';

class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceId: null,
      loading: true,
      data: [],
      dataExpanded: [],
      allData: [],
      showPDF: false,
      pdf: null,
      totalPage: 1,
      currentPage: 1,
      viewPerPage: 20,
      totalRecords: 0,
      store_connection: {},
      owner_can_edit_in_lock_status: false,
      currentFilter: 'all',
      sortFilter: 'created_at',
      orderBy: 'desc',
      markAsVoidModal: false,
      showExportModal: false,
      markAsVoidId: '',
      search: '',
      start: '',
      end: '',
      contactId: '',
      contactName: null,
    };
    this.getID = this.getID.bind(this);
  }

  filterOptions = [
    {
      id: 'none',
      label: 'Status',
      type: 'tagline',
    },
    {
      id: 'all',
      label: 'All',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'all', page: 1 }),
    },
    {
      id: 'draft',
      label: 'Draft',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'draft', page: 1 }),
    },
    {
      id: 'sent',
      label: 'Sent',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'sent', page: 1 }),
    },
    {
      id: 'partially paid',
      label: 'PRTL Paid',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'partially paid', page: 1 }),
    },
    {
      id: 'paid',
      label: 'Paid',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'paid', page: 1 }),
    },
    {
      id: 'overdue',
      label: 'Overdue',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'overdue', page: 1 }),
    } /*,{
      id: 'void',
      label: "Void",
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'void', page: 1 })
    }*/,
    {
      id: 'market_place',
      label: 'Market Place',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'market_place', page: 1 }),
    },
    {
      id: 'woocom',
      label: 'Woo-Commerce',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'woocom', page: 1 }),
    },
    {
      id: 'shopify',
      label: 'Shopify',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'shopify', page: 1 }),
    },
  ];

  sortOptions = [
    {
      id: 'created_at',
      type: 'link',
      label: 'Created Time',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('created_at') }),
    },
    {
      id: 'updated_at',
      type: 'link',
      label: 'Last Modified Time',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('updated_at') }),
    },
    {
      id: 'invoice_date',
      type: 'link',
      label: 'Date',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('invoice_date'),
        }),
    },
    {
      id: 'invoice_no',
      type: 'link',
      label: 'Invoice No',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('invoice_no') }),
    },
    {
      id: 'display_name',
      type: 'link',
      label: 'Customer Name',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('display_name'),
        }),
    },
    {
      id: 'order_no',
      type: 'link',
      label: 'Sales Order No',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('order_no') }),
    },
    {
      id: 'due_date',
      type: 'link',
      label: 'Due Date',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('due_date') }),
    },
    {
      id: 'status',
      type: 'link',
      label: 'Status',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('status') }),
    },
    /*{
      id: 'total',
      type: 'link',
      label: 'Amount',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('total') })
    },
    {
      id: 'payment_due',
      type: 'link',
      label: 'Balance Due',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('payment_due') })
    }*/
  ];

  importExportOptions = [
    {
      label: 'Export Invoices',
      icon: <ExportIcon />,
      onClick: () => this.openExportModal(),
      disabled: () => !this.hasViewPermission('invoice'),
    },
  ];

  componentDidMount() {
    document.title = 'Invoices';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  handleOnUpdateQueryString() {
    const {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      contactId = '',
    } = this.parseQueryString();
    if (filter !== this.state.currentFilter) {
      this.setState({
        start: '',
        end: '',
      });
      // for (var i = 0; i < this.filterOptions.length; i++) {
      //   if (this.filterOptions[i].id === 'due') {
      //     this.filterOptions.splice(i, 1);
      //     i--; // decrement index if item is removed
      //   }
      // }
      this.setState(
        {
          currentFilter: filter,
        },
        () => this.fetchData()
      );
    }
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: parseInt(page),
          bulkSelectedData: [],
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.setState(
        {
          viewPerPage: parseInt(view),
          bulkSelectedData: [],
        },
        () => this.fetchData()
      );
    }
    if (search !== this.state.search) {
      this.setState({ search: search, bulkSelectedData: [] }, () =>
        this.fetchData()
      );
    }
    if (contactId !== this.state.contactId) {
      this.setState(
        {
          contactId: contactId,
        },
        () => this.fetchData()
      );
    }
  }

  fetchDataWithQueryString() {
    const { filter, page, sort, orderBy, view, search, start, end, contactId } =
      this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
        search: search,
        start: start,
        end: end,
        contactId: contactId,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    switch (query.filter) {
      case 'all':
      case 'draft':
      case 'sent':
      case 'partially paid':
      case 'paid':
      case 'overdue':
      case 'void':
      case 'due':
      case 'market_place':
      case 'woocom':
      case 'shopify':
        filter = query.filter;
        break;
      default:
        filter = 'all';
    }

    let sort = '';
    switch (query.sort) {
      case 'invoice_date':
      case 'invoice_no':
      case 'due_date':
      case 'display_name':
      case 'status':
      case 'created_at':
      case 'updated_at':
      case 'order_no':
      case 'total':
      case 'payment_due':
        sort = query.sort;
        break;
      default:
        sort = 'created_at';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'desc';
    }

    let page = parseInt(query.page);
    if (!page) page = 1;
    let view = parseInt(query.view);
    if (!view) view = 20;

    let search = query.search;
    if (!search) search = '';

    let start = query.start;
    if (!start) start = '';

    let end = query.end;
    if (!end) end = '';

    let contactId = query.contactId;
    if (!contactId) contactId = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      start,
      end,
      contactId,
    };
  }

  MarketFilterOptions() {
    const { wocom, shopify } = this.state.store_connection;
    let array;
    if (wocom && !shopify) {
      array = this.filterOptions.filter((option) => {
        return option.id !== 'shopify' && option.id !== 'market_place';
      });
    } else if (!wocom && shopify) {
      array = this.filterOptions.filter((option) => {
        return option.id !== 'woocom' && option.id !== 'market_place';
      });
    } else if (!wocom && !shopify) {
      array = this.filterOptions.filter((option) => {
        return (
          option.id !== 'woocom' &&
          option.id !== 'shopify' &&
          option.id !== 'market_place'
        );
      });
    } else {
      return;
    }

    this.filterOptions = array;
  }

  fetchData = () => {
    this.setState({ loading: true });

    const currentFilter =
      this.state.currentFilter === 'all' ? '' : this.state.currentFilter;
    var url = `invoices?filter=${currentFilter}&page=${this.state.currentPage}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}&view=${this.state.viewPerPage}&search=${this.state.search}`;
    if (this.state.start && this.state.end) {
      url = `${url}&start=${this.state.start}&end=${this.state.end}`;

      if (currentFilter === 'due') {
        this.filterOptions.push({
          id: 'due',
          type: 'link',
          label: 'Due Invoices',
          onClick: () =>
            this.generateRoute({
              filter: 'due',
              page: 1,
            }),
        });
      }
    }
    if (this.state.contactId) {
      url = `${url}&contactId=${this.state.contactId}`;
    }
    restRequest('get', url)
      .then((result) => {
        let res = result.invoices;
        let obj = {
          prevId: null,
          id: res.data[0] && res.data[0].id,
          nextId: res.data[1] && res.data[1].id,
          moduleName: 'invoices',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.update) {
          sessionStorage.setItem('fullscreen', true);
          sessionStorage.setItem('once', true);
          setObjInLS('module', obj);
        } else if (!dataFromLS) {
          // when redirect from dashboard to new tab, page shows loader unlimited time
          sessionStorage.setItem('fullscreen', true);
          sessionStorage.setItem('once', true);
        }
        if (dataFromLS && dataFromLS.moduleName !== 'invoices') {
          sessionStorage.setItem('fullscreen', true);
          removeValueFromLS('module');
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res.data[0]) {
          removeValueFromLS('module');
        }
        if (!dataFromLS || dataFromLS.action === 'add') {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (dataFromLS.action === 'bulkdelete') {
          if (res.data[0]) {
            setObjInLS('module', { ...obj, lolo: 'no' });
          }
        } else if (
          dataFromLS &&
          dataFromLS.moduleName === 'invoices' &&
          (dataFromLS.queryParam !== window.location.search || localStorage.getItem('org_switch') === 'true')
        ) {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
          localStorage.removeItem('org_switch');
        }
        if (!res.data?.length) {
          sessionStorage.setItem('fullscreen', true);
          setObjInLS('module', obj);
        }
        this.setState(
          {
            loading: false,
            data: this.prepareDataForTableCollapsed(res.data),
            dataExpanded: this.prepareDataForTable(res.data),
            totalPage: res.last_page,
            totalRecords: res.total,
            allData: res?.data || [],
            store_connection: result.store_connection,
            owner_can_edit_in_lock_status:result.owner_can_edit_in_lock_status
          },
          () => {
            this.MarketFilterOptions();
            this.setState({
              mainLoading: false,
            });
          }
        );
        if (this.state.contactId) {
          this.setState({
            contactName: res.data[0]?.display_name,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        checkError(error);
        this.props.handleToast(error, 'error');
      });
  };

  prepareDataForTable(data) {
    return data.map((invoice) => ({
      id: invoice.id,
      Date: {
        id: 'invoice_date',
        sortable: true,
        getValue: function () {
          return invoice[this.id];
        },
      },
      'Invoice No.': {
        id: 'invoice_no',
        sortable: true,
        getValue: function () {
          return invoice[this.id];
        },
      },
      Customer: {
        id: 'display_name',
        sortable: true,
        market_place: invoice?.order_association,
        getValue: function () {
          return invoice.display_name;
        },
      },
      'Sale Order': {
        id: 'order_no',
        sortable: true,
        getValue: function () {
          return invoice.order_no;
        },
      },
      'Due Date': {
        id: 'due_date',
        sortable: true,
        getValue: function () {
          return invoice[this.id];
        },
      },
      Status: {
        id: 'status',
        sortable: true,
        getValue: function () {
          return invoice[this.id] === 'partially paid'
            ? 'PRTL Paid'
            : invoice[this.id];
        },
      },
      Amount: {
        id: 'total',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${invoice.currency.symbol}${parseFloat(
            invoice[this.id]
          ).toFixed(2)}`;
        },
      },
      'Balance Due': {
        id: 'payment_due',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${invoice.currency.symbol}${parseFloat(
            invoice[this.id]
          ).toFixed(2)}`;
        },
      },
      // not displayable
      additional: {
        status: invoice.status,
        originalStatus: invoice.original_status,
        total: invoice.total,
        //hasCreditNote: invoice?.credit_notes?.length,
      },
    }));
  }
  prepareDataForTableCollapsed(data) {
    return data.map((invoice) => ({
      id: invoice.id,
      Date: {
        id: 'invoice_date',
        sortable: true,
        getValue: function () {
          return invoice[this.id];
        },
      },
      // 'Invoice No.': {
      //   id: 'invoice_no',
      //   sortable: true,
      //   getValue: function () { return invoice[this.id] }
      // },
      Customer: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return invoice.display_name;
        },
      },
      /*'Order No.': {
        id: 'order_no',
        // sortable: true,
        getValue: function () { return invoice.order_no }
      },*/
      // 'Invoice Date': {
      //   id: 'invoice_date',
      //   sortable: true,
      //   getValue: function () { return invoice[this.id] }
      // },
      // 'Due Date': {
      //   id: 'due_date',
      //   sortable: true,
      //   getValue: function () { return invoice[this.id] }
      // },
      Status: {
        id: 'status',
        sortable: true,
        getValue: function () {
          return invoice[this.id] === 'partially paid'
            ? 'PRTL Paid'
            : invoice[this.id];
        },
      },
      /*'Amount': {
        id: 'total',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${invoice.currency.symbol}${formatNumber(invoice[this.id])}`
        }
      },
      'Balance Due': {
        id: 'payment_due',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${invoice.currency.symbol}${formatNumber(invoice[this.id])}`
        }
      },*/
      // not displayable
      additional: {
        status: invoice.status,
        originalStatus: invoice.original_status,
        total: invoice.total,
        //hasCreditNote: invoice?.credit_notes?.length,
      },
    }));
  }

  openPdf = (id) => {
    this.setState({ showPDF: true });
    restRequest('get', `invoices/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error, this.props.handleToast));
  };

  hidePDFModal = () => {
    this.setState({
      showPDF: false,
      pdf: null,
    });
  };

  isInvoiceEditable(id) {
    let invoiceInfo = this.state.data.find((one) => {
      return one.id === id;
    }).additional;
    if(this.state.owner_can_edit_in_lock_status === true){
      return true
    }else{
      return !(
        invoiceInfo.originalStatus === 'void' ||
        invoiceInfo.originalStatus === 'overdue' ||
        invoiceInfo.originalStatus === 'sent' ||
        (invoiceInfo.originalStatus === 'paid' && !invoiceInfo.total !== 0) ||
        invoiceInfo.originalStatus === 'partially paid'
      );
    }
  }

  checkInvoiceStatus = (status, id = false) => {
    const currentId = id ? id : this.state.bulkSelectedData[0];
    return (
      this.state.data.find((one) => {
        return one.id === currentId;
      }).additional.originalStatus === status
    );
  };
  // this is used for disable mark as void option in invoice listing for single row or bulk select
  // if invoice has credit note
  // currently void option is not exists in our system so we do not need this 30-09-2022
  /*checkCreditNote = (id = false) => {
    const currentId = id ? id : this.state.bulkSelectedData[0];
    return (
      this.state.data.find((one) => {
        return one.id === currentId;
      }).additional.hasCreditNote === 1
    );
  };*/

  markAsSent = (id) => {
    this.setState({ processing: true });
    restRequest('put', `invoices/${id}/sent`)
      .then((res) => {
        this.setState({ processing: false });
        this.props.handleToast('Invoice has been marked as sent.', 'success');
        this.fetchData();
        this.updateCheckedState();
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
      });
  };

  bulkMarkAsSent(ids) {
    this.setState({ processing: true });
    restRequest('put', 'invoices/sent', {
      ids: ids,
    })
      .then((res) => {
        this.setState({ processing: false });
        this.props.handleToast(res.message, 'success');
        this.fetchData();
        this.updateCheckedState();
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
      });
  }

  bulkMarkAsVoid(ids) {
    restRequest('put', 'invoices/void', {
      ids: ids,
    })
      .then((res) => {
        this.props.handleToast(res.message, 'success');
        this.fetchData();
        this.updateCheckedState();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  }

  markAsVoid = (id) => {
    restRequest('put', `invoices/${id}/void`)
      .then((res) => {
        this.props.handleToast('Invoice has been marked as void.', 'success');
        this.fetchData();
        this.updateCheckedState();
        this.closeMarkAsVoidModal();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  };

  openMarkAsVoidModal = (id) => {
    this.setState({
      markAsVoidModal: true,
      markAsVoidId: id,
    });
  };

  closeMarkAsVoidModal = () => {
    this.setState({
      markAsVoidModal: false,
    });
  };

  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'invoices',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);
    this.setState({ invoiceId: id });
    // setValueInLS('id',id)
  }

  renderMarkAsVoidModal() {
    return (
      <CustomModal
        showModal={this.state.markAsVoidModal}
        title="Confirm to Mark as Void"
        onClose={this.closeMarkAsVoidModal}
        onSubmit={() => this.markAsVoid(this.state.markAsVoidId)}
      >
        Are you sure you want to mark this invoice as Void?
      </CustomModal>
    );
  }

  populateConditionalOptions(id) {
    const hasEditPermission = this.hasPermission('invoice', 'Edit');
    const options = [];

    if (!hasEditPermission) return options;

    if (this.checkInvoiceStatus('draft')) {
      options.push({
        label: 'Mark as Sent',
        onClick: () => this.markAsSent(id),
      });
    }
    /*if (this.checkInvoiceStatus('draft') || this.checkInvoiceStatus('sent') ) {
      if(!this.checkCreditNote(id)){
        options.push({
          label: 'Mark as Void',
          onClick: () => this.openMarkAsVoidModal(id)
        })
      }
    }*/

    return options;
  }

  openExportModal() {
    this.setState({ showExportModal: true });
  }

  closeExportModal = () => {
    this.setState({ showExportModal: false });
  };

  renderExportModal() {
    return (
      <CustomModal
        showModal={this.state.showExportModal}
        title="Export Invoices"
        Icon={ExportIcon}
        onClose={this.closeExportModal}
        renderActions={false}
      >
        <Export
          name="Invoices"
          onCancel={this.closeExportModal}
          submitURL="invoices/export"
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    );
  }

  isOrderCreateable() {
    const [hasCreatePermission] = this.hasPermission('invoice', 'All');
    return !hasCreatePermission;
  }

  // render action menu for single selected data
  renderSingleActionMenu = () => {
    const [hasCreatePermission, hasEditPermission, hasDeletePermission] =
      this.hasPermission('invoice', 'All');
    const checkedId = this.state.bulkSelectedData[0];
    const options = this.populateConditionalOptions(checkedId);
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <ButtonLink
          enable={this.isInvoiceEditable(checkedId) && hasEditPermission}
          to={`/invoices/edit/${checkedId}`}
          type="edit"
        />
        <Button
          handleClick={() => this.openPdf(checkedId, '/pdf/invoice/')}
          type="pdf"
        />
        <Button handleClick={() => this.print(checkedId)} type="print" />
        <ButtonLink
          enable={hasCreatePermission}
          to={`/invoices/email/${checkedId}`}
          type="email"
        />
        <Button
          enable={
            hasDeletePermission && !this.checkInvoiceStatus('paid', checkedId)
          }
          handleClick={() => this.openDeleteModal(checkedId)}
          type="delete"
        />

        <Button handleClick={() => this.exportCurrentView()} type="export" />

        <Options enable={!!options.length} options={options} />
      </CheckedMenu>
    );
  };

  print(id) {
    this.setState({ loading: true });
    restRequest('get', `invoices/${id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 100);

        this.setState({ loading: false });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error,'error')
        this.setState({ loading: false });
      });
  }

  // render action menu for multiple selected data
  renderMultipleActionMenu = () => {
    const [, hasEditPermission, hasDeletePermission] = this.hasPermission(
      'invoice',
      'All'
    );
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={this.openBulkDeleteModal}
          type="delete"
        />
        <Options
          // enable={hasEditPermission}
          options={[
            {
              label: 'Mark as Sent',
              onClick: () => this.bulkMarkAsSent(this.state.bulkSelectedData),
              disabled: () => !hasEditPermission,
            },
            /*{
              label: 'Mark as Void',
              onClick: () => this.bulkMarkAsVoid(this.state.bulkSelectedData),
              disabled: () => !hasEditPermission
            },*/
            {
              label: 'Export Selected',
              onClick: () => this.exportCurrentView(),
              disabled: () => !this.hasViewPermission('invoice'),
            },
          ]}
        />
      </CheckedMenu>
    );
  };

  isSyncOrderExist = (id) =>
    this.state.allData?.some((order) => {
      /*if(order.id === id){
        console.log('------------------------',order.id, ' ---- ', order.invoice_no, ' ---- ',order?.order_association?.length ? true : false);
      }*/
      return order.id === id && order?.order_association?.length ? true : false;
    });

  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Edit',
        icon: EditIcon,
        onClick: (id) => this.props.history.push(`/invoices/edit/${id}`),
        disabled: (id) =>
          !this.isInvoiceEditable(id) || this.isSyncOrderExist(id),
      },
      {
        label: 'Email',
        // type: 'link',
        icon: EmailIcon,
        // onClick: id => `/invoices/email/${id}`,
        onClick: (id) => this.props.history.push(`/invoices/email/${id}`),
        disabled: () => this.isOrderCreateable(),
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: (id) => this.openDeleteModal(id),
        disabled: (id) =>
          this.checkInvoiceStatus('paid', id) ||
          this.checkInvoiceStatus('void', id) ||
          this.checkInvoiceStatus('partially paid', id) ||
          this.checkInvoiceStatus('sent', id) ||
          this.isSyncOrderExist(id),
      },
      {
        label: 'View PDF',
        icon: PdfIcon,
        onClick: (id) => this.openPdf(id),
      },
      {
        label: 'Mark as Sent',
        icon: MarkAsSentIcon,
        onClick: (id) => this.markAsSent(id),
        disabled: (id) => !this.checkInvoiceStatus('draft', id),
      } /*,
      {
        label: 'Mark as Void',
        icon:MarkAsVoidIcon,
        onClick: id => this.openMarkAsVoidModal(id),
        disabled: id => !(this.checkInvoiceStatus('draft', id) || this.checkInvoiceStatus('sent', id)) || this.checkCreditNote(id)
      }*/,
    ];

    const permissions = this.hasPermission('invoice', 'All');

    const allowedRowOptions = GenerateOptions(permissions, rowOptions, [
      'Mark as Sent',
      'Mark as Void',
    ]);
    return allowedRowOptions;
  }

  exportCurrentView = () => {
    restRequest('post', `invoices/exportCurrentView`, {
      ids: this.state.bulkSelectedData,
    })
      .then((res) => {
        let csvFile = 'data:text/csv;charset=utf-8,' + res;
        let encodedUri = encodeURI(csvFile);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `Invoices.csv`);
        document.body.appendChild(link);
        link.click();
        this.props.handleToast(`Invoices exported successfully`, 'success');
        this.setState({ bulkSelectedData: [] });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
      });
  };

  renderModals() {
    return (
      <Fragment>
        {this.renderMarkAsVoidModal()}
        {this.renderExportModal()}
        <PDFViewer
          showPDF={this.state.showPDF}
          pdf={this.state.pdf}
          hidePDFModal={this.hidePDFModal}
        />
      </Fragment>
    );
  }

  renderTable() {
    const {
      data,
      bulkSelectedData,
      dataExpanded,
      sortFilter,
      orderBy,
      //loading,
      mainLoading,
    } = this.state;
    if (!mainLoading) {
      if (this.checkIfOnboarding()) {
        return (
          // <Onboarding
          //   name="Invoice"
          //   Image={InvoiceImage}
          //   FlowDiagram={InvoiceFlowDiagram}
          //   title="Manage Invoice  Activity"
          //   description="Create invoice & get paid easily"
          //   buttonLabel="Create Invoice"
          //   buttonLink="/invoices/add"
          // />
          <Onboarding
            FlowDiagram={InvoiceFlowDiagram}
            name="Invoice"
            videoFrame={
              <iframe
                width="700"
                height="400"
                src="https://www.youtube.com/embed/VanjBMN14IE"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            }
            DisplayIcon={NewInvoiceIcon}
            headerTitle="Invoices"
            title="Manage Invoice  Activity"
            description="Create invoice & get paid easily"
            buttonLabel="Create Invoice"
            buttonLink="/invoices/add"
          />
        );
      }
    }
    let fullscreen = getStringValueFromLS('fullscreen');
    let fullscreenFlag = getStringValueFromLS('fullscreenFlag');
    if (fullscreenFlag === 'true') {
      sessionStorage.setItem('fullscreen', false);
      sessionStorage.setItem('fullscreenFlag', false);
    }
    return (
      <Fragment>
        {this.renderModals()}
        <ListTable
          className={
            fullscreen === 'true'
              ? 'invoice_listing UIUX_listing_amount invoice-listing-fullscreen'
              : 'expanded_view_invoice'
          }
          data={fullscreen === 'true' ? dataExpanded : data}
          selectedData={bulkSelectedData}
          rowOptions={this.prepareRowOptions()}
          linkTo="/invoices"
          sortFilter={sortFilter}
          orderBy={orderBy}
          loading={mainLoading}
          generateRoute={this.generateRoute}
          handleCheckAll={this.handleCheckAll}
          handleCheckSingle={this.handleCheckSingle}
          getID={this.getID}
          moduleName="invoices"
          totalRecords={this.state.totalRecords}
        />
      </Fragment>
    );
  }

  onClickHandler = () => {
    //console.log('i m here')
    this.setState({ contactId: '', contactName: '' });
    //console.log(this.state.contactId)
    //console.log(getObjFromLS('module'))
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS && dataFromLS.moduleName === 'invoices') {
      setObjInLS('module', {
        ...dataFromLS,
        queryParam: '?filter=all&sort=created_at&page=1&orderBy=desc&view=20',
      });
      this.props.history.push('/r');
    } else {
      this.props.history.push(routesPath.invoices);
    }
    //console.log('after props history.push')
  };

  renderSearchDetails() {
    const { search, contactId, contactName } = this.state;
    if (search) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Search Criteria"
          linkTo={routesPath.invoices}
        >
          <SearchResult
            criterias={['Invoice No.', 'Sales Order No.', 'Customer Name']}
            searchKey={search.replace('%26', '&')}
          />
        </Alert>
      );
    }
    if (contactId) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Invoices"
          linkTo={routesPath.invoices}
          crossIcon={false}
        // onClickHandler={this.onClickHandler}
        >
          Displaying all results from contact "{contactName}"
        </Alert>
      );
    }
    return null;
  }

  render() {
    return (
      <>
        {/* {this.renderSearchDetails()} */}
        {this.renderTable()}
      </>
    );
  }
}

export default HeaderWrapper(
  InvoiceList,
  {
    tableIcon: 'general_module_icon',
    name: 'Invoices',
    deleteName: 'Invoice',
    Icon: NewInvoiceIcon,
    baseUrl: 'invoices',
    redirectUrl: '/invoices',
    buttonUrl: '/invoices/add',
    buttonLabel: 'New Invoice',
    routeName: 'invoices',
    showDetailsComp: true,
    bypassLoadingState: false,
    permissionName: 'invoice',
  },
  InvoiceDetails,
  AddInvoice,
  EditInvoice
);
