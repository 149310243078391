import React, { Component, Fragment } from 'react';

import { SalesOrderIcon } from '../../../Assets/Navigation/NavigationIcons';
import { NewPackageIcon } from '../../../Assets/Navigation/NavigationIcons';
import InvoiceOutlineIcon from '../../../Assets/General/InvoiceOutlineIcon';
import PackageOutlineIcon from '../../../Assets/General/PackageOutlineIcon';
import ShipManuallyIcon from '../../../Assets/General/ShipManuallyIcon';
import { NewInvoiceIcon } from '../../../Assets/Navigation/NavigationIcons';
import { restRequest } from '../../../Helpers/RequestHelper';
import getOverallTaxDetails from '../../../Helpers/GetOverallTaxDetails';
import shouldShowModal from '../../../Helpers/ShouldShowModal';
import getCurrentMenu from '../../../Helpers/getCurrentMenu';

import {
  Loader,
  HeaderWrapper,
  Address,
  PDFViewer,
  Box,
  AccessDenied,
  TermsCondition,
  NotFound,
  CustomModal,
  ActionMenu,
  Menu,
  MenuData,
  Notes,
  HeaderMenus,
} from '../../../Components';

import SalesOrderInfo from './Info/SalesOrderInfo';
import SalesOrderSummary from './Summary/SalesOrderSummary';
import BulkDropshipBackorder from './../BackorderDropship/BulkDropshipBackorder';

import { DetailsHeader } from '../../../Components/Details/Details';

import { controllerNames } from '../../../Constants';
import Packages from './Tabs/PackagesTab';
import SalesReturn from './Tabs/SalesReturn';
import Invoices from './Tabs/InvoicesTab';
import Dropshipments from './Tabs/DropshipmentsTab';
import {
  getObjFromLS,
  setObjInLS,
  checkError,
  pageIdentify,
  pdfDownlaod,
} from '../../../Helpers';
import { SiWordpress } from '../../../Common/Icons';
import SaleReturn from '../../../Assets/General/SalesReutrn';
import History from '../../../Assets/General/History';
import Package from '../../../Assets/General/Packages';
import Invoice from '../../../Assets/General/Invoices';

import { PackageForm, InvoiceForm } from '../../../Components/Form';
import ShipmentCreate from '../../../Pages/SalesOrder/Packages/ShipmentCreate';
import MarkAsConfirmedIcon from '../../../Assets/General/MarkAsConfirmedIcon';
import DropshipIcon from '../../../Assets/General/DropshipIcon';
import BackorderIcon from '../../../Assets/General/BackorderIcon';
import SycnOrderDetails from '../../Items/ItemDetails/SyncOrderDetials';
import useMobileDetection from '../../../Hooks/MobileDetect';
// import useMobileDetection from '../../../Hooks/MobileDetect';

import '../../../Assets/CSS/Details.css';
import './SOdetails.css';

class SalesOrderDetails extends Component {
  id = this.props.id
    ? this.props.id
    : this.props.idFromHeaderWrapper
    ? this.props.idFromHeaderWrapper
    : this.props?.match?.params?.id;

  state = {
    salesOrder: {
      id: this.props.id
        ? this.props.id
        : this.props.idFromHeaderWrapper
        ? this.props.idFromHeaderWrapper
        : this.props?.match?.params?.id,
      salesOrderNo: '',
      customerID: null,
      billingID: null,
      shippingID: null,
      reference: '',
      customer_note: '',
      deliveryMethod: '',
      orderDate: null,
      expectedShipmentDate: null,
      status: '',
      packageStatus: null,
      invoiceStatus: '',
      adjustment: '',
      shippingCharge: null,
      discountLevel: '',
      discountTransactionLevel: null,
      discountType: '',
      total: null,
      items: [],
      notes: '',
      terms: '',
      discount: 0,
      salesPerson: null,
      hide_button: false,
    },
    currentPackageID: null,
    packageList: [],
    shipments: [],
    detailsNo: '',
    customer: {
      id: null,
      name: '',
      company: '',
      addresses: {
        billingAddress: {
          id: null,
          type: '',
          attention: '',
          street: '',
          city: '',
          state: '',
          zip: '',
          countryName: '',
          phone: '',
          fax: '',
        },
        shippingAddress: {
          id: null,
          type: '',
          attention: '',
          street: '',
          city: '',
          state: '',
          zip: '',
          countryName: '',
          phone: '',
          fax: '',
        },
      },
    },
    currency: {},
    pdf: false,
    currentMenu: getCurrentMenu(window.location.search),
    showPDF: false,
    markAsVoidModal: false,
    packageCreateModal: false,
    invoiceCreateModal: false,
    manualShippingModal: false,
    outOfStock: null,
    dropshipBackorderModal: false,
    dropshipBackorderType: '',
    loading: true,
    notFound: false,
    accessDenied: false,
    sync_order: null,
    dropShipStatusState: '',
    backOrderStatusState: '',
    showPrompt: false,
    pathImg: [],
  };

  componentDidMount() {
    if (!this.props.inModal) {
      document.title = 'Sales Order Details';
    }
    this.fetchData();
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }
  fetchData = async () => {
    this.setState({ loading: true });
    let dataFromLS = getObjFromLS('module');
    if (
      dataFromLS &&
      dataFromLS.moduleName === 'salesorders' &&
      dataFromLS.id
    ) {
      this.id = dataFromLS.id;
      if (JSON.parse(sessionStorage.getItem('showPrint'))) {
        let printId = JSON.parse(sessionStorage.getItem('showPrintId'));
        this.openPdf(printId || this.id || dataFromLS.id);
      }
      // sessionStorage.removeItem('showPrint');
    }
    await restRequest('get', `salesorders/${this.id}`)
      .then((res) => {
        // WARNING: fix backend to invoke error if not found
        if (!Object.keys(res).length) {
          this.setState({ notFound: true });
        } else {
          let salesOrderDetails = {
            ...this.state.salesOrder,
            salesOrderNo: res.sales_order_no,
            id: res.id,
            customerID: res.customer_id,
            billingID: res.billing_address_id,
            shippingID: res.shipping_address_id,
            reference: res.reference,
            deliveryMethod: res.delivery_method,
            orderDate: res.order_date,
            expectedShipmentDate: res.expected_shipment_date,
            status: res.status,
            customer_note: res.customer_note,
            packageStatus: res.package_status,
            invoiceStatus: res.invoice_status,
            backorderStatus: res.backorder_status,
            dropshipStatus: res.dropship_status,
            packages_shipment_status: res.get_package_shipment_status,
            adjustment: res.adjustment,
            shippingCharge: res.shipping_charge,
            discountLevel: res.discount_level,
            discountTransactionLevel: res.discount_transaction_level,
            discountType: res.discount_type,
            dropshipments: res.dropshipments,
            back_orders: res.back_orders,
            total: res.total,
            discount: res.discount,
            note: res.customer_note,
            hide_button: res.hide_button,
            terms: res.terms_and_condition,
            salesPerson: res.sales_person ? res.sales_person.name : null,
            items: res.sales_order_details.map((item) => ({
              id: item.item_id,
              images: item.items.images,
              name: item.items.name,
              quantity: item.quantity,
              unitPrice: item.rate,
              discount: item.discount_item_level,
              discountType: item.discount_type,
              unit: item.items.unit,
              sku: item.items.sku,
              taxInfo:
                item.tax_id !== null
                  ? {
                      id: item?.tax?.id,
                      rate: item?.tax?.rate,
                      name: item?.tax?.name,
                    }
                  : null,
              taxAmount: item.tax_amount,
              total: item.amount,
              extra_description: item.extra_description,
              warehouse: item.warehouses,
            })),
          };
          let billingAddress = {};
          let shippingAddress = {};
          if (res.billing_address) {
            billingAddress = {
              id: res.billing_address.id,
              type: res.billing_address.address_type,
              attention: res.billing_address.attention,
              street: res.billing_address.street,
              city: res.billing_address.city,
              state: res.billing_address.state,
              zip: res.billing_address.zip_code,
              countryName: res.billing_address.country_name,
              phone: res.billing_address.phone,
              fax: res.billing_address.fax,
            };
          }
          if (res.shipping_address) {
            shippingAddress = {
              id: res.shipping_address.id,
              type: res.shipping_address.address_type,
              attention: res.shipping_address.attention,
              street: res.shipping_address.street,
              city: res.shipping_address.city,
              state: res.shipping_address.state,
              zip: res.shipping_address.zip_code,
              countryName: res.shipping_address.country_name,
              phone: res.shipping_address.phone,
              fax: res.shipping_address.fax,
            };
          }

          let customer = {
            id: res.contact.id,
            name: res?.display_name
              ? res?.display_name
              : res.contact.display_name,
            company: res.contact.company_name,
            addresses: {
              billingAddress: billingAddress,
              shippingAddress: shippingAddress,
            },
          };
          this.setState({
            shipments: res.shipments,
            salesOrder: salesOrderDetails,
            outOfStock: res.itemStock.out_of_stock,
            detailsNo: salesOrderDetails.salesOrderNo,
            customer,
            sync_order: res?.sync_order || null,
            dropShipStatusState: res.dropship_status,
            backOrderStatusState: res.backorder_status,
            currency: res.base_currency,
          });
          if (
            dataFromLS &&
            dataFromLS.queryParam &&
            dataFromLS.queryParam.includes(this.state.currentMenu)
          ) {
            let query = dataFromLS.queryParam;
            query = query.replace(`&${this.state.currentMenu}=true`, '');
            query = query.replace(`?${this.state.currentMenu}=true`, '');
            setObjInLS('module', {
              ...dataFromLS,
              queryParam: query,
              presistDetailPage: true,
            });

            if (window.history.pushState) {
              let newurl =
                window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname +
                query;
              window.history.pushState({ path: newurl }, '', newurl);
            }
          } else {
            setObjInLS('module', {
              ...dataFromLS,
              presistDetailPage: true,
            });
          }
        }
      })
      .catch((error) => {
        checkError(error);
        if (error.response && error.response.status === 403) {
          this.setState({ accessDenied: true });
        } else {
          this.setState({ notFound: true });
        }
      });

    this.setState({ loading: false });
  };
  updateSalesOrderStatus = () => {
    if (!pageIdentify(window.location.pathname)) {
      this.props.history.push('/r');
    } else this.fetchData();
  };
  hasPermissionCustom(name, type) {
    let permissions = getObjFromLS('role').permissions;
    if (permissions.superAccess) {
      if (type === 'All') return [true, true, true];
      return true;
    }

    if (!['Create', 'Edit', 'Delete', 'All'].includes(type))
      throw new Error('Type must be one of Create, Edit, Delete, All');
    if (!(name in controllerNames))
      throw new Error('Invalid name is receieved for permission check');

    if (type !== 'All') {
      return permissions[`${controllerNames[name]}${type}`];
    }
    return [
      permissions[`${controllerNames[name]}Create`],
      permissions[`${controllerNames[name]}Edit`],
      permissions[`${controllerNames[name]}Delete`],
    ];
  }
  openPdf(id) {
    const [hasMobile] = useMobileDetection();
    if (hasMobile) {
      window.open(`/print/${id}/salesorders`, '_blank');
    } else {
      this.setState({ showPDF: true });
      restRequest('get', `salesorders/${id}/pdf`)
        .then((res) => {
          this.setState({ pdf: res });
        })
        .catch((error) => checkError(error));
    }
  }

  print() {
    this.setState({ loading: true });
    restRequest('get', `salesorders/${this.id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 3000);
        this.setState({ loading: false });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.prhandleToast(error,'error')
        this.setState({ loading: false });
      });
  }

  markAsConfirmed(status, id) {
    this.setState({ processing: true });
    restRequest('put', `salesorders/${id}/confirmed`)
      .then(() => {
        this.setState({ processing: false });
        this.props.handleToast('Sales Order marked as confirmed.', 'success');
        if (pageIdentify(window.location.pathname)) {
          const updatedSalesOrder = {
            ...this.state.salesOrder,
            status: status,
          };
          this.setState({ salesOrder: updatedSalesOrder });
        } else {
          this.props.history.push('/r');
        }
      })
      .catch((error) => {
        /*this.props.handleToast(error, "error")*/
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
      });
  }

  async handleManualShipping(saleOrderId) {
    await restRequest('get', `shipments/create?salesOrderId=${saleOrderId}`)
      .then((res) => {
        this.setState({
          manualShippingModal: true,
          currentPackageID:
            res.packages_list.length > 0 ? res.packages_list[0].id : null,
          packageList: res.packages_list,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(err, 'error')
      });
  }

  createNewInvoice() {
    this.setState({ invoiceCreateModal: true });
  }
  async createNewPackage() {
    const { packageStatus, dropshipStatus, id } = this.state.salesOrder;
    // check for this case: package can be created or not
    if (
      packageStatus === 'partially packed' &&
      dropshipStatus === 'partially dropshipped'
    ) {
      try {
        await restRequest('get', `packages/create?sales_order_id=${id}`);
      } catch (error) {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
        return;
      }
    }
    this.setState({ packageCreateModal: true });
  }

  markAsVoid = () => {
    restRequest('put', `salesorders/${this.id}/void`)
      .then(() => {
        this.props.handleToast('Sales Order marked as void', 'success');
        this.closeAllModals();
        if (pageIdentify(window.location.pathname)) {
          const updatedSalesOrder = {
            ...this.state.salesOrder,
            status: 'Void',
          };
          this.setState({ salesOrder: updatedSalesOrder });
        } else {
          this.props.history.push('/r');
        }
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
        this.closeAllModals();
      });
  };

  renderMarkAsVoidModal() {
    return (
      <CustomModal
        showModal={this.state.markAsVoidModal}
        title="Confirm to Mark as Void"
        onClose={this.closeAllModals}
        onSubmit={this.markAsVoid}
      >
        Are you sure you want to mark this sales order as Void?
      </CustomModal>
    );
  }
  handlePackageActionSubmit = () => {
    this.updateSalesOrderStatus();
    this.closeAllModals();
  };

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  renderPackageCreateModal() {
    return (
      <CustomModal
        showModal={this.state.packageCreateModal}
        Icon={NewPackageIcon}
        title={'Create Package | ' + this.state.salesOrder.salesOrderNo}
        onClose={this.closeAllModals}
        renderActions={false}
        showPrompt={this.state.showPrompt}
        className="so-package-main"
      >
        <PackageForm
          className="so-package-dropdown"
          inModal
          type="add"
          salesOrderId={this.id}
          close={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  getCurrentPackage = (id) => {
    return this.state.packageList.find((obj) => obj.id === id);
  };

  renderManualShippingModal() {
    const currentPackage = this.getCurrentPackage(this.state.currentPackageID);
    // const slipID = currentPackage
    const slipID = currentPackage ? currentPackage.package_no : null;
    return (
      <CustomModal
        className="Shipment_modals_fields ship-modal-res-popup"
        showModal={this.state.manualShippingModal}
        Icon={ShipManuallyIcon}
        title={'Create Shipment | ' + this.state.salesOrder.salesOrderNo}
        renderActions={false}
        onClose={this.closeAllModals}
        showPrompt={this.state.showPrompt}
      >
        <ShipmentCreate
          forAllPackages={true}
          packageId={this.state.currentPackageID}
          packageNo={slipID}
          packageList={this.state.packageList}
          onClose={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
          Componenthistory={this.props.history}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  renderInvoiceCreateModal() {
    const { handleToast } = this.props;
    return (
      <CustomModal
        fill="#000000"
        showModal={this.state.invoiceCreateModal}
        Icon={NewInvoiceIcon}
        title={'Create Invoice | ' + this.state.salesOrder.salesOrderNo}
        renderActions={false}
        onClose={this.closeAllModals}
        size="large"
        className="uiux-so-invoice-popup"
        showPrompt={this.state.showPrompt}
      >
        <InvoiceForm
          inModal
          type="add"
          handleToast={handleToast}
          salesOrderId={this.state.salesOrder.id}
          close={this.closeAllModals}
          onSubmit={this.handleInvoiceActionSubmit}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  openMarkAsVoidModal = () => {
    this.setState({
      markAsVoidModal: true,
    });
  };

  renderDropshipBackorderModal = () => {
    return (
      <CustomModal
        size="large"
        showModal={this.state.dropshipBackorderModal}
        title={`${
          this.state.dropshipBackorderType === 'dropship'
            ? 'Dropship'
            : 'Backorder'
        }`}
        renderActions={false}
        modaltitle={'align_so_modalicons'}
        Icon={
          this.state.dropshipBackorderType === 'dropship'
            ? DropshipIcon
            : BackorderIcon
        }
        onClose={this.closeAllModals}
        showPrompt={this.state.showPrompt}
      >
        <BulkDropshipBackorder
          handleToast={this.props.handleToast}
          closeModal={this.closeAllModals}
          type={this.state.dropshipBackorderType}
          ids={[this.id]}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  };

  toggleDropshipBackorderModal = (type) => {
    this.setState({ processing: true });
    const data = [this.id];
    const requestType = 'get';
    const requestUrl = `salesorders/${type}?ids=${data}`;

    shouldShowModal(requestType, requestUrl)
      .then(() => {
        this.setState({ processing: false });
        let dataFromLS = getObjFromLS('module');
        let { pathname, search } = window.location;
        setObjInLS('module', {
          ...dataFromLS,
          moduleName: 'salesorders',
          back_drop: { id: this.id, url: `${pathname}${search}` },
          queryParam: dataFromLS.queryParam,
        });
        this.setState({
          dropshipBackorderType: type,
          dropshipBackorderModal: true,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ processing: false });
        this.props.handleToast(error, 'error');
      });
  };

  populateConditionalOptions(id, isVoid) {
    const { dropShipStatusState, salesOrder, backOrderStatusState, sync_order } = this.state;
    const hasEditPermission = this.hasPermission('salesorder', 'Edit');
    const options = [];

    if (!hasEditPermission) return options;
    if (
      this.state.salesOrder.status !== 'confirmed' &&
      this.state.salesOrder.status !== 'closed'
    ) {
      options.push({
        label: 'Mark as Confirmed',
        icon: MarkAsConfirmedIcon,
        onClick: () => this.markAsConfirmed('confirmed', id),
        disabled: () => (backOrderStatusState ? true : false),
      });
    }
    if (!isVoid && this.state.salesOrder.status === 'confirmed') {
      options.push({
        label: 'Dropship',
        icon: DropshipIcon,
        tooltipText:(sync_order !== null) ? `Dropship is not available for e-commerce order` : null,
        onClick: () => this.toggleDropshipBackorderModal('dropship'),
        disabled: () =>
          dropShipStatusState === 'dropshipped' ||
          salesOrder.packageStatus === 'packed' ||
          backOrderStatusState === 'backordered' ||
          sync_order !== null
            ? true
            : false,
      });
      options.push({
        label: 'Backorder',
        icon: BackorderIcon,
        tooltipText:(sync_order !== null) ? `Backorder is not available for e-commerce order` : null,
        onClick: () => this.toggleDropshipBackorderModal('backorder'),
        disabled: () =>
          this.state.outOfStock === false
            ? true
            : dropShipStatusState === 'dropshipped' ||
              backOrderStatusState === 'backordered' ||
              sync_order !== null
            ? true
            : false,
      });
    }
    return options;
  }

  isVoidAble(salesOrder) {
    return (
      salesOrder.backorderStatus !== null ||
      salesOrder.dropshipStatus !== null ||
      salesOrder.invoiceStatus !== null ||
      salesOrder.packageStatus !== null
    );
  }

  populateSaleOrderOptions(id) {
    const hasPackageCreatePermission = this.hasPermissionCustom(
      'package',
      'Create'
    );
    const hasInvoiceCreatePermission = this.hasPermissionCustom(
      'invoice',
      'Create'
    );
    const options = [];

    if (hasPackageCreatePermission) {
      if (
        this.isPackageCreatable(
          this.state.salesOrder.packageStatus,
          this.state.salesOrder.status,
          this.state.salesOrder.dropshipStatus,
          this.state.salesOrder.hide_button
        )
      ) {
        options.push({
          label: 'Package',
          icon: PackageOutlineIcon,
          onClick: () => this.createNewPackage(),
        });
      }
    }
    if (hasInvoiceCreatePermission) {
      if (
        this.isInvoiceCreatable(
          this.state.salesOrder.invoiceStatus,
          this.state.salesOrder.status
        )
      ) {
        options.push({
          label: 'Invoice',
          icon: InvoiceOutlineIcon,
          onClick: () => this.createNewInvoice(),
        });
      }
    }
    if (this.state.salesOrder.packages_shipment_status === false) {
      options.push({
        label: 'Shipment',
        icon: ShipManuallyIcon,
        onClick: () => this.handleManualShipping(id),
      });
    }

    return options;
  }

  checkIfEditable = (salesOrder) => {
    if (
      salesOrder.backorderStatus ||
      salesOrder.dropshipStatus ||
      salesOrder.invoiceStatus ||
      salesOrder.packageStatus
    )
      return false;
    return true;
  };

  isPackageCreatable(packageStatus, orderStatus, dropshipStatus, hide_button) {
    if (
      packageStatus === 'packed' ||
      ['onhold', 'void'].includes(orderStatus) ||
      dropshipStatus === 'dropshipped' ||
      hide_button
    )
      return false;

    return true;
  }
  isInvoiceCreatable(invoiceStatus, orderStatus) {
    if (
      invoiceStatus === 'invoiced' ||
      ['onhold', 'void'].includes(orderStatus)
    ) {
      return false;
    } else if (orderStatus === 'draft') {
      return false;
    }

    return true;
  }

  renderActionMenu() {
    const id = this.id;
    const isVoid = this.state.salesOrder.status === 'void';
    const options = this.populateConditionalOptions(id, isVoid);
    const saleOrdersOptions = this.populateSaleOrderOptions(id);
    const [hasCreatePermission, hasEditPermission, hasDeletePermission] =
      this.hasPermission('salesorder', 'All');

    const menusList = [
      {
        type: 'options',
        options: options,
        className: 'options-dropdown',
        enable: options.length,
      },
      {
        type: 'link',
        icon: 'edit',
        enable: this.state.loading
          ? false
          : !isVoid &&
            hasEditPermission &&
            this.checkIfEditable(this.state.salesOrder),
        to: `/salesorders/edit/${id}`,
        tooTip: 'Edit',
        mobileLable: 'Edit',
        isMobile: true,
      },
      {
        type: 'button',
        icon: 'pdf',
        tooTip: 'Download PDF',
        isMobile: true,
        mobileLable: 'Download PDF',
        handleClick: () =>
          pdfDownlaod(
            `salesorders/${id}/pdf?download=true`,
            this.state.detailsNo,
            this.props.handleToast
          ),
      },
      {
        type: 'button',
        icon: 'print',
        isMobile: true,
        mobileLable: 'Print',
        tooTip: 'Print',
        handleClick: () => this.openPdf(id),
      },
      {
        type: 'link',
        icon: 'email',
        tooTip: 'Email',
        isMobile: true,
        mobileLable: 'Email',
        enable: hasCreatePermission,
        to: `/salesorders/email/${id}`,
      },
      {
        type: 'link',
        icon: 'clone',
        tooTip: 'Clone',
        mobileLable: 'Clone',
        isMobile: true,
        enable: hasCreatePermission,
        to: `/salesorders/clone/${id}`,
      },
      {
        type: 'button',
        icon: 'delete',
        tooTip: 'Delete',
        mobileLable: 'Delete',
        isMobile: true,
        handleClick: () =>
          this.openDeleteModal(
            id,
            this.props.forceRedirect,
            this.props.forceRedirectFlag
          ),
        enable: this.state.loading
          ? false
          : !isVoid &&
            hasDeletePermission &&
            this.checkIfEditable(this.state.salesOrder),
      },

      {
        type: 'button',
        className: 'button--secondary package_button float-left mr-3',
        handleClick: () => this.createNewPackage(),
        label: `Package`,
        icon: 'new',
        enable: this.state.loading
          ? false
          : this.hasPermissionCustom('package', 'Create') &&
            this.state.salesOrder.packageStatus == null &&
            this.isPackageCreatable(
              this.state.salesOrder.packageStatus,
              this.state.salesOrder.status,
              this.state.salesOrder.dropshipStatus
            ),
      },
      {
        type: 'dropdown',
        className:
          'custom-dropdown transaction_Dropdwon mr-10 sales_order_dropdwon abcd button-text',
        options: saleOrdersOptions,
        dropdownLable: 'Create',
        enable:
          this.hasPermissionCustom('package', 'Create') &&
          this.state.salesOrder.packageStatus != null &&
          saleOrdersOptions.length > 0,
      },
      {
        type: 'button',
        className: 'button--primary float-left mr-3',
        handleClick: () => this.props.history.push(`/salesorders/add`),
        label: `New`,
        icon: 'new',
      },
    ];
    return (
      <ActionMenu>
        <HeaderMenus menusList={menusList} loading={this.state.loading} />
      </ActionMenu>
    );
  }

  closeAllModals = () => {
    sessionStorage.removeItem('showPrint');
    sessionStorage.removeItem('showPrintId');
    this.setState({
      showPDF: false,
      pdf: null,
      markAsVoidModal: false,
      dropshipBackorderModal: false,
      packageCreateModal: false,
      invoiceCreateModal: false,
      manualShippingModal: false,
    });
  };

  renderMenuForHistory() {
    return (
      <MenuData
        name="History"
        salesOrderID={this.salesOrderID}
        url={this.url}
      />
    );
  }
  renderMenuForPackages() {
    return (
      <MenuData
        name="Packages"
        salesOrderID={this.salesOrderID}
        url={this.url}
        saleOrderNo={this.saleOrderNo}
        salesOrderStatus={this.salesOrderStatus}
        packageStatus={this.packageStatus}
        dropshipStatus={this.dropshipStatus}
        handleToast={this.handleToast}
        inModal={this.inModal}
        updateSalesOrderStatus={this.updateSalesOrderStatus}
        hasPermissionCustom={this.hasPermissionCustom}
        hasViewPermission={this.hasViewPermission}
        Componenthistory={this.history}
        sync_order={this.sync_order}
      />
    );
  }
  renderMenuInvoices() {
    return (
      <MenuData
        name="Invoices"
        url={this.url}
        salesOrderID={this.salesOrderID}
        salesOrderStatus={this.salesOrderStatus}
        invoiceStatus={this.invoiceStatus}
        saleOrderNo={this.saleOrderNo}
        handleToast={this.handleToast}
        updateSalesOrderStatus={this.updateSalesOrderStatus}
        forceRedirectFlag={this.forceRedirectFlag}
        history={this.history}
        inModal={this.inModal}
        hasPermissionCustom={this.hasPermissionCustom}
        hasViewPermission={this.hasViewPermission}
        sync_order={this.sync_order}
      />
    );
  }
  renderMenuForSalesReturn() {
    return (
      <MenuData
        name="salesReturn"
        url={this.url}
        shipmentsLength={this.shipmentsLength}
        salesOrderID={this.salesOrderID}
        saleOrderNo={this.saleOrderNo}
        salesOrderStatus={this.salesOrderStatus}
        packageStatus={this.packageStatus}
        dropshipStatus={this.dropshipStatus}
        handleToast={this.handleToast}
        history={this.history}
        inModal={this.inModal}
        forceRedirectFlag={this.forceRedirectFlag}
        updateSalesOrderStatus={this.updateSalesOrderStatus}
        hasPermissionCustom={this.hasPermissionCustom}
        hasViewPermission={this.hasViewPermission}
      />
    );
  }

  handleTabChange(tab) {
    this.setState({ currentMenu: tab });
  }

  menus() {
    return [
      {
        id: 'history',
        label: 'History',
        salesOrderID: this.state.salesOrder.id,
        url: `salesorders/${this.state.salesOrder.id}/activity`,
        icon: () => <History />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('history');
        },
        renderContent: this.renderMenuForHistory,
      },
      {
        id: 'packages',
        label: 'Packages',
        saleOrderNo: this.state.detailsNo,
        salesOrderID: this.state.salesOrder.id,
        url: `salesorders/${this.state.salesOrder.id}/activity`,
        salesOrderStatus: this.state.salesOrder.status,
        packageStatus: this.state.salesOrder.packageStatus,
        dropshipStatus: this.state.salesOrder.dropshipStatus,
        handleToast: this.props.handleToast,
        history: this.props.history,
        inModal: this.props.inModal,
        sync_order: this.state.sync_order,
        updateSalesOrderStatus: this.updateSalesOrderStatus,
        hasPermissionCustom: this.hasPermissionCustom,
        hasViewPermission: this.hasViewPermission('package'),
        icon: () => <Package />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('packages');
        },
        renderContent: this.renderMenuForPackages,
      },
      {
        id: 'invoices',
        label: 'Invoices',
        url: `salesorders/${this.state.salesOrder.id}/activity`,
        salesOrderID: this.state.salesOrder.id,
        salesOrderStatus: this.state.salesOrder.status,
        saleOrderNo: this.state.detailsNo,
        invoiceStatus: this.state.salesOrder.invoiceStatus,
        handleToast: this.props.handleToast,
        sync_order: this.state.sync_order,
        updateSalesOrderStatus: this.updateSalesOrderStatus,
        forceRedirectFlag: this.props.forceRedirectFlag,
        history: this.props.history,
        inModal: this.props.inModal,
        hasPermissionCustom: this.hasPermissionCustom,
        hasViewPermission: this.hasViewPermission('invoice'),
        icon: () => <Invoice />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('invoices');
        },
        renderContent: this.renderMenuInvoices,
      },
      {
        id: 'salesReturn',
        label: 'Sales Return',
        url: `salesorders/${this.state.salesOrder.id}/activity`,
        shipmentsLength: this.state.shipments.length,
        salesOrderID: this.state.salesOrder.id,
        saleOrderNo: this.state.detailsNo,
        salesOrderStatus: this.state.salesOrder.status,
        packageStatus: this.state.salesOrder.packageStatus,
        dropshipStatus: this.state.salesOrder.dropshipStatus,
        handleToast: this.props.handleToast,
        updateSalesOrderStatus: this.updateSalesOrderStatus,
        hasPermissionCustom: this.hasPermissionCustom,
        history: this.props.history,
        hasViewPermission: this.hasViewPermission('salesreturns'),
        forceRedirectFlag: this.props.forceRedirectFlag,
        inModal: this.props.inModal,
        icon: () => <SaleReturn />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('salesReturn');
        },
        renderContent: this.renderMenuForSalesReturn,
      },
    ];
  }

  menusUpdated() {
    return [
      {
        id: 'history',
        label: 'History',
        salesOrderID: this.state.salesOrder.id,
        url: `salesorders/${this.state.salesOrder.id}/activity`,
        icon: () => <History />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('history');
        },
        renderContent: this.renderMenuForHistory,
      },
      {
        id: 'packages',
        label: 'Packages',
        saleOrderNo: this.state.detailsNo,
        salesOrderID: this.state.salesOrder.id,
        url: `salesorders/${this.state.salesOrder.id}/activity`,
        salesOrderStatus: this.state.salesOrder.status,
        packageStatus: this.state.salesOrder.packageStatus,
        dropshipStatus: this.state.salesOrder.dropshipStatus,
        handleToast: this.props.handleToast,
        history: this.props.history,
        inModal: this.props.inModal,
        sync_order: this.state.sync_order,
        updateSalesOrderStatus: this.updateSalesOrderStatus,
        hasPermissionCustom: this.hasPermissionCustom,
        hasViewPermission: this.hasViewPermission('package'),
        icon: () => <Package />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('packages');
        },
        renderContent: this.renderMenuForPackages,
      },
      {
        id: 'invoices',
        label: 'Invoices',
        url: `salesorders/${this.state.salesOrder.id}/activity`,
        salesOrderID: this.state.salesOrder.id,
        salesOrderStatus: this.state.salesOrder.status,
        saleOrderNo: this.state.detailsNo,
        invoiceStatus: this.state.salesOrder.invoiceStatus,
        handleToast: this.props.handleToast,
        sync_order: this.state.sync_order,
        updateSalesOrderStatus: this.updateSalesOrderStatus,
        forceRedirectFlag: this.props.forceRedirectFlag,
        history: this.props.history,
        inModal: this.props.inModal,
        hasPermissionCustom: this.hasPermissionCustom,
        hasViewPermission: this.hasViewPermission('invoice'),
        icon: () => <Invoice />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('invoices');
        },
        renderContent: this.renderMenuInvoices,
      },
    ];
  }

  renderModals() {
    return (
      <Fragment>
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={this.state.pdf}
        />
        {this.renderMarkAsVoidModal()}
        {this.renderPackageCreateModal()}
        {this.renderManualShippingModal()}
        {this.renderInvoiceCreateModal()}
        {this.renderDropshipBackorderModal()}
      </Fragment>
    );
  }

  renderLayoutView() {
    const { salesOrder, customer, currency, sync_order } = this.state;
    const { salesOrderNo, status, terms } = salesOrder;
    const billingAddress = customer.addresses.billingAddress;
    const shippingAddress = customer.addresses.shippingAddress;
    const { handleToast } = this.props;
    return (
      <div className="float-left w-100 position-relative sales-return-main sales_order_set">
        <Menu
          menus={
            this.state.shipments.length > 0 ? this.menus() : this.menusUpdated()
          }
          currentMenu={this.state.currentMenu}
          history={this.props.history}
          inModal={this.props.inModal || false}
        />
        <div className="float-left w-100 overflow_custom_height">
          <div id="printSO" className="sale_Order_Details">
            <Fragment>
              {this.renderModals()}
              <div className="float-left w-100 sales_order_info">
                <DetailsHeader
                  title="Sales Order"
                  no={salesOrderNo}
                  status={status}
                  customer={customer.name}
                  contactID={salesOrder.customerID}
                  inModal={this.props.inModal}
                  type={salesOrder.dropshipStatus}
                />

                <SalesOrderInfo
                  className="so-details-info"
                  info={{
                    name: customer.name,
                    contactID: salesOrder.customerID,
                    reference: salesOrder.reference,
                    orderDate: salesOrder.orderDate,
                    expectedShipmentDate: salesOrder.expectedShipmentDate,
                    deliveryMethod: salesOrder.deliveryMethod,
                    salesPerson: salesOrder.salesPerson,
                    dropShipments: salesOrder.dropshipments,
                    backOrders: salesOrder.back_orders,
                    inModal: this.props.inModal,
                  }}
                />
              </div>
              <div className="float-left w-100 sales_address">
                <Address
                  address={{
                    type: 'Shipping',
                    attention: shippingAddress.attention,
                    company: customer.company,
                    street: shippingAddress.street,
                    city: shippingAddress.city,
                    state: shippingAddress.state,
                    zip: shippingAddress.zip,
                    country: shippingAddress.countryName,
                    phone: shippingAddress.phone,
                    fax: shippingAddress.fax,
                  }}
                />
                <Address
                  address={{
                    type: 'Billing',
                    attention: billingAddress.attention,
                    company: customer.company,
                    street: billingAddress.street,
                    city: billingAddress.city,
                    state: billingAddress.state,
                    zip: billingAddress.zip,
                    country: billingAddress.countryName,
                    phone: billingAddress.phone,
                    fax: billingAddress.fax,
                  }}
                />
              </div>
              {sync_order !== null ? (
                <div>
                  <SycnOrderDetails
                    synDetial={sync_order}
                    icon={SiWordpress}
                    salesOrder={salesOrder}
                  />
                </div>
              ) : null}
              {/* <GridLayout className="layout-padding" grid="1-1">

            </GridLayout>*/}
              <SalesOrderSummary
                currency={currency}
                salesOrder={salesOrder}
                overallTax={getOverallTaxDetails(salesOrder.items)}
                handleToast={handleToast}
                inModal={this.props.inModal}
              />
              <hr className="float-left w-100 mb-1 divider" />
              <hr className="float-left w-100 divider" />
              {/*{this.renderNotes(salesOrder.customer_note)}*/}
              <Notes note={salesOrder.customer_note} />
              <TermsCondition term={terms} />
              {(terms || salesOrder.customer_note) && (
                <hr className="float-left w-100 divider" />
              )}
            </Fragment>
          </div>
        </div>
      </div>
    );
  }

  renderNotes(note) {
    return (
      <Fragment>
        {!!note && (
          <div className="notes">
            <h5 className="heading">Customer Notes</h5>
            <p>{note}</p>
          </div>
        )}
      </Fragment>
    );
  }
  renderSidebar() {
    const { salesOrder, shipments } = this.state;
    const { handleToast, history } = this.props;
    return (
      <Fragment>
        <Box title="Packages" className="right-section-box">
          {!this.hasViewPermission('package') ? (
            <AccessDenied className="small dashboard" type="section" />
          ) : (
            <Packages
              salesOrderID={salesOrder.id}
              salesOrderStatus={salesOrder.status}
              packageStatus={salesOrder.packageStatus}
              dropshipStatus={salesOrder.dropshipStatus}
              handleToast={handleToast}
              updateSalesOrderStatus={this.updateSalesOrderStatus}
              hasPermissionCustom={this.hasPermissionCustom}
            />
          )}
        </Box>
        <Box title="Invoices" className="right-section-box">
          {!this.hasViewPermission('invoice') ? (
            <AccessDenied className="small dashboard" type="section" />
          ) : (
            <Invoices
              salesOrderID={salesOrder.id}
              salesOrderStatus={salesOrder.status}
              invoiceStatus={salesOrder.invoiceStatus}
              handleToast={handleToast}
              updateSalesOrderStatus={this.updateSalesOrderStatus}
              forceRedirectFlag={this.props.forceRedirectFlag}
              history={history}
              hasPermissionCustom={this.hasPermissionCustom}
            />
          )}
        </Box>
        <Box title="Sales Return" className="right-section-box">
          {!this.hasViewPermission('salesreturns') ? (
            <AccessDenied className="small dashboard" type="section" />
          ) : (
            <SalesReturn
              shipmentsLength={shipments.length}
              salesOrderID={salesOrder.id}
              salesOrderStatus={salesOrder.status}
              packageStatus={salesOrder.packageStatus}
              dropshipStatus={salesOrder.dropshipStatus}
              handleToast={handleToast}
              updateSalesOrderStatus={this.updateSalesOrderStatus}
              forceRedirect={this.props.forceRedirect}
              hasPermissionCustom={this.hasPermissionCustom}
            />
          )}
        </Box>
        {!!salesOrder.dropshipments.length && (
          <Box title="Dropshipments">
            <Dropshipments dropshipments={salesOrder.dropshipments} />
          </Box>
        )}
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    if (this.state.accessDenied) return <AccessDenied type="section" />;
    return <Fragment>{this.renderLayoutView()}</Fragment>;
  }
}

export default HeaderWrapper(SalesOrderDetails, {
  name: 'Sales Orders',
  tableIcon: 'general_module_icon',
  deleteName: 'Sales Order',
  Icon: SalesOrderIcon,
  baseUrl: 'salesorders',
  redirectUrl: '/salesorders',
  onlyMenu: true,
  showName: true,
  permissionName: 'salesorder',
});
