import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { restRequest, checkError, getObjFromLS } from '../../../Helpers';
import OverlayLoader from '../../../Components/Loader/OverlayLoader';
import { Form, FormAction } from '../../../Components/Form';
import { isEqual } from 'lodash';

class GeneralPreference extends Component {
  state = {
    loading: true,
    staticVal: {},
    showPrompt: false,
    stock: {
      physical: false,
      accounting: false,
    },
    discount: {
      no_discount: false,
      at_individual_item_level: false,
      at_transaction_level: false,
    },
    isSubmitting: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { stock, discount, staticVal } = this.state;

    if (prevState.showPrompt !== this.state.showPrompt) {
      const checkEquality = Boolean(
        isEqual({ ...stock, ...discount }, staticVal)
      );
      if (!checkEquality) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }

  handlePrompt = () => {
    this.setState({
      showPrompt: !this.state.showPrompt,
    });
  };

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.fetchData();
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  async fetchData() {
    this.setState({ loading: true });

    await restRequest('get', 'preferences/stock')
      .then((res) => {
        this.setState({
          stock: {
            physical: res.preferences.physical,
            accounting: res.preferences.accounting,
          },
          staticVal: {
            ...this.state.staticVal,
            physical: res.preferences.physical,
            accounting: res.preferences.accounting,
          },
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
    await restRequest('get', 'preferences/discount')
      .then((res) => {
        this.setState({
          discount: {
            no_discount: res.preferences.no_discount,
            at_individual_item_level: res.preferences.at_individual_item_level,
            at_transaction_level: res.preferences.at_transaction_level,
          },
          staticVal: {
            ...this.state.staticVal,
            no_discount: res.preferences.no_discount,
            at_individual_item_level: res.preferences.at_individual_item_level,
            at_transaction_level: res.preferences.at_transaction_level,
          },
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });

    this.setState({ loading: false });
  }

  handleStockPreferenceSubmit() {
    let { physical, accounting } = this.state.stock;
    return restRequest('put', 'preferences/stock', {
      status: {
        physical,
        accounting,
      },
    })
      .then(() => {
        this.props.handleToast(
          'General preference updated successfully',
          'success'
        );
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  }

  handleDiscountPreferenceSubmit() {
    let { no_discount, at_individual_item_level, at_transaction_level } =
      this.state.discount;
    return restRequest('put', 'preferences/discount', {
      status: {
        no_discount,
        at_individual_item_level,
        at_transaction_level,
      },
    })
      .then(() => {
        this.props.handleToast('General preferences saved', 'success');
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    Promise.all([
      this.handleStockPreferenceSubmit(),
      this.handleDiscountPreferenceSubmit(),
    ]).then((res) => {
      this.setState({ isSubmitting: false });
      this.props.setPrompt(false);
      this.fetchData();
    });
  };

  stockFunction(stockType) {
    restRequest('put', 'preferences/stock', {
      stock: stockType,
    })
      .then((res) => {
        this.setState({
          stockServerRes: res,
        });
        if (stockType === 'physical') {
          this.props.handleToast(
            'Preference has been set to "Physical quantity"',
            'success'
          );
        } else if (stockType === 'accounting') {
          this.props.handleToast(
            'Preference has been set to "Accounting quantity"',
            'success'
          );
        }
      })
      .catch((error) => {
        checkError(
          error,
          this.props.handleToast,
          'You do not have permission to change stock preference'
        );
        //this.props.handleToast("You do not have permission to change stock preference", "error")
      });
  }

  discountFunction(discountType) {
    restRequest('put', 'preferences/discount', {
      discount: discountType,
    })
      .then((res) => {
        this.setState({
          discountServerRes: res,
        });
        if (discountType === 'no_discount') {
          this.props.handleToast(
            'Preference has been set to "I don\'t give discounts"',
            'success'
          );
        } else if (discountType === 'at_individual_item_level') {
          this.props.handleToast(
            'Preference has been set to "At individual item level"',
            'success'
          );
        } else if (discountType === 'at_transaction_level') {
          this.props.handleToast(
            'Preference has been set to "At transaction level"',
            'success'
          );
        }
      })
      .catch((error) => {
        checkError(
          error,
          this.props.handleToast,
          'You do not have permission to change discount preference'
        );
        //this.props.handleToast("You do not have permission to change discount preference", "error")
      });
  }

  updateOptions(obj, option) {
    let updatedObj = obj;
    for (let key in updatedObj) {
      if (key === option) updatedObj[key] = true;
      else updatedObj[key] = false;
    }
    return updatedObj;
  }

  handleRadioChange = (event) => {
    let stateName = event.target.name;
    let option = event.target.id;
    let updatedOptions = this.updateOptions(this.state[stateName], option);
    this.setState((state) => ({
      [stateName]: {
        ...state[stateName],
        ...updatedOptions,
      },
    }));
    this.handlePrompt();
  };

  handleRadioClick(radioOption, radiostateName) {
    let stateName = radiostateName;
    let option = radioOption;
    let updatedOptions = this.updateOptions(this.state[stateName], option);
    this.setState((state) => ({
      [stateName]: {
        ...state[stateName],
        ...updatedOptions,
      },
    }));
    this.handlePrompt();
  }

  renderDiscountPreference() {
    let { discount } = this.state;
    return (
      <div className="float-left w-100">
        <h4 className="mb_5 float-left w-100 mt_0">Do you give discounts?</h4>

        <div className="preference-label radio-btn float-left w-100">
          <input
            type="radio"
            checked={discount.no_discount}
            onChange={(event) => this.handleRadioChange(event)}
            name="discount"
            id="no_discount"
          />
          <label htmlFor="no_discount">I don't give discounts</label>
          <span
            onClick={() => this.handleRadioClick('no_discount', 'discount')}
            className="radio-circle"
          />
        </div>

        <div className="preference-label radio-btn float-left w-100">
          <input
            type="radio"
            checked={discount.at_individual_item_level}
            onChange={(event) => this.handleRadioChange(event)}
            name="discount"
            id="at_individual_item_level"
          />
          <label htmlFor="at_individual_item_level">
            At individual item level
          </label>
          <span
            onClick={() =>
              this.handleRadioClick('at_individual_item_level', 'discount')
            }
            className="radio-circle"
          />
        </div>

        <div className="preference-label radio-btn float-left w-100">
          <input
            type="radio"
            checked={discount.at_transaction_level}
            onChange={(event) => this.handleRadioChange(event)}
            name="discount"
            id="at_transaction_level"
          />
          <label htmlFor="at_transaction_level">At transaction level</label>
          <span
            onClick={() =>
              this.handleRadioClick('at_transaction_level', 'discount')
            }
            className="radio-circle"
          />
        </div>
      </div>
    );
  }

  renderStockPreference() {
    let { stock } = this.state;
    return (
      <div className="float-left w-100">
        <h4 className="mb_5 float-left w-100 mt_0">Mode of stock tracking</h4>
        <div className="preference-label radio-btn float-left w-100">
          <input
            type="radio"
            checked={stock.physical}
            onChange={(event) => this.handleRadioChange(event)}
            name="stock"
            id="physical"
          />
          <label htmlFor="physical">
            Physical Stock - The stock on hand will be calculated based on
            Receives & Shipments
          </label>
          <span
            onClick={() => this.handleRadioClick('physical', 'stock')}
            className="radio-circle"
          />
        </div>
        <div className="preference-label radio-btn float-left w-100">
          <input
            type="radio"
            checked={stock.accounting}
            onChange={(event) => this.handleRadioChange(event)}
            name="stock"
            id="accounting"
          />
          <label htmlFor="accounting">
            Accounting Stock - The stock on hand will be calculated based on
            Bills & Invoice
          </label>
          <span
            onClick={() => this.handleRadioClick('accounting', 'stock')}
            className="radio-circle"
          />
        </div>
      </div>
    );
  }

  renderActionButtons() {
    let permissions = getObjFromLS('role').permissions;
    if (permissions.superAccess || permissions.PreferenceEdit) {
      return (
        <FormAction
          disabled={this.state.isSubmitting}
          onCancel={async () => {
            this.props.history.push('/settings');
          }}
        />
      );
    }
  }

  renderGeneralPreference() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="po-pref-component float-left w-100">
          <div className="float-left w-70 genral_tab_content">
            {this.state.loading && <OverlayLoader />}
            {this.renderStockPreference()}
            {/* <hr className="pref--hr float-left w-100" /> */}
            {this.renderDiscountPreference()}
            {this.renderActionButtons()}
          </div>
        </div>
      </Form>
    );
  }

  render() {
    return this.renderGeneralPreference();
  }
}

export default withRouter(GeneralPreference);
