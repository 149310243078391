import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  restRequest,
  checkError,
  getObjFromLS,
  getOrganizationDate,
  getItemTotalAmount,
  GetDateString,
  imagePathS3,
} from '../../../Helpers';
import { Loader, NotFound } from '../../';
import SalesOrderFormEnhancer from './SalesOrderFormEnhancer';
import './SalesOrderForm.css';
import './../../Table/Table.css';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';

class SalesOrderForm extends Component {
  type = this.props.type; // "add", "edit", "clone" form

  id = this.props.id || null;
  contactId = window.location.search.split('=')[1] || null;
  state = {
    loading: true,
    resp: {},
    salesOrder: {
      contactId: null,
      contactObj: {},
      billingAddressId: null,
      shippingAddressId: null,
      bilingAdressForSaleOrder: null,
      shippingAdressForSaleOrder: null,
      salesOrderNo: '',
      orderDate: moment(getOrganizationDate()),
      expectedShipmentDate: moment(getOrganizationDate()),
      salesPersonId: null,
      reference: '',
      deliveryMethodId: null,
      customerNotes: '',
      hasTermsAndConditions: window.location.pathname.includes('add')
        ? true
        : false,
      termsAndConditions: '',
      warehouseId: null,
      items: [],
      transactionDiscountType: 'percent', // percent, amount
      transactionDiscount: 0,
      adjustment: 0,
      shippingCharge: 0,
      discountLevel: null,
      salesOrderDetails: [],
      showItemModal: false,
    },
    customerList: [],
    customerAddressList: [],
    salesPersonsFromDB: [],
    is_sales_person: false,
    itemsDBList: [],
    deliveryMethodsList: [],
    warehouseFromDB: [],
    itemList: [],
    stockPreference: '',
    organizationCurrency: {},
    taxFromDB: [],
    discountPreference: {},
    isSubmitted: false,
    notFound: false,
    accessDenied: false,
    cofirmState: false,
    showPrompt: false,
  };

  emptyItem = {
    id: null,
    name: '',
    images: [],
    sku: '',
    quantity: 1,
    stocks: [],
    unit: 'unit',
    unitPrice: null,
    previousSellingPrice: 0,
    discountType: 'percent', // percent, amount
    discount: null,
    tax: {
      id: null,
      rate: 0,
      name: '',
    },
    taxAmount: 0,
    total: '0.00',
    dragDropID: GetDateString(),
  };
  componentDidMount() {
    this.fetchData();
    sessionStorage.setItem('once', false);
    sessionStorage.setItem('fullscreen', false);
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  fetchData = async () => {
    this.setState({ loading: true });

    switch (this.type) {
      case 'clone':
      case 'edit':
        await this.fetchSalesOrderEditCloneData(
          this.getCloneEditUrl(this.id, this.type)
        );
        await this.fetchContactAddress(this.state.salesOrder.contactId);
        break;
      case 'add':
      default:
        await this.fetchSalesOrderCreateData();
        if (this.contactId) {
          await this.fetchContactDetail(this.contactId);
          await this.fetchContactAddress(this.contactId);
        }
        break;
    }

    this.setState({ loading: false });
  };

  getCloneEditUrl(id, type) {
    return `salesorders/${id}/${type === 'edit' ? 'edit' : 'clone'}`;
  }

  getWarehouseID(warehouseList) {
    return !!warehouseList.length
      ? warehouseList.find((warehouse) => warehouse.is_primary).id
      : null;
  }

  getTaxAmount = (quantity, unitPrice, taxRate) => {
    return parseFloat(((quantity * unitPrice * taxRate) / 100).toFixed(2));
  };

  fetchSalesOrderCreateData() {
    let role = getObjFromLS('role');
    let roleId = role.id;
    let roleSlug = role.slug;
    return restRequest('get', 'salesorders/create')
      .then((res) => {
        let warehouseList = [];
        warehouseList.push(...res.warehouse_list);
        // Removes inactive warehouse from list
        warehouseList = warehouseList.filter((list) => list.is_active === 1);
        let { no_discount, at_individual_item_level } =
          res.preference_list.discount_preference.preferences;
        this.setState({ resp: res });
        this.setState({
          salesOrder: {
            ...this.state.salesOrder,
            salesOrderNo: res.sales_order_no,
            termsAndConditions:
              res.preference_list.sales_order_preference.terms,
            discountLevel: no_discount
              ? 'no_discount'
              : at_individual_item_level
                ? 'item'
                : 'transaction',
            warehouseId: this.getWarehouseID(res.warehouse_list),
            items: [this.emptyItem],
            /*salesPersonId: res.sales_person_user
              ? res.sales_person[0].users.id
              : null,*/
             salesPersonId: (res.sales_person_user === true)
              ? res.sales_person[0].users.id
              : (roleSlug && roleSlug !== 'sales_person') ? res.sales_person.find((p) => p.role_id === roleId).users.id: null,
          },
          itemsDBList: res.item_list,
          taxFromDB: res.tax_list,
          discountPreference:
            res.preference_list.discount_preference.preferences,
          stockPreference:
            res.preference_list.stock_preference.preferences.physical === true
              ? 'physical'
              : 'accounting',
          warehouseFromDB: warehouseList,
          customerList: res.customer_list,
          deliveryMethodsList: res.delivery_method_list,
          organizationCurrency: res.base_currency,
          salesPersonsFromDB: res.sales_person,
          is_sales_person: (roleSlug === 'sales_person'),
          itemList: res.item_list,
        });
      })
      .catch((error) => {
        checkError(error);
      });
  }
  fetchSalesOrderEditCloneData(url) {
    return restRequest('get', url)
      .then(async (res) => {
        let warehouseList = [];
        warehouseList.push(...res.warehouse_list);
        //warehouseList.push(res.warehouse_list.find((warehouse) => warehouse.is_primary=== true ))
        //warehouseList.push(...res.warehouse_list.filter((warehouse) => warehouse.is_primary=== false ))
        let salesOrder = {
          ...this.state.salesOrder,
          salesOrderNo: res.sales_order.sales_order_no,
          contactId: res.sales_order.customer_id,
          contactObj: {
            value: res.sales_order.contact.id,
            label: res.sales_order.contact.display_name,
          },
          billingAddressId: res.sales_order.billing_address_id,
          shippingAddressId: res.sales_order.shipping_address_id,
          bilingAdressForSaleOrder: res.sales_order.billing_address_id,
          shippingAdressForSaleOrder: res.sales_order.shipping_address_id,
          orderDate: moment(res.sales_order.order_date),
          expectedShipmentDate: res.sales_order.expected_shipment_date
            ? moment(res.sales_order.expected_shipment_date)
            : null,
          salesPersonId: res.sales_order.sales_person_id,
          reference: res.sales_order.reference,
          deliveryMethodId: res.sales_order.delivery_method_id,
          customerNotes: res.sales_order.customer_note,
          hasTermsAndConditions: !!res.sales_order.terms_and_condition.length,
          termsAndConditions: !!res.sales_order.terms_and_condition.length
            ? res.sales_order.terms_and_condition
            : res.preference_list.sales_order_preference.terms,
          warehouseId: res.sales_order.warehouse_id,
          transactionDiscountType: res.sales_order.discount_type || 'percent', // percent, amount
          transactionDiscount: res.sales_order.discount_transaction_level,
          adjustment: res.sales_order.adjustment,
          // status: res.sales_order.status,
          // Because clone always save as a new sale order so its status will be draft evey time in clone from
          status: this.type === 'clone' ? 'draft' : res.sales_order.status,
          shippingCharge: res.sales_order.shipping_charge,
          discountLevel: res.sales_order.discount_level,
          salesOrderDetails: res.sales_order.sales_order_details,
          items: res.sales_order.sales_order_details.map((salesOrderItems) => ({
            id: salesOrderItems.items.id,
            salesOrderItemDetailId: salesOrderItems.id,
            name: salesOrderItems.items.name,
            images: salesOrderItems.items.images,
            sku: salesOrderItems.items.sku,
            quantity: salesOrderItems.quantity,
            stocks: salesOrderItems.items.stocks,
            unit: salesOrderItems.items.unit,
            upc: salesOrderItems.items.upc ? salesOrderItems.items.upc : null,
            mpn: salesOrderItems.items.mpn ? salesOrderItems.items.mpn : null,
            ean: salesOrderItems.items.ean ? salesOrderItems.items.ean : null,
            isbn: salesOrderItems.items.isbn
              ? salesOrderItems.items.isbn
              : null,
            extra_description: salesOrderItems.extra_description
              ? salesOrderItems.extra_description
              : null,
            unitPrice: salesOrderItems.rate,
            sales_unit_price: salesOrderItems.rate,
            is_active: salesOrderItems.items.is_active,
            inventoryType:
              salesOrderItems.items.inventory_type === 'noninventory'
                ? true
                : false,
            inventory_type: salesOrderItems.items.inventory_type,
            previousSellingPrice: 0,
            discountType: salesOrderItems.discount_type || 'percent', // percent, amount
            discount: salesOrderItems.discount_item_level,
            dragDropID: GetDateString(),
            tax: {
              id: salesOrderItems.tax ? salesOrderItems.tax.id : null,
              rate: salesOrderItems.tax ? salesOrderItems.tax.rate : 0,
              name: salesOrderItems.tax ? salesOrderItems.tax.name : '',
            },
            taxAmount: salesOrderItems.tax
              ? this.getTaxAmount(
                salesOrderItems.quantity,
                salesOrderItems.rate,
                salesOrderItems.tax.rate
              )
              : 0,
            total: getItemTotalAmount(salesOrderItems),
          })),
        };
        if (this.type === 'edit') {
          const ids = salesOrder.items.map((item) => {
            return item.id;
          });
          const payload = {
            customer_id: salesOrder.contactId,
            item_id: ids,
            object: 'saleOrder',
            object_id: res.sales_order.id,
          };
          let response = await restRequest(
            'post',
            `salesorders/previoussoldprice`,
            payload
          ).then((priceData) => {
            return priceData;
          });
          salesOrder.items.forEach((item, index) => {
            let previousRate = response.find((o) => o.item_id === item.id);

            salesOrder.items[index] = {
              ...item,
              previousSellingPrice: previousRate?.rate,
            };
          });
        }
        let corectItems = [];
        salesOrder.items.forEach((item, index) => {
          if (item.is_active === 1) {
            corectItems.push(item);
          }
        });
        salesOrder.items = corectItems;
        let filteredItemsList = res.item_list.filter((item, index) => {
          if (item.inventory_type === 'noninventory') {
            if (
              salesOrder.items.some(
                (salesOrderItem) =>
                  item.id === salesOrderItem.id &&
                  salesOrderItem.inventory_type === 'noninventory'
              )
            ) {
              salesOrder.items.filter((salesOrderItem) => {
                if (item.id === salesOrderItem.id)
                  return (salesOrderItem.sales_unit_price =
                    item.sales_unit_price);
              });
              return item;
            } else {
              return item;
            }
          } else return item;
        });
        this.setState({
          salesOrder: salesOrder,
          itemsDBList: filteredItemsList,
          taxFromDB: res.tax_list,
          discountPreference:
            res.preference_list.discount_preference.preferences,
          stockPreference:
            res.preference_list.stock_preference.preferences.physical === true
              ? 'physical'
              : 'accounting',
          warehouseFromDB: warehouseList,
          customerList: res.customer_list,
          deliveryMethodsList: res.delivery_method_list,
          organizationCurrency: res.base_currency,
          salesPersonsFromDB: res.sales_person,
          is_sales_person: res.sales_person_user,
          itemList: filteredItemsList,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
        if (error.response && error.response.status === 403) {
          this.setState({ accessDenied: true });
        } else {
          this.setState({ notFound: true });
        }
      });
  }

  async fetchContactDetail(contactId) {
    return restRequest(
      'get',
      `salesorders/contact?page=1&contactId=${contactId}`
    ).then((res) => {
      let salesOrder = {
        ...this.state.salesOrder,
        contactObj: {
          label: res.data[0].label,
          value: res.data[0].value,
        },
      };
      this.setState({
        salesOrder: salesOrder,
      });
    });
  }

  async fetchContactAddress(contactId) {
    let salesOrderType = (() => {
      switch (this.type) {
        case 'edit':
          return 'edit';
        case 'clone':
        default:
          return 'create';
      }
    })();
    return restRequest(
      'get',
      `salesorders/${salesOrderType}/contact/${contactId}/contactAddress`
    )
      .then((addressList) => {
        this.setState({ customerAddressList: addressList });
        const billingAddress =
          this.state.customerAddressList.find(
            (address) => address.address_type === 'billing'
          ) || {};
        const shippingAddress =
          this.state.customerAddressList.find(
            (address) => address.address_type === 'shipping'
          ) || {};
        var billingAddressId =
          !!Object.keys(billingAddress).length !== 0 ? billingAddress.id : null;
        var shippingAddressId =
          !!Object.keys(shippingAddress).length !== 0
            ? shippingAddress.id
            : null;
        if (this.type === 'add') {
          if (billingAddressId) {
            //this.state.salesOrder.billingAddressId = billingAddressId;
            let salesOrder = {
              ...this.state.salesOrder,
              bilingAdressForSaleOrder: billingAddressId,
              billingAddressId: billingAddressId,
            };
            this.setState({
              salesOrder: salesOrder,
            });
          }
          if (shippingAddressId) {
            //this.state.salesOrder.shippingAddressId = shippingAddressId;
            let salesOrder = {
              ...this.state.salesOrder,
              shippingAdressForSaleOrder: shippingAddressId,
              shippingAddressId: shippingAddressId,
            };
            this.setState({
              salesOrder: salesOrder,
            });
          }
        }
        if (this.type === 'edit' || this.type === 'clone') {
          if (
            billingAddressId &&
            this.state.salesOrder.billingAddressId &&
            this.state.salesOrder.billingAddressId !== billingAddressId
          ) {
            //this.state.salesOrder.billingAddressId = billingAddressId;
            let salesOrder = {
              ...this.state.salesOrder,
              billingAddressId: billingAddressId,
            };
            this.setState({
              salesOrder: salesOrder,
            });
          }
          if (
            shippingAddressId &&
            this.state.salesOrder.shippingAddressId &&
            this.state.salesOrder.shippingAddressId !== shippingAddressId
          ) {
            //this.state.salesOrder.shippingAddressId = shippingAddressId;
            let salesOrder = {
              ...this.state.salesOrder,
              shippingAddressId: shippingAddressId,
            };
            this.setState({
              salesOrder: salesOrder,
            });
          }
        }
      })
      .catch((error) => {
        checkError(error);
      });
  }

  submitRequestType = () => {
    if (this.type === 'edit') {
      return 'put';
    }
    return 'post';
  };

  submitRequestUrl = () => {
    switch (this.type) {
      case 'edit':
        return `salesorders/${this.props.id}`;
      case 'add':
      case 'clone':
      default:
        return 'salesorders';
    }
  };

  successMessage = () => {
    switch (this.type) {
      case 'clone':
        this.props.handleToast('Sales Order has been cloned', 'success');
        break;
      case 'edit':
        this.props.handleToast('Sales Order has been updated', 'success');
        break;
      case 'add':
      default:
        this.props.handleToast('Sales Order created successfully', 'success');
    }
  };

  updateCustomerList = (newCustomer) => {
    this.setState((state) => ({
      customerList: [newCustomer, ...state.customerList],
    }));
  };

  updateTaxList = (newTax) => {
    this.setState((state) => ({ taxFromDB: [newTax, ...state.taxFromDB] }));
  };

  updateItemList = (newItem) => {
    let addedItem = {
      ean: newItem.ean,
      id: newItem?.id,
      // value: newItem.id || null,
      // label: newItem.name || "",
      images: newItem.images,
      inventory_type: newItem.inventory_type,
      isbn: newItem.isbn,
      item_primary_image: newItem.item_primary_image,
      mpn: newItem.mpn,
      name: newItem.name,
      purchase_account: newItem.purchase_account,
      sales_unit_price: newItem.sales_unit_price,
      sku: newItem.sku,
      stocks: newItem.stocks,
      tax: newItem.tax,
      tax_id: parseInt(newItem.tax_id),
      to_be_received: newItem.to_be_received,
      // never use on backend anywhere both on front-end and backend
      // to_be_shipped: newItem.to_be_shipped,
      unit: newItem.unit,
      upc: newItem.upc,
    };
    this.setState((state) => ({
      itemsDBList: [addedItem, ...state.itemsDBList],
      itemList: [addedItem, ...state.itemList],
    }));
  };

  handleTotalCheckTrue = (check) => {
    this.setState({
      cofirmState: check,
    });
  };

  handleTotalCheckFalse = (check) => {
    this.setState({
      cofirmState: check,
    });
  };

  handlePrompt = (status = true) => this.setState({ showPrompt: status });

  renderSalesOrderForm() {
    const { title, handleToast, history } = this.props;
    let {
      salesOrder,
      deliveryMethodsList,
      salesPersonsFromDB,
      is_sales_person,
      warehouseFromDB,
      itemList,
      customerList,
      customerAddressList,
      hasTermsAndConditions,
      organizationCurrency,
      discountPreference,
      itemsDBList,
      stockPreference,
      taxFromDB,
      resp,
      cofirmState,
    } = this.state;
    return (
      <>
        <Prompt
          when={this.state.showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        <SalesOrderFormEnhancer
          allData={resp}
          title={title}
          type={this.type}
          id={this.id}
          contactIdTransaction={this.contactId}
          salesOrder={{ ...salesOrder }}
          customerList={customerList}
          customerAddressList={customerAddressList}
          salesPersonsFromDB={salesPersonsFromDB}
          is_sales_person={is_sales_person}
          deliveryMethodsList={deliveryMethodsList}
          hasTermsAndConditions={hasTermsAndConditions}
          warehouseFromDB={warehouseFromDB}
          itemList={itemList}
          originalItemList={itemList}
          emptyItem={this.emptyItem}
          itemsDBList={itemsDBList}
          updateItemList={this.updateItemList}
          organizationCurrency={organizationCurrency}
          discountPreference={discountPreference}
          stockPreference={stockPreference}
          taxFromDB={taxFromDB}
          submitRequestType={this.submitRequestType}
          submitRequestUrl={this.submitRequestUrl}
          updateCustomerList={this.updateCustomerList}
          successMessage={this.successMessage}
          history={history}
          updateTaxList={this.updateTaxList}
          updateDeliveryMethodList={this.updateDeliveryMethodList}
          handleToast={handleToast}
          moduleDetails={getObjFromLS('module')}
          cofirmState={cofirmState}
          handleTotalCheckTrue={this.handleTotalCheckTrue}
          handleTotalCheckFalse={this.handleTotalCheckFalse}
          setPrompt={this.handlePrompt}
        />
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    if (this.state.accessDenied) return <AccessDenied type="section" />;
    return this.renderSalesOrderForm();
  }
}

export default withRouter(SalesOrderForm);
